<template>
  <div>
    车辆管理
  </div>
</template>

<script>
export default {
  name: "vehicle"
}
</script>

<style scoped>

</style>
