<template>
  <div class="bindDevice">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="未绑定设备" name="first">
        <searchForm :formOptions="formOptions" @onSearch="onSearch" :btnItems="btnItems" />
        <page-table
          :tableData="tableData"
          :tableLabel="tableLabel"
          :tableOption="tableOption"
          class="gdtable"
          :pager="pager"
          @pagination="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
          @handleButton="tableBtnHandle(arguments)"
        ></page-table>
      </el-tab-pane>
      <el-tab-pane label="已绑定设备" name="second">
        <searchForm :formOptions="formOptions2" @onSearch="onSearch2" :btnItems="btnItems" @setSelect2="setSelect2" @setSelect="setSelect" />
        <page-table
          :tableData="tableData2"
          :tableLabel="tableLabel2"
          :tableOption="tableOption2"
          class="gdtable"
          :pager="pager2"
          @pagination="handleCurrentChange2"
          @handleSizeChange="handleSizeChange2"
          @handleButton="tableBtnHandle(arguments)"
        ></page-table>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="绑定" :visible.sync="centerDialogVisible" :destroy-on-close="true" width="70%">
      <!-- <div class="grid-content bg-purple"> -->
      <el-form :inline="true" :rules="rules" :model="formItem" ref="formItem" :label-position="labelPosition" label-width="120px" class="demo-form-inline">
        <el-form-item label="设备类型" prop="deviceType">
          <el-input v-model="formItem.deviceType" placeholder="设备类型" disabled></el-input>
        </el-form-item>
        <el-form-item label="序列号" prop="snNumber">
          <el-input v-model="formItem.snNumber" placeholder="序列号" disabled></el-input>
        </el-form-item>

        <el-form-item label="绑定栋舍" prop="buildingId">
          <el-select v-model="formItem.buildingId" placeholder="绑定栋舍" @change="saveBuildVal">
            <el-option v-for="item in formOptions2[2].options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定单元/区" prop="buildId">
          <el-select v-model="formItem.buildId" placeholder="绑定单元/区" @change="saveBuildVal2">
            <el-option v-for="item in formOptions2[3].options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定栏位" prop="fieldId">
          <el-select v-model="formItem.fieldId" placeholder="绑定栏位" @change="saveFieldVal">
            <el-option v-for="item in formOptions2[4].options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName">
          <el-row>
            <!--              <el-input v-model="formItem.buildId"  style='width: 50%'></el-input>-->
            <el-input v-model="formItem.deviceName" placeholder="设备名称"></el-input>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStrategy('formItem')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import searchForm from '@/components/searchForm'
import pageTable from '@/components/pageTable'
import store from '@/store'
export default {
  name: 'bindDevice',
  components: {
    searchForm,
    pageTable
  },
  data() {
    return {
      selectBuildId: '',
      showSelection: true,
      rules: {
        buildingId: [{ required: true, message: '请选择绑定栋舍', trigger: 'change' }],
        deviceName: [{ required: true, message: '设备名称必填', trigger: 'blur' }]
      },
      labelPosition: 'right',
      centerDialogVisible: false,
      btnItems: 'search' + 'reset',
      activeName: 'first',
      formOptions: [
        {
          label: '设备类型',
          prop: 'deviceType',
          element: 'el-select',
          options: this.getDeviceTypeList()
        },
        {
          label: '序列号',
          prop: 'snNumber',
          element: 'el-input',
          placeholder: '设备序列号'
        }
      ],
      formOptions2: [
        {
          label: '设备类型',
          prop: 'deviceType',
          element: 'el-select',
          options: this.getDeviceTypeList()
        },
        {
          label: '序列号',
          prop: 'snNumber',
          element: 'el-input',
          placeholder: '设备序列号'
        },
        {
          label: '栋舍',
          prop: 'buildingId',
          element: 'el-select',
          isChange: 'change',
          options: this.getBuildType()
        },
        {
          label: '单元/区',
          prop: 'buildId',
          element: 'el-select',
          initValue: '',
          isChange: 'change2',
          showTooltip: true,
          options: []
        },
        {
          label: '栏位列表',
          prop: 'fieldId',
          element: 'el-select',
          ref: 'fieldId',
          options: []
        }
        // {
        //   label: '组播地址',
        //   prop: 'lwMac',
        //   element: 'el-input',
        //   placeholder: '组播地址'
        // },
      ],
      tableData: [],
      tableLabel: [
        { label: '类型', param: 'deviceType' },
        { label: '序列号', param: 'snNumber' },
        { label: '绑定栋舍', param: 'buildingName' },
        { label: '绑定单元/区', param: 'buildName' },
        { label: '绑定栏位', param: 'fieldName' },
        { label: '名称', param: 'deviceName' }
        // { label: '策略', param: 'strategyName' }
      ],
      tableOption: {
        label: '操作',
        options: [{ label: '绑定', methods: 'editDetail' }]
      },
      pager: {
        pageSize: 10, // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0 //列表总数
      },
      tableData2: [],
      tableLabel2: [
        { label: '类型', param: 'deviceType' },
        { label: '序列号', param: 'snNumber' },
        { label: '绑定栋舍', param: 'buildingName' },
        { label: '绑定单元/区', param: 'buildName' },
        { label: '绑定栏位', param: 'fieldName' },
        // { label: '组播地址' , param: 'lwMac' },
        { label: '名称', param: 'deviceName' }
        // { label: '策略', param: 'strategyName' }
      ],
      tableOption2: {
        label: '操作',
        options: [
          { label: '重新绑定', methods: 'editDetail' }
          // { label: '解绑', methods: 'cancel',type:'danger' },
        ]
      },
      pager2: {
        pageSize: 10, // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0 //列表总数
      },
      formItem: {
        deviceId: '', //设备id
        snNumber: '', //序列号
        deviceName: '', //设备名称
        deviceType: '', //设备类型
        buildId: '', //已绑栋舍id
        fieldId: '', //已绑栏位id
        buildName: '', //已绑栋舍
        fieldName: '' //已绑栏位
      },
      buildId: '',
      deviceIds: []
    }
  },
  mounted() {
    this.getDevicePage()
    this.getDevicePage2()
    this.getAllPigpen()
  },
  watch: {
    selectBuildId(val) {
      this.getAllPigpen(val)
    },
    buildId(val) {
      this.getPigpenFiled(val)
    }
  },
  methods: {
    saveBuildVal2(val) {
      this.getPigpenFiled(val)
    },
    //保存栋舍id
    saveBuildVal(val) {
      this.formItem.buildId = undefined
      //通过选择栋舍id获取栏位列表
      this.getAllPigpen(val)
    },
    //获取栋舍id
    setSelect(val) {
      console.log('------------')
      this.selectBuildId = val
      this.formOptions2[3].initValue = ''
    },
    getAllPigpen(val) {
      let buildId = ''

      if (val) {
        buildId = val
      } else {
        buildId = this.selectBuildId
      }
      let arr = []
      console.log('=============')
      this.formOptions2[3].options = []
      this.$http
        .get(this.$api.getPigBuildList, {
          organizationId: store.state.farmId,
          type: 0,
          buildId: buildId
        })
        .then(res => {
          console.log('单元/区')
          console.log(res)
          if (res.result) {
            res.result.map(item => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
            this.formOptions2[3].options = arr
          }
        })
    },
    setSelect2(val) {
      this.buildId = val
    },
    //栋舍列表
    getBuildType() {
      let arr = []
      this.$http
        .get(this.$api.getPigBuildList, {
          organizationId: store.state.farmId,
          type: 2
        })
        .then(res => {
          if (res.result) {
            res.result.map(item => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
          }
        })
      return arr
    },
    //选中集合
    handleSelectionChange(rows) {
      //rows为选中行的数据的集合
      //勾选后，需要往后台传递什么数据，从rows中获取即可

      this.deviceIds = []
      rows.map(item => {
        this.deviceIds.push(item.deviceId)
      })
    },
    //获取栋舍id
    // setSelect(val,prop){
    //   if(prop){
    //     if(prop =='buildingId'){
    //       this.getPigpenBuild(val)
    //     }else{
    //       this.buildId = val
    //     }
    //   }
    // },
    //保存
    saveStrategy(formName) {
      console.log(this.formItem)
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$http
            .post(this.$api.setDeviceBuildOrField, {
              buildId: this.formItem.buildId,
              buildingId: this.formItem.buildingId,
              deviceId: this.formItem.deviceId,
              deviceName: this.formItem.deviceName,
              pigPenId: this.formItem.fieldId
            })
            .then(res => {
              if (res.statusCode == 200) {
                this.$message({
                  type: 'success',
                  message: '绑定成功'
                })
                this.centerDialogVisible = false
                this.getDevicePage()
                this.getDevicePage2()
              }
            })
        } else {
          return false
        }
      })
    },
    //选中栏位列表
    saveFieldVal() {
      console.log(111)
      console.log(this.buildId)
    },
    //获取栏位列表
    getPigpenFiled(buildId) {
      if (buildId != '') {
        let list = []
        this.$http
          .get(this.$api.getPigBuildList, {
            organizationId: store.state.farmId,
            type: 1,
            buildId: buildId
          })
          .then(res => {
            if (res.code == 200) {
              res.result.map(item => {
                list.push({ label: item.name, value: item.id })
              })
            }
          })
        this.formOptions2[4].options = list
      } else {
        // this.$message({
        //   type: 'warning',
        //   message: '请先选择单元/区'
        // });
      }
    },
    //获取单元列表
    getPigpenBuild(val) {
      let arr = []
      this.$http
        .get(this.$api.getAllPigpen, {
          buildingId: val
        })
        .then(res => {
          if (res.data) {
            res.data.map(item => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
          }
        })
      return arr
    },
    //未绑定设备列表
    getDevicePage(obj) {
      let that = this
      this.$http
        .get(this.$api.getDevicePage, {
          ...obj,
          current: that.pager.currentPage,
          size: that.pager.pageSize,
          isBind: 1
        })
        .then(res => {
          this.tableData = res.data.records
          this.pager.total = res.data.total
        })
    },
    //已绑定设备列表
    getDevicePage2(obj) {
      let that = this
      this.$http
        .get(this.$api.getDevicePage, {
          ...obj,
          current: that.pager2.currentPage,
          size: that.pager2.pageSize,
          isBind: 0
        })
        .then(res => {
          this.tableData2 = res.data.records
          this.pager2.total = res.data.total
        })
    },
    //切换tab标签页
    handleClick(tab, event) {
      console.log(tab, event)
    },
    //获取设备列表
    getDeviceTypeList() {
      let list = []
      this.$http.get(this.$api.getDeviceTypeList).then(res => {
        if (res.statusCode == 200) {
          res.data.map(item => {
            list.push({ label: item, value: item })
          })
        }
      })
      return list
    },
    //组播推送
    pushDataBat() {
      if (this.deviceIds.length != 0) {
        this.$http
          .post(this.$api.pushDataBat, {
            deviceIds: this.deviceIds
          })
          .then(res => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.getDevicePage2(this.obj)
          })
      } else {
        this.$message({
          type: 'error',
          message: '请先选择设备'
        })
      }
    },
    //未绑定设备进行绑定
    tableBtnHandle(arg) {
      if (arg[0] == 'editDetail') {
        //编辑
        this.centerDialogVisible = true
        //this.setDialog(arg[1]);
        this.$set(this.formItem, 'deviceId', arg[1].deviceId)
        this.$set(this.formItem, 'snNumber', arg[1].snNumber)
        this.$set(this.formItem, 'buildingId', arg[1].buildingId)
        this.$set(this.formItem, 'deviceName', arg[1].deviceName)
        this.$set(this.formItem, 'deviceType', arg[1].deviceType)
        this.$set(this.formItem, 'buildId', arg[1].buildId)
        this.$set(this.formItem, 'buildName', arg[1].buildName)
        this.$set(this.formItem, 'fieldName', arg[1].fieldName)
        this.$set(this.formItem, 'fieldId', arg[1].fieldId)

        // this.formItem.fieldId = arg[1].fieldId;
        // this.formItem.deviceId = arg[1].deviceId;
        // this.formItem.snNumber = arg[1].snNumber;
        // this.formItem.buildingId = arg[1].buildingId;
        // this.formItem.deviceName = arg[1].deviceName;
        // this.formItem.deviceType = arg[1].deviceType;
        // this.formItem.buildId = arg[1].buildId;
        // this.formItem.buildName = arg[1].buildName;
        // this.formItem.fieldName = arg[1].fieldName;
        if (arg[1].buildId) {
          this.getPigpenFiled(arg[1].buildId)
        }
      } else {
        this.deviceIds = [arg[1].deviceId]
        this.pushDataBat()
      }
    },
    // else if (arg[0]=='cancel'){
    //   this.$confirm('此操作将解绑该设备, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //
    //   })
    // }
    onSearch(val) {
      this.obj = val
      this.getDevicePage(val)
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.getDevicePage(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.getDevicePage(this.obj)
    },
    onSearch2(val) {
      this.obj = val
      console.log(val)
      this.getDevicePage2(val)
    },
    //翻页
    handleCurrentChange2(val) {
      this.pager2.currentPage = val
      this.getDevicePage2(this.obj)
    },
    //页码大小
    handleSizeChange2(val) {
      this.pager2.pageSize = val
      this.getDevicePage2(this.obj)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
  height: calc(100vh - 350px) !important;
  overflow-y: auto !important;
}
.bindDevice {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  height: calc(100vh - 132px);
  overflow-y: hidden;
  .el-select {
    width: 194px;
  }
}

.addbtnbox {
  margin-bottom: 10px;
}
</style>
