<template lang="">
  <div class="menubox">
    <div class="left">
      <el-button class="newcaidan" @click="addMenuTop()">新增一级菜单</el-button>
       <el-tree
        :data="treedata"
        node-key="id"
        :load="getAllMenuTree"
        default-expand-all
        :expand-on-click-node="false"
        @node-click="showNode"
        style="height:calc(100vh - 232px);overflow-y:auto"
        :render-content="renderContent">
      </el-tree>
    </div>
    <div class="right">
      <el-form :model="treeNode" :rules="rules" ref="treeNode" label-width="100px" class="demo-ruleForm">
        <el-form-item label="菜单名称" prop="label">
          <el-input v-model="treeNode.label"></el-input>
        </el-form-item>
        <el-form-item label="菜单路由" prop="router">
          <el-input v-model="treeNode.router"></el-input>
        </el-form-item>
        <el-form-item label="路由名称" prop="target">
          <el-input v-model="treeNode.target"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标" prop="classs">
          <el-input v-model="treeNode.classs"></el-input>
        </el-form-item>
        <div class="menubtnbox"><el-button @click="saveMenu('treeNode')">保存</el-button></div>
      </el-form>
    </div>
  </div>
</template>
<script>
import {changeKey} from '../../libs/tools';
let id = 10;
export default {
  components:{
  },
  data(){
      return{
        treedata:[],
        treeNode:{
          label:'',
          router:'',
          target:'',
          classs:''
        },
        rules: {
          label: [
            { required: true, message: '请输入菜单名称', trigger: 'blur' }
          ],
          // router: [
          //   { required: true, message: '请输入菜单路由', trigger: 'blur' }
          // ],
          // target: [
          //   { required: true, message: '请输入路由名称', trigger: 'blur' }
          // ]
        }
      }
  },
  mounted(){
    this.getAllMenuTree();
  },
  methods:{
    showNode(node){
      this.treeNode = node;

    },
    addMenuTop(){
      let tmpdata = { value: id++, label: '待修改菜单',parentId:0, children: [] };
      this.treedata.push(tmpdata);
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span>
            <el-button size="mini" type="text" on-click={ () => this.append(data)  }>新增</el-button>
            <el-button size="mini" type="text" on-click={ () => this.remove(node, data) }>删除</el-button>
          </span>
        </span>);
    },
    getAllMenuTree(){
      this.$http.get(this.$api.getAllMenuTree).then(res=>{
        this.treedata = changeKey(res.data);
      })
    },
    append(data) {
      // console.log(data)
      const newChild = { value: id++, label: '待修改菜单',parentId:data.value, children: [] };
      if (!data.children) {
        this.$set(data, 'children', []);
      }
      data.children.push(newChild);
      // this.$http.post(this.$api.addMenuTree,{
      //   "fatherId":newChild.parentId,
      //   "classs":'',
      //   "name":newChild.label,
      //   "router":'',
      //   "target":'',
      //   "type":0,
      // }).then(res=>{
      //   console.log(res)
      //   if(res.statusCode ==200){
      //     this.getAllMenuTree();
      //     this.$message({
      //       type: 'success',
      //       message: '新增成功!'
      //     });
      //   }
      // })
    },

    remove(node, data) {
      // console.log(data)
      if(!data.children){

      }else{
        if(data.children.length>0){
        this.$notify({
          title: '警告',
          message: '存在子菜单不能删除',
          type: 'error'
        });
        return;
      }
      }

      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.value === data.value);
      console.log(node)
      console.log(data)
      this.$confirm('确认要删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(this.$api.delMenuById(data.value)).then(res=>{
          console.log(res)
          if(res.statusCode ==200){
            children.splice(index, 1);
            this.getAllMenuTree();
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    saveMenu(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.treeNode)
          if(this.treeNode.parentId == null){
            this.$notify({
              title: '警告',
              message: '请先选择左侧菜单',
              type: 'error'
            });
            return;
          }
          if(this.treeNode.value<100){
            //判断为新增
            // console.log(111)
            // console.log(this.treeNode);
            this.$http.post(this.$api.addMenuTree,{
              "fatherId":this.treeNode.parentId,
              "classs":this.treeNode.classs,
              "name":this.treeNode.label,
              "router":this.treeNode.router,
              "target":this.treeNode.target,
              "type":0,
            }).then(res=>{
              console.log(res)
              if(res.statusCode ==200){
                this.$message({
                  type: 'success',
                  message: '新增成功!'
                });
              }
            })
          }else{
            //编辑保存
            // console.log(222)
            // console.log(this.treeNode)
            this.$http.patch(this.$api.updateMenuTree,{
              "fatherId":this.treeNode.parentId,
              "id":this.treeNode.value,
              "classs":this.treeNode.classs,
              "name":this.treeNode.label,
              "router":this.treeNode.router,
              "target":this.treeNode.target,
              "type":0,
            }).then(res=>{
              if(res.statusCode ==200){
                this.getAllMenuTree();
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                });
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });



    }
  }
}
</script>
<style lang="scss" >
  .menubox{
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    .left{
      width: 40%;
      padding:0 20px;
      .custom-tree-node {
        width:100%;
        display: flex ;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
      }
      .newcaidan{
        width: 100%;
        margin:20px 0;
      }
    }
    .right{
      padding:0 20px;

      flex:1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .menuitem{
        flex:1;
        display: flex;
        align-items: center;
        justify-content: start;
        margin:10px 0;
        .menulabel{
          width:120px;
        }
        .menuvalue{
          width:200px;
        }
      }
      .menubtnbox{
        margin:0 auto;
        margin-top:20px;
      }
    }

  }
</style>
