<template>
  <div style='background-color: white;padding: 10px;border-radius: 8px;height: calc(100vh - 132px);overflow-y: hidden'>
    <searchForm :formOptions="formOptions"  @onSearch="onSearch" :btnItems='btnItems' @onExport='onExport'  @setSelect = 'setSelect'/>
    <div class='title'>{{ startTime }} 共 <span style='color:black'>{{ pager.total }}</span> 条  盘点数约 <span style='color:black'>{{ count }}</span> 头 </div>
    <page-table
      v-loading="loading"
      :tableData="tableData"
      :tableLabel="tableLabel"
      :tableOption="tableOption"
      class="gdtable"
      :pager="pager"
      :tableId='"eleLog"'
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
      @handleButton='tableBtnHandle(arguments)'
    ></page-table>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from "@/components/searchForm";
import moment from 'moment'
import { exportExcel, tranBuildType } from '../../libs/tools'
import store from '../../store'
import axios from "axios";
import {getDownload} from "../../libs/axios";

export default {
  name:'videoLog',
  components:{
    pageTable,
    searchForm
  },
  data(){
    return{
      selectBuildId:'',
      buildType: '',
      loading:true,
      btnItems: 'search'+'export'+'reset',
      startTime: moment().format('yyyy/MM/DD'),
      endTime: moment().format('yyyy/MM/DD'),
      obj: {
        buildType:undefined,
        buildId:undefined,
        buildingId:undefined,
        deviceName:undefined,
        startTime: moment().format('yyyy-MM-DD') +' 00:00:00',
        endTime: moment().format('yyyy-MM-DD') + ' 23:59:59'
      },
      count: 0,
      formOptions: [
        {
          label: '栋舍',
          prop: 'buildingId',
          element: 'el-select',
          options: this.getBuildType(),
          isChange:'change'
        },{
          label: '单元/区',
          prop: 'buildId',
          element: 'el-select',
          showTooltip:true,
          options: []
        },{
          label: '日期',
          prop: 'date',
          initValue: new Date(),
          type: 'date', //'daterange'monthrange | datetimerange | daterange |date
          element: 'el-date-picker'
        }
      ],
      tableData: [],
      tableLabel: [
        { label: '栋舍名称', param: 'buildingName' },
        { label: '单元/区名称', param: 'buildName' },
        { label: '单元类型', param: 'buildType' },
        { label: '栏位', param: 'filedName' },
        // { label: '设备名称', param: 'deviceName' },
        { label: '最近盘点数量(头)', param: 'count' ,align:'right'},
        { label: '平均体宽(cm)', param: 'avgWidth' ,align:'right'},
        { label: '平均体长(cm)', param: 'avgLength' ,align:'right'},
        { label: '平均体重(kg)', param: 'avgWeight' ,align:'right'},
        { label: '日期', param: 'date'}
      ],
      tableOption: {

      },
      pager:{
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total:0,  //列表总数
      }
    }
  },
  mounted() {
    this.init()
  },
  watch:{
    buildType(val){
      this.getAllPigpen(val)
    }
  },
  methods:{
    init(){
      this.getVideoLog(this.obj)
      this.getLogCount(this.obj)
      this.getAllPigpen()
    },
    setSelect(val){
      this.selectBuildId = val
      this.buildType = val
    },
    //视频盘点历史记录
    getVideoLog(obj) {
      let that = this
      this.$http.get(this.$api.videoInventoryLog, {
        ...obj,
        deviceType: '普利兹盘点摄像头',
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res => {
        if (res.data){
          let arr = res.data.records
          if (arr){
            arr.map(item=>{
              if (item.avgWeight=='None'){ item.avgWeight = '--' }
              if (item.avgWidth=='None'){ item.avgWidth = '--' }
              if (item.avgLength=='None'){ item.avgLength = '--' }
              if (item.date){
                item.date = item.date.replaceAll('-','/').substring(0,10)
              }
              item.buildType = tranBuildType(item.buildType)
            })
          }
          this.tableData = arr
          this.pager.total = res.data.total
          this.loading = false
        }else {
          this.loading = false
        }
      })

    },
    //视频盘点历史统计
    getLogCount(obj) {
      let that = this
      this.$http.get(this.$api.getVideoLogCount, {
        ...obj,
        deviceType: '普利兹盘点摄像头'
      }).then(res => {
        if (res.data.amount!=null){
          that.count = res.data.amount
        }else {
          that.count = 0
        }
      })
    },
    //单元类型列表
    getBuildType() {
      let arr = []
      this.$http.get(this.$api.getBuildList, {
        deviceType: '普利兹盘点摄像头'
      }).then(res => {
        if (res.data){
          res.data.map(item=>{
            arr.push({
              label: item.name,
              value: item.id
            })
          })
        }
      })
      return arr
    },
    loadTable(val){
      this.loading = true
      this.getVideoLog(val)
      this.getLogCount(val)
    },
    tableBtnHandle(arg){
      // console.log(arg[0])
      // console.log(arg[1])
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },
    onSearch (val) {
      if (val==null){
        return
      }
      if(val.date!=null){
        this.obj.startTime = moment(val.date).format('yyyy-MM-DD') +' 00:00:00'
        this.startTime = moment(val.date).format('yyyy/MM/DD')
        this.obj.endTime = moment(val.date).format('yyyy-MM-DD')+ ' 23:59:59'
        // this.endTime = moment(val.date).format('yyyy/MM/DD')
      }else {
        this.$message.warning("请选择日期")
        return
      }
      this.$set(this.pager,'currentPage',1)
      this.obj.buildId = val.buildId
      this.obj.buildingId = val.buildingId
      this.obj.buildType = val.buildType
      this.obj.deviceName = val.deviceName
      this.loadTable(this.obj)
    },
    //栋舍列表
    getAllPigpen(val) {
      let arr = []
      this.formOptions[1].options = []
      this.$http.get(this.$api.getAllPigpen, {
        deviceType: '普利兹盘点摄像头',
        buildingId:this.selectBuildId
      }).then(res => {
        if (res.data){
          res.data.map(item => {
            arr.push({
              label: item.name,
              value: item.id
            })
          })
          this.formOptions[1].options = arr
        }
      })
      return arr
    },
    onExport(val){
      if(val.date!=null){
        this.obj.startTime = moment(val.date).format('yyyy-MM-DD') + ' 00:00:00'
        this.startTime = moment(val.date).format('yyyy/MM/DD')
        this.obj.endTime = moment(val.date).format('yyyy-MM-DD')+ ' 23:59:59'
        // this.endTime = moment(val.date).format('yyyy/MM/DD')
      }else {
        this.$message.warning("请选择日期")
        return
      }
      this.obj.buildId = val.buildId
      this.obj.buildingId = val.buildingId
      this.obj.buildType = val.buildType
      this.loadTable(this.obj)
      this.exportHisWaterList(this.obj)
    },
    //列表导出
    exportHisWaterList(obj) {
      // let urls = store.state.baseUrl+this.$api.exportHisVideoInventoryList +'?deviceType=普利兹盘点摄像头&current=-1&size=-1&farmId=' + store.state.farmId
      // let buildType = ""
      // let buildName = ""
      // let startTime = ""
      // let endTime = ""
      // if (obj.buildType){
      //   buildType = obj.buildType
      //   urls = urls + '&buildType=' + buildType
      // }
      // if (obj.buildName){
      //   buildName = obj.buildName
      //   urls = urls + '&buildName=' + buildName
      // }
      // if (obj.startTime){
      //   startTime = obj.startTime
      //   urls = urls + '&startTime=' + startTime
      // }
      // if (obj.endTime){
      //   endTime = obj.endTime
      //   urls = urls + '&endTime=' + endTime
      // }
      // window.location.href = urls
      axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
      let headers = {
        'x-auth-token': localStorage.getItem('token')
      }
      getDownload(this.$api.exportHisVideoInventoryList,{
        farmId:store.state.farmId,
        ...obj
      },headers).then((res) => {
        let blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        // downloadElement.download = decodeURIComponent(res.headers.filename); //下载后文件名
        downloadElement.download = `视频盘点历史记录.xlsx`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放blob对象
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 327px) !important;
    overflow-y: auto !important;
}
.title {
  margin-bottom: 10px;
  color: #606266;
}
</style>
