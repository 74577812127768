<template>
  <div class="strategy">
    <searchForm :formOptions='formOptions' @onSearch='onSearch' :btnItems='btnItems'  />
    <div class="addbtnbox">
      <el-button
        v-if="btnItems.includes('search')"
        size="mini"
        type="primary"
        class="btn-search"
        @click="onAdd"
      >新增</el-button>
    </div>
    <!-- <div class='title'>共 {{ totalDevice }} 条 </span></div> -->
    <page-table
      :tableData='tableData'
      :tableLabel='tableLabel'
      :tableOption='tableOption'
      class="gdtable"
      :pager='pager'
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
      @handleButton='tableBtnHandle(arguments)'
      @changeTop='changeTop'
      @cancelTop='cancelTop'
    ></page-table>
    <el-dialog
      :title="editId==''?'新增策略':'编辑策略'"
      :visible.sync="centerDialogVisible"
      :destroy-on-close='true'
      width="70%"
      center>
      <div class="grid_content bg-purple">
        <label><span style='color: red'>*</span>策略名称：</label>
        <el-input v-model="chooseName" size="medium" ></el-input>
      </div>
      <TableEdit
        :datas="sxdataTable"
        :tableDataX.sync='tableDataX'
        @update:FathersaveRow="FathersaveRow"
        @update:FatherdeleteRow="FatherdeleteRow"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStrategy">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
import TableEdit from '@/components/TableEdit'

export default {
  name: 'strategy',
  components: {
    pageTable,
    searchForm,
    TableEdit
  },
  data() {
    return {
      tableDataX:[],
      editId:'',
      chooseName:'',
      sxdataTable: {
        tableTitle: [
          {prop:'valueType',label:'类型',type:'select',change:'isChange',options:[{value:'温度',label:'温度'},{value:'湿度',label:'湿度'},{value:'氨气浓度',label:'氨气浓度'}]},
          {prop:'minValue',label:'最小值',type:'minInput'},
          {prop:'maxValue2',label:'最大值',type:'maxInput'},
          {prop:'alarmType',label:'报警方式',type:'select',options:[{value:'电话',label:'电话'},{value:'短信',label:'短信'},{value:'现场(平板)',label:'现场(平板)'}]}
        ],
        tableData:[]
      },
      centerDialogVisible: false,
      totalDevice:0,
      obj:undefined,
      btnItems: 'search' + 'reset',
      formOptions: [

        {
          label: '策略名称',
          prop: 'tacticsName',
          element: 'el-input',
          placeholder: '策略名称'
        }

      ],
      tableData: [],
      tableLabel: [
        { label: '策略名称', param: 'tacticsName' },
        { label: '记录时间', param: 'createDate' }
      ],
      tableOption: {
        label: '操作',
        options: [
          { label: '编辑', methods: 'editDetail' },
          { label: '删除', methods: 'delDetail' },
        ]
      },
      pager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      }
    }
  },
  mounted() {
    this.getEnviPage()
  },
  methods: {

    onAdd(){
      this.tableDataX = []
      this.editId = '';
      this.chooseName = '';
      this.tableDataX.push({
        valueType: '水量计',
        desc: '是否大于前七天用量的平均值，浮动百分比:20%',
        isUse: false,
      }, {
        valueType: '电表',
        desc: '是否大于前七天用量的平均值，浮动百分比:20%',
        isUse: false
      }, {
        valueType: '料塔',
        desc: '余量是否小于前七天的下料量总和',
        isUse: false,
      })
      this.sxdataTable.tableData=[];
      this.centerDialogVisible = true;
    },
    saveStrategy(){
      let bool = true
      let tmpdata = this.sxdataTable.tableData;
      let tmpdata2 = this.tableDataX;
      let arr = []
      tmpdata.map(item=>{
        if (!item.isSet){
          if (item.valueType=='料塔余量'){
            item.minValue = item.minValue * 1000
          }
          item.valueType = item.valueType == '温度' ? 0 : item.valueType == '湿度' ? 1 : item.valueType == '氨气浓度' ? 2  : item.valueType == '其他' ? 3 :item.valueType == '水' ? 4 :item.valueType == '电' ? 5 :item.valueType == '料塔余量' ? 6 : item.valueType == '日耗料'? 7 : '';
          item.alarmType = item.alarmType == '电话' ? 0 : item.alarmType == '短信' ? 1 : item.alarmType == '现场(平板)' ? 2 : '';
          arr.push(item)
        }else {
          bool = false
        }
      })
      tmpdata2.map(item=>{
        let newObj = {
          valueType:'',
          isUse:'',
        }
        if (item.isUse!=null){
          newObj.valueType =  item.valueType == '水量计' ? 4 :item.valueType == '电表' ? 5 : 6
          newObj.isUse = (item.isUse ? "1" : "0")
          arr.push(newObj)
        }
      })
      // arr.map(item=>{
      //   console.log(item)
      //   console.log(item.isUse)
      // })
      let para = {
        tacticsName:this.chooseName,
        id:this.editId,
        details:arr
      }
      if (bool){
        if(this.editId != ''){
          //编辑保存
          this.$http.post(this.$api.updateEnvironmentaltactics,para).then(res=>{
            if(res.statusCode ==200){
              this.$message({
                type: 'success',
                message: '编辑保存成功'
              });
              this.getEnviPage();
            }
          })
        }else{
          //添加保存
          if (this.chooseName==''){
            this.$message({
              type: 'warning',
              message: '策略名称不能为空'
            });
            return
          }
          this.$http.post(this.$api.addEnvironmentaltactics,para).then(res=>{
            if(res.statusCode ==200){
              this.$message({
                type: 'success',
                message: '新增保存成功'
              });
              this.getEnviPage();
            }
          })
        }
        this.centerDialogVisible = false
      }else {
        this.centerDialogVisible = false
        this.$message.warning('请先保存编辑项')
      }

    },
    FatherdeleteRow(){

    },
    FathersaveRow(val){
      //console.log(val);
      //console.log(this.sxdataTable.tableData)
    },
    //策略设置列表
    getEnviPage(obj) {
      let that = this
      this.$http.get(this.$api.getEnviPage, {
        ...obj,
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res => {
        let arr =  res.data.records
        arr.map(item=>{
          if (item.online==1){
            item.online = '在线'
          }else {
            item.online = '离线'
          }
          item.createDate = item.createDate.replaceAll('-','/').substring(0,16)
        })
        this.tableData = arr
        this.pager.total = res.data.total
      })
    },

    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.getEnviPage(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.getEnviPage(this.obj)
    },
    changeTop(val){
      this.$http.patch(this.$api.changeTop(val), {
      }).then(res => {
      })
    },
    cancelTop(val){
      this.$http.patch(this.$api.cancelTop(val), {
      }).then(res => {
      })
    },
    getEnvironmentaltactics(id){
      this.editId = id;
      this.$http.get(this.$api.getEnvironmentaltactics(id)).then(res=>{
        let arr = []
        let arrData = []
          res.data.map(item=>{
            if (item.valueType == 6||item.valueType==4||item.valueType==5){
              arr.push(item)
            }else {
              item.valueType = item.valueType ==0 ? '温度' : item.valueType == 1 ? '湿度' : item.valueType == 2 ? '氨气浓度' :  '其他';
              item.alarmType = item.alarmType ==0 ? '电话' : item.alarmType == 1 ? '短信' : item.alarmType == 2 ? '现场(平板)' : undefined;
              item.isSet = false;
              arrData.push(item)
            }
          })
          this.sxdataTable.tableData = arrData;

        if (arr){
          let water = true
          let ele = true
          let food = true
          arr.map(item=>{
            if (item.isUse!=null){
              if (item.valueType==4){
                item.valueType = '水量计'
                item.desc = '是否大于前七天用量的平均值，浮动百分比:20%'
                item.isUse = (item.isUse == 1 );
                water = false
              }else if (item.valueType==5){
                item.valueType = '电表'
                item.desc = '是否大于前七天用量的平均值，浮动百分比:20%'
                item.isUse = (item.isUse == 1);
                ele = false
              }else if (item.valueType==6){
                item.valueType = '料塔'
                item.desc = '余量是否小于前七天的下料量总和'
                item.isUse = (item.isUse == 1)
                food = false
              };
            }
          })
          if (water){
            arr.push({
              valueType: '水量计',
              desc: '是否大于前七天用量的平均值，浮动百分比:20%',
              isUse: false
            })
          };
          if (ele){
            arr.push( {
              valueType: '电表',
              desc: '是否大于前七天用量的平均值，浮动百分比:20%',
              isUse: false
            })
          };
          if (water){
            arr.push({
              valueType: '料塔',
              desc: '余量是否小于前七天的下料量总和',
              isUse: false
            })
          };
          this.tableDataX = arr;
        }else {
          this.tableDataX.push({
            valueType: '水量计',
            desc: '是否大于前七天用量的平均值，浮动百分比:20%',
            isUse: false,
          }, {
            valueType: '电表',
            desc: '是否大于前七天用量的平均值，浮动百分比:20%',
            isUse: false
          }, {
            valueType: '料塔',
            desc: '余量是否小于前七天的下料量总和',
            isUse: false,
          })
        }
        //弹窗
        this.centerDialogVisible = true;

      })
    },
    tableBtnHandle(arg) {
      if(arg[0]=='editDetail'){
        //编辑
        this.chooseName = arg[1].tacticsName;
        this.tableDataX = []
        this.getEnvironmentaltactics(arg[1].id);
      }else{
        //删除
        this.$http.delete(this.$api.delEnvironmentaltactics(arg[1].id)).then(res=>{
          if(res.statusCode ==200){
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.getEnviPage();
          }
        }).catch(res=>{
          this.$message.error(res.message)
        })
      }
    },
    onSearch(val) {
      this.obj = val;
      this.getEnviPage(val)
      //this.loadTable(val)
    },

  }
}
</script>
<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 333px) !important;
    overflow-y: auto !important;
}
.title{
  margin-bottom: 10px;
}
.strategy{
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  height: calc(100vh - 132px);
  overflow-y: scroll;
  .addbtnbox{
    margin-bottom:10px;
  }
  .grid_content{
    margin-bottom:10px;
    .el-input{
      width:200px;
    }
  }
}
</style>
