<template>
  <div style='height: calc(100vh - 132px);overflow-y: hidden'>
    <el-row>
      <el-col class='fullHeight minWidth'>
        <el-card class='box' >
          <div>
            <el-form ref='form' :model='form' label-width='80px'>
              <el-form-item class='check' label='状态'>
                <el-checkbox-group v-model='form.status'>
                  <el-checkbox class='checkbox' label='开始' name='status' key='0'></el-checkbox>
                  <el-checkbox class='checkbox' label='持续' name='status' key='1'></el-checkbox>
                  <el-checkbox class='checkbox' label='结束' name='status' key='2'></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item class='check' label='日期'>
                <el-date-picker
                  v-model="form.data"
                  type="daterange"
                  align="right"
                  size='small'
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item class='btn' style='margin-left: 100px'>
                <el-button type='primary' @click='onSubmit("form")' size='small'>搜索</el-button>
              </el-form-item>
              <el-form-item class='btn' >
                <el-button  @click='reset("form")' size='small'>重置</el-button>
              </el-form-item>
              <el-form-item class='btn' >
                <el-button type='primary' size='small' @click='onExport()'>导出</el-button>
              </el-form-item>

            </el-form>
          </div>
          <page-table
            :tableData='tableData'
            :tableLabel='tableLabel'
            :tableOption='tableOption'
            class="gdtable"
            :toTop='toTop'
            :pager='pager'
            @pagination='handleCurrentChange'
            @handleSizeChange='handleSizeChange'
            @handleButton='tableBtnHandle(arguments)'
          ></page-table>

        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
import login from '../../api/user/login'
import pickerOptionsRange from '@/libs/tools'
import moment from 'moment'
import store from '../../store'

export default {
  name: 'followRecord',
  components: {
    pageTable,
    searchForm
  },
  data() {
    return {
      pickerMinDate:'',
      pickerOptions: {
        disabledDate:(time)=> {
          if (this.pickerMinDate) {
            const day1 =  1095 * 24 * 3600 * 1000
            let maxTime = ''
            let minTime = ''
            if (Date.now() - this.pickerMinDate > day1){
              maxTime = this.pickerMinDate + day1
              minTime = this.pickerMinDate - day1
              return time.getTime() > maxTime || time.getTime()<minTime || time.getTime() > Date.now()
            }else {
              maxTime = Date.now()
              minTime = this.pickerMinDate - day1
              return time.getTime() > maxTime || time.getTime()<minTime || time.getTime() > Date.now()
            }
          }else{
            return time.getTime() > Date.now()
          }

        },
        onPick:(obj) =>{
          this.pickerMinDate = new Date(obj.minDate).getTime();
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              const end = new Date()
              const start = new Date(new Date().toDateString())
              start.setTime(start.getTime())
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近十五天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      obj: {
        // startTime: moment().format('yyyy-MM-DD'),
        // endTime: moment().format('yyyy-MM-DD'),
        startTime: undefined,
        endTime: undefined,
        status: undefined
      },
      btnItems: 'search',
      radio: '按小时',
      form: {
        data: undefined,
        status: ['开始','持续']
      },
      tableData: [],
      tableLabel: [
        // { label: '栋舍名称', param: 'buildName'},
        { label: '关注事项', param: 'eventDesc',width:450,align:'left' },
        { label: '状态', param: 'status' },
        { label: '开始时间', param: 'createDate' },
        { label: '记录时间', param: 'statusDate' },
        // { label: '通知人', param: 'remindPeople' },
        // { label: '通知方式', param: 'remindType' },
        // { label: '发送状态', param: 'remindStatus' },
      ],
      tableOption: {},
      toTop: {},
      pager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      }
    }
  },
  mounted() {
    this.onSubmit("form")
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.data){
            this.obj.startTime = moment(this.form.data[0]).format('yyyy-MM-DD') +' 00:00:00'
            this.obj.endTime = moment(this.form.data[1]).format('yyyy-MM-DD') + ' 23:59:59'
          }else {
            this.obj.startTime = undefined
            this.obj.endTime = undefined
          }
          let arr = []
          if (this.form.status.includes("开始")){
            arr.push(0)
          }
          if (this.form.status.includes("持续")){
            arr.push(1)
          }
          if (this.form.status.includes("结束")){
            arr.push(2)
          }
          this.obj.status = arr.join(',')

          this.loadTable(this.obj)
        } else {
          return false;
        }
      });
    },
    reset(){
      this.form.status = []
      this.form.data =  undefined
      this.obj.startTime = undefined
      this.obj.endTime = undefined
      this.obj.status = undefined
      this.loadTable(this.obj)

    },
    //关注
    getFollowEvent(obj) {
      let that = this
      this.$http.get(this.$api.getFollowEvent, {
        ...obj,
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res => {
        let arr = res.data.records
        if (arr) {
          arr.map(item => {
            item.statusDate = item.statusDate.replaceAll('-','/').substring(0,16)
            item.createDate = item.createDate.replaceAll('-','/').substring(0,16)
            if (item.status == 0) {
              item.status = '开始'
            } else if (item.status == 1) {
              item.status = '持续'
            } else{
              item.status = '结束'
            }
          })
        }
        this.tableData = arr
        this.pager.total = res.data.total
      })
    },

    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },

    tableBtnHandle(arg) {
      console.log(arg[0])
      console.log(arg[1])
    },
    onSearch(val) {

    },
    //重载表格
    loadTable(val) {
      this.getFollowEvent(val)
    },
    onExport() {
      if (this.form.data){
        this.obj.startTime = moment(this.form.data[0]).format('yyyy-MM-DD')
        this.obj.endTime = moment(this.form.data[1]).format('yyyy-MM-DD')
      }else {
        this.obj.startTime = undefined
        this.obj.endTime = undefined
      }
      let arr = []
      if (this.form.status.includes("开始")){
        arr.push(0)
      }
      if (this.form.status.includes("持续")){
        arr.push(1)
      }
      if (this.form.status.includes("结束")){
        arr.push(2)
      }
      this.obj.status = arr.join(',')
      this.loadTable(this.obj)
      this.exportWaterList(this.obj)
    },
    //列表导出
    exportWaterList(obj) {
      let urls = store.state.baseUrl+this.$api.exportFollowevent +'?current=-1&size=-1&farmId=' + store.state.farmId
      let status = ""
      let startTime = ""
      let endTime = ""
      if (obj.status){
        status = obj.status
        urls = urls + '&status=' + status
      }
      if (obj.startTime){
        startTime = obj.startTime
        urls = urls + '&startTime=' + startTime
      }
      if (obj.endTime){
        endTime = obj.endTime
        urls = urls + '&endTime=' + endTime
      }
      window.location.href = urls
    }

  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 296px) !important;
    overflow-y: auto !important;
}

.minWidth {
  min-width: 480px;
}

.minWidth2 {
  padding: 0 0 0 10px;
  min-width: 600px;
}
.word97 {
  font-size: 16px;
  font-family: PingFangSC-Medium;
  color: rgba(53, 138, 255, 1);
  line-height: 22px;
}
::v-deep .el-form-item__label {
  padding-right: 10px;
}

::v-deep .el-form-item.select {
  display: inline-block;
  width: 300px;
  margin: 0 15px 0 0;
}
::v-deep .el-form-item.btn {
  display: inline-block;
  width: 100px;
  margin: 0 15px 0 0;
}

::v-deep .el-form-item.check {
  display: inline-block;
  width: 300px;
  margin: 0 15px 0 0;
}

::v-deep .el-form-item.btn {
  display: inline-block;
  width: 50px;
  margin: 0 15px 0 0;
}

::v-deep .el-checkbox.checkbox {
  margin-right: 10px;
}

.checkbox ::v-deep .el-checkbox__label {
  padding-left: 3px;
}

::v-deep .el-select {
  width: 210px;
}

::v-deep .el-card__body {
  padding: 10px;
}

::v-deep .el-card__header {
  padding: 10px 10px 0 10px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color:  #2C36C3;
  border-color: #2C36C3;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #FFF;
  background-color:  #2C36C3;
  border-color: #2C36C3;
  box-shadow: -1px 0 0 0 #2C36C3;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #2C36C3;
}
::v-deep .el-date-editor .el-range-separator {
  padding: 0 ;
  line-height: 26px;
  width: 7%;
  color: #303133;
}
</style>
