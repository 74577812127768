<template>
  <div style='height: calc(100vh - 132px)'>
    <el-row style='height: 100%;'>
      <el-col :span='10' style='height: 100%;background-color: white;border-right:1px solid whitesmoke'>
        <el-tree
          :data='roleData'
          :props='roleProps'
          @node-click='handleRoleClick'></el-tree>
      </el-col>

      <el-col :span='14' style='height: 100%;background-color: white;overflow-y: scroll' class='menu'>
        <el-button type='primary' size='small' style='margin-bottom:10px' @click='add'>保存</el-button>
        <el-tree
          :data='treeData'
          show-checkbox
          ref='tree'
          :props='defaultProps'
          node-key='id'
          :default-checked-keys='defaultSelect'
          @node-click='handleNodeClick'></el-tree>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { changeKey } from '../../libs/tools'

export default {
  name: 'bindMenu',
  data() {
    return {
      treeData: [],
      roleData: [],
      selectRole: [],
      defaultSelect: [],
      defaultRoleSelect:'',
      defaultProps: {
        children: 'children',
        label: 'name',
        id:'id'
      },
      roleProps: {
        label: 'roleName'
      }
    }
  },
  created() {
    this.getAllMenuTree()
    this.getAllRoles()
  },
  methods: {
    add(){
      let arr = this.$refs.tree.getCheckedKeys()
      let arr1 = this.$refs.tree.getHalfCheckedKeys()

      let data = [...arr,...arr1]

      this.$http.post(this.$api.addRoleOfMenu,
      {
          menuIds: data,
          roleId: this.selectRole
        }
      ).then(res => {
        if (res.statusCode==200){
          this.$message.success("保存成功")
        }else {
          this.$message.error("保存失败")
        }
        this.getAllRoles()
      })
    },
    getAllRoles() {
      this.$http.get(this.$api.getAllRoles).then(res => {
        if (res.data) {
          this.roleData = res.data
          this.selectRole = res.data[0].roleId
          // this.handleRoleClick(res.data[0])
        }
      })
    },
    getAllMenuTree() {
      this.$http.get(this.$api.getAllMenuTree).then(res => {
        this.treeData = res.data
      })
    },
    handleNodeClick(data) {
      console.log(data)
    },
    handleRoleClick(data) {
      console.log(data)
      this.selectRole = data.roleId
      this.$refs.tree.setCheckedKeys([]);
      this.$http.get(this.$api.getRoleMenuIds,
        {
          roleId: data.roleId
        }).then(res => {
        this.defaultSelect = res.data
      })

    }
  }
}
</script>

<style scoped>

</style>
