<template lang="">
    <div class="GoodsListView">
     <el-row :gutter="3" class="list-operation">
      <el-col :span="8" :xs="12">
        <div class="grid-content bg-purple">
          <el-input v-model="key_world" placeholder="搜索分类,描述" clearable style="width: 350px"></el-input>
          <el-button type="primary" plain @click="getUserList">搜索</el-button>
        </div>
      </el-col>
      <el-col :span="2" :xs="6">
        <div class="grid-content bg-purple">
          <el-button type="success" plain @click="multipleEnabled(1)">批量开启</el-button>
        </div>
      </el-col>
      <el-col :span="2" :xs="6">
        <div class="grid-content bg-purple">
          <el-button type="warning" plain @click="multipleEnabled(0)">批量关闭</el-button>
        </div>
      </el-col>
      <el-col :span="3" :xs="6">
        <div class="grid-content bg-purple">
          <el-button type="danger" @click="multipleDel()">批量删除</el-button>
        </div>
      </el-col>
    </el-row>

     <el-row>
    <el-table
    :data="tableData" border
    v-loading="loading"
    style="width: 100%">
    <el-table-column
          type="selection"
          align="center"
          width="50"
        >
    </el-table-column>
    <el-table-column
      label="编号"
      align="center" width="70">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span style="margin-left: 10px">{{ scope.row.id }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="商品名称"
      align="center">
      <template slot-scope="scope">
        <el-popover trigger="hover" placement="top">
          <p>UUID: {{ scope.row.guid }}</p>
          <p>IP地址: {{ scope.row.ip }}</p>
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium">{{ scope.row.goodsName }}</el-tag>
          </div>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column
      label="地址"
      align="center">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span style="margin-left: 10px">{{ scope.row.address }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="图片"
      align="center" width="100">
      <!-- <template slot-scope="scope">
        <img :src="scope.row.image" min-width="70" height="70">
      </template> -->
      <template>
        <img src="/img/admin-logo.jpg" min-width="70" height="70">
      </template>
    </el-table-column>
    <el-table-column
      label="电子邮件"
      align="center">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span style="margin-left: 10px">{{ scope.row.email }}</span>
      </template>
    </el-table-column>
     <el-table-column
      label="上架时间"
      align="center">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center" width="180">
      <template slot-scope="scope">
        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
      </template>
     </el-table-column>
     </el-table>
     </el-row>

     <el-row class="pagination">
      <el-pagination
        background
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        :current-page="page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>
    </div>
</template>
<script>
export default {
  name: 'GoodsListView',
  data() {
    return {
      tableData: [],
      total: 100,
      page: 1,
      page_size: 10,
      key_world: '',
      loading: false
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    getUserList() {
      this.loading = true
      // this.$api.GoodsApi.getGoodsList()
      //   .then(rep => {
      //     this.tableData = rep.data.goodsList
      //     this.total = rep.data.total
      //     this.loading = false
      //   })
      //   .catch(err => {
      //     this.loading = true
      //     console.log(err)
      //   })
    },
    handleEdit(index, row) {
      /// todo 修改页面传递id参数
      this.$emit('editGoods', row.id)
    },
    handleDelete(index, row) {

    },
    handleSizeChange(pageSize) {
      this.page_size = pageSize
    },
    handleCurrentChange(page) {
      this.page = page
    },
    multipleDel() {

    }
  }
}
</script>
<style lang="scss">
 .list-operation {
    margin: 15px 0;
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .grid-content {
    display: flex;
    justify-content: space-around;
  }
</style>
