<template>
  <div style='background-color: white;padding: 10px;border-radius: 8px;height: calc(100vh - 132px);overflow-y: hidden' >
    <searchForm :formOptions='formOptions' @onSearch='onSearch' :btnItems='btnItems' @onExport='onExport' @setSelect = 'setSelect' @setSelect2 = 'setSelect2'/>
    <div class='title'>共 <span style='color:black'>{{ totalDevice }}</span> 条，在线 <span style='color:black'>{{ onlineDevice }}</span>，离线 <span style='color:red'>{{offlineDevice}}</span></div>
    <page-table
      v-if="hardReset"
      v-loading="loading"
      :tableData='tableData'
      :tableLabel='tableLabel'
      :tableOption='tableOption'
      :tableVideo='tableVideo'
      class="gdtable"
      :toTop='toTop'
      :pager='pager'
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
      @handleButton='tableBtnHandle(arguments)'
      @changeTop='changeTop'
      @cancelTop='cancelTop'
    ></page-table>
    <el-dialog
      title='视频播放'
      :append-to-body='true'
      :visible.sync='editVisible'
      width='52%'
      top='12vh'
    >
      <div style='padding:10px 10px;background-color: black;color: white;text-align: center'>
        <span style='color: white;float: left'>{{selectRow.buildName}}-{{selectRow.filedName}}</span>
        <span style='padding:0px 10px'>盘点头数:{{selectRow.count?selectRow.count:'-'}}头</span>
        <span style='padding:0px 10px'>平均体宽:{{selectRow.avgWidth?selectRow.avgWidth:'-'}}cm</span>
        <span style='padding:0px 10px'>平均体长:{{selectRow.avgLength?selectRow.avgLength:'-'}}cm</span>
        <span style='padding:0px 10px'>平均体重:{{selectRow.avgWeight?selectRow.avgWeight:'-'}}kg</span>
      </div>
      <VideoPlayer v-if='editVisible' :urlVideo='urlVideo'></VideoPlayer>
    </el-dialog>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
import login from '../../api/user/login'
import { exportExcel, tranBuildType } from '../../libs/tools'
import store from '../../store'
import VideoPlayer from '../../components/VideoPlayer'
import Template from './template'
import axios from "axios";
import {getDownload} from "../../libs/axios";
export default {
  name: 'videoDeviceList',
  components: {
    Template,
    pageTable,
    VideoPlayer,
    searchForm
  },
  data() {
    return {
      selectBuildId:'',
      selectRow:{
      },
      buildType:'',
      buildId:'',
      loading:true,
      hardReset:true,
      totalDevice:0,
      onlineDevice:0,
      offlineDevice:0,
      obj:undefined,
      urlVideo: '',
      editVisible: false,
      btnItems: 'search' + 'export' + 'reset',
      formOptions: [
        {
          label: '栋舍',
          prop: 'buildingId',
          element: 'el-select',
          options: this.getBuildType(),
          isChange: 'change',

        },{
          label: '单元/区',
          prop: 'buildId',
          element: 'el-select',
          isChange: 'change2',
          showTooltip:true,
          options: []
        }, {
          label: '栏位',
          prop: 'fieldId',
          element: 'el-select',
          options: []
        },{
          label: '状态',
          prop: 'online',
          element: 'el-select',
          options: [
            { label: '在线', value: '1' },
            { label: '离线', value: '0' }
          ]
        },
        {
          label: '设备名称',
          prop: 'deviceName',
          element: 'el-input',
          placeholder: '请输入设备名称'
        }

      ],
      tableData: [],
      tableLabel: [
        { label: '栋舍名称', param: 'buildingName' },
        { label: '单元/区名称', param: 'buildName' },
        { label: '单元类型', param: 'buildType' },
        { label: '栏位', param: 'fieldName' },
        // { label: '设备名称', param: 'deviceName' },
        { label: '最近盘点数(头)', param: 'count' ,align:'right'},
        { label: '平均体宽(cm)', param: 'avgWidth' ,align:'right'},
        { label: '平均体长(cm)', param: 'avgLength' ,align:'right'},
        { label: '平均体重(kg)', param: 'avgWeight' ,align:'right'},
        { label: '状态', param: 'online',status:1}
      ],
      tableOption: {
        label: '实时视频',
        options: [
          { label: '实时视频', methods: 'video' },
        ]
      },
      tableVideo: {

      },
      toTop: {
        label: '置顶',
        options: [
          { label: '详情', methods: 'editDetail' },
        ]
      },
      pager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      }
    }
  },
  watch:{
    buildType(val){
      this.getAllPigpen(val)
    },
    buildId(val){
      this.getFiled(val)
    }
  },
  mounted() {
    this.getVideoDeviceList()
    this.getStatusCount()
    this.getAllPigpen()
  },
  methods: {
    setSelect(val){
      this.selectBuildId = val
      this.buildType = val
    },
    setSelect2(val){
      this.buildId = val
    },
    //设备列表
    getVideoDeviceList(obj) {
      let that = this
      this.$http.get(this.$api.videoInventoryList, {
        ...obj,
        deviceType:'普利兹盘点摄像头',
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res => {
        let arr =  res.data.records
        if (arr){
          arr.map(item=>{
            item.buildType = tranBuildType(item.buildType)
            if (item.avgWeight=='None'){ item.avgWeight = '--' }
            if (item.avgWidth=='None'){ item.avgWidth = '--' }
            if (item.avgLength=='None'){ item.avgLength = '--' }
            if (item.online==1){
              item.online = '在线'
            }else {
              item.online = '离线'
            }
          })
        }
        this.tableData = arr
        this.pager.total = res.data.total
        this.loading = false
        this.hardReset = true
      })
    },
    //断电报警统计
    getStatusCount(obj) {
      let that = this
      this.$http.get(this.$api.getStatusCount, {
        ...obj,
        deviceType:'普利兹盘点摄像头',
      }).then(res => {
        if (res.data){
          that.offlineDevice = res.data.offline
          that.onlineDevice = res.data.online
          that.totalDevice = res.data.total
        }
      })
    },
    //栋舍列表
    getAllPigpen(val) {
      let arr = []
      this.formOptions[1].options = []
      this.$http.get(this.$api.getAllPigpen, {
        deviceType:'普利兹盘点摄像头',
        buildingId:this.selectBuildId
      }).then(res => {
        if (res.data){
          res.data.map(item=>{
            arr.push({'label':item.name,'value':item.id})
            // arr.push({
            //   label:item.name,
            //   value:item.name
            // })
          })
          this.formOptions[1].options  = arr
        }
      })
    },
    //单元类型列表
    getBuildType() {
      let arr = []
      this.$http.get(this.$api.getBuildList, {
        deviceType: '普利兹盘点摄像头'
      }).then(res => {
        if (res.data){
          res.data.map(item=>{
            arr.push({
              label: item.name,
              value: item.id
            })
          })
        }
      })
      return arr
    },
    //栏位列表
    getFiled(val) {
      let list = []
      this.formOptions[2].options = []
      this.$http.get(this.$api.getPigpenFiled,{
        buildId:val
      }).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'label':item.name,'value':item.id})
          })
          this.formOptions[2].options = list
        }
      })
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },
    changeTop(val){
      this.$http.patch(this.$api.changeTop(val), {
      }).then(res => {
      })
    },
    cancelTop(val){
      this.$http.patch(this.$api.cancelTop(val), {
      }).then(res => {
      })
    },
    tableBtnHandle(arg) {
      // this.editVisible = true
      this.loading = true

      if (arg[0] == 'video') {
        this.urlVideo = null
        this.$http.get(this.$api.queryStreamUrl, {
          pigpenId:arg[1].fieldId
        }).then(res => {
          // this.urlVideo = 'http://aiem-000064.plc-tek.com:20015/stream/live/4209201bc8379b88/4209201bc8379b88.m3u8'
          // this.urlVideo = 'http://aiem-000064.plc-tek.com:20015/stream/live/5498544712cbe272/5498544712cbe272.m3u8'
          this.urlVideo = res.message
          this.loading = false
          this.editVisible = true
          if (this.urlVideo){
            this.selectRow = arg[1]
          }else {
            this.$message.warning("无连接设备")
          }
        }).catch(res=>{
          this.loading = false;
          this.$message.warning(res.message)
        })
      }
    },


    onSearch(val) {
      this.$set(this.pager,'currentPage',1)
      this.hardReset = false
      this.obj = val
      this.loadTable(val)
    },
    //重载表格
    loadTable(val){
      this.hardReset = false
      this.loading = true
      this.getVideoDeviceList(val)
      this.getStatusCount(val)
    },
    onExport(val) {
      this.obj = val
      this.loadTable(val)
      this.exportWaterList(val)
    },
    //列表导出
    exportWaterList(obj) {
      // let urls = store.state.baseUrl+this.$api.exportVideoInventoryList +'?deviceType=普利兹盘点摄像头&current=-1&size=-1&farmId=' + store.state.farmId
      // let buildType = ""
      // let buildName = ""
      // let online = ""
      // let deviceName = ""
      // if (obj.buildType){
      //   buildType = obj.buildType
      //   urls = urls + '&buildType=' + buildType
      // }
      // if (obj.buildName){
      //   buildName = obj.buildName
      //   urls = urls + '&buildName=' + buildName
      // }
      // if (obj.online){
      //   online = obj.online
      //   urls = urls + '&online=' + online
      // }
      // if (obj.deviceName){
      //   deviceName = obj.deviceName
      //   urls = urls + '&deviceName=' + deviceName
      // }
      // window.location.href = urls
      axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
      let headers = {
        'x-auth-token': localStorage.getItem('token')
      }
      getDownload(this.$api.exportVideoInventoryList,{
        farmId:store.state.farmId,
        ...obj
      },headers).then((res) => {
        let blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        // downloadElement.download = decodeURIComponent(res.headers.filename); //下载后文件名
        downloadElement.download = `视频盘点设备列表.xlsx`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放blob对象
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 327px) !important;
    overflow-y: auto !important;
}
.title{
  margin-bottom: 10px;
  color: #606266;
}
</style>
