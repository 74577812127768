<template>
  <div class="bindDevice">
    <searchForm :formOptions="formOptions" @onSearch="onSearch" :btnItems="btnItems" />
    <div class="addbtnbox">
      <el-button v-if="btnItems.includes('search')" size="mini" type="primary" class="btn-search" @click="onAdd">新增</el-button>
    </div>
    <page-table
      :tableData="tableData"
      :tableLabel="tableLabel"
      :tableOption="tableOption"
      class="gdtable"
      :pager="pager"
      @pagination="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleButton="tableBtnHandle(arguments)"
    ></page-table>
    <el-dialog :title="this.editId == '' ? '新增' : '修改'" :visible.sync="centerDialogVisible" :destroy-on-close="true" v-if="centerDialogVisible" width="70%">
      <!-- <div class="grid-content bg-purple"> -->
      <el-form :inline="true" :rules="rules" :model="formItem" ref="formItem" :label-position="labelPosition" label-width="120px" class="demo-form-inline">
        <el-form-item label="位置" prop="passagewayPosition">
          <el-input v-model="formItem.passagewayPosition" placeholder="位置"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="controlAccount">
          <el-select v-model="formItem.controlAccount" placeholder="负责人">
            <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <!--            <el-input v-model="formItem.controlAccount" placeholder="负责人" ></el-input>-->
        </el-form-item>
        <el-form-item label="过道名称" prop="passagewayName">
          <el-input v-model="formItem.passagewayName" placeholder="过道名称"></el-input>
        </el-form-item>
        <el-form-item label="摄像头" prop="deviceName">
          <el-select v-model="formItem.deviceName" placeholder="摄像头" @change="queryName">
            <el-option v-for="item in videolist" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="称重秤" prop="deviceWeightName">
          <el-select v-model="formItem.deviceWeightName" placeholder="称重秤" @change="queryWeightName">
            <el-option v-for="item in czlist" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStrategy('formItem')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import searchForm from '@/components/searchForm'
import pageTable from '@/components/pageTable'
import axioss from 'axios'
import axios from '../../api'
import store from '../../store'
import qs from 'qs'
export default {
  name: 'salePig',
  components: {
    searchForm,
    pageTable
  },
  data() {
    return {
      editId: '',
      userList: [],
      videolist: [],
      czlist: [],
      rules: {
        passagewayPosition: [{ required: true, message: '请输入位置', trigger: 'blur' }],
        controlAccount: [{ required: true, message: '请输入责任人', trigger: 'blur' }],
        passagewayName: [{ required: true, message: '请输入过道名称', trigger: 'blur' }],
        deviceName: [{ required: true, message: '请选择摄像头', trigger: 'change' }],
        deviceWeightName: [{ required: true, message: '请选择称重秤', trigger: 'change' }]
      },
      labelPosition: 'right',
      centerDialogVisible: false,
      btnItems: 'search' + 'reset',
      formOptions: [
        {
          label: '过道名称',
          prop: 'passagewayName',
          element: 'el-input',
          placeholder: '通道名称'
        }
      ],

      tableData: [],
      tableLabel: [
        { label: '位置', param: 'passagewayPosition' },
        { label: '负责人', param: 'controlAccount' },
        { label: '过道名称', param: 'passagewayName' },
        { label: '摄像头', param: 'deviceName' },
        { label: '称重秤', param: 'deviceWeightName' }
      ],
      tableOption: {
        label: '操作',
        options: [
          { label: '编辑', methods: 'editDetail' },
          { label: '删除', methods: 'delDetail' }
        ]
      },
      pager: {
        pageSize: 10, // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0 //列表总数
      },

      formItem: {
        passagewayPosition: '',
        controlAccount: '',
        passagewayName: '',
        deviceId: '',
        deviceWeightId: '',
        deviceName: '',
        deviceWeightName: ''
      }
    }
  },
  mounted() {
    this.getPigSalePassageway()
    this.getDevicePage('海康计数摄像头,普通摄像头') //摄像头列表
    this.getDevicePage2('销售称重秤') //摄像头列表
    this.getUserList()
  },
  methods: {
    queryName(e) {
      this.formItem.deviceId = e
    },
    queryWeightName(e) {
      this.formItem.deviceWeightId = e
    },
    onAdd() {
      this.editId = ''
      this.formItem = {
        passagewayPosition: '',
        controlAccount: '',
        passagewayName: '',
        deviceId: '',
        deviceWeightId: '',
        deviceName: '',
        deviceWeightName: ''
      }
      this.centerDialogVisible = true
    },
    //摄像头列表
    getDevicePage(val) {
      this.videolist = []
      this.$http
        .get(this.$api.getDevicePage, {
          current: -1,
          size: -1,
          deviceTypes: val
        })
        .then(res => {
          res.data.records.map(item => {
            this.videolist.push({ value: item.deviceId, label: item.deviceName })
          })
          console.log(this.videolist)
        })
    },
    //负责人列表
    getUserList() {
      this.userList = []
      // axioss.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
      // axioss.defaults.headers['Content-Type']='application/x-www-form-urlencoded;charset=UTF-8';
      // axioss.defaults.headers.common['Content-Type']='application/x-www-form-urlencoded;charset=UTF-8';
      // console.log(axioss.defaults.headers)
      // console.log(111)
      // // axios('post', store.state.baseUrl + '/upms/UnifySmSysInfo/page?size=-1&current=-1&regionId='+store.state.farmId).then(res => {
      // //   console.log(res)
      // //   if (res.code === 200) {
      // //     console.log(res)
      // //   }
      // // })
      // console.log(qs)
      // axios('post', store.state.baseUrl + '/upms/UnifySmSysInfo/page', qs.stringify({
      //   size:-1,
      //   current:-1,
      //   regionId:store.state.farmId
      // })).then(res => {
      //   console.log(res)
      //   if (res.code === 200) {
      //     console.log(res)
      //   }
      // })
      this.$http
        .post(this.$api.getUserList, {
          size: -1,
          current: -1,
          contentType: 'application/x-www-form-urlencoded;charset=UTF-8'
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res)
            res.result.records.map(item => {
              this.userList.push({ value: item.account, label: item.nikeName + '(' + item.account + ')' })
            })
          }
        })
      console.log(this.userList)
    },
    //称重秤列表
    getDevicePage2(val) {
      this.czlist = []
      this.$http
        .get(this.$api.getDevicePage, {
          current: -1,
          size: -1,
          deviceType: val
        })
        .then(res => {
          res.data.records.map(item => {
            this.czlist.push({ value: item.deviceId, label: item.deviceName })
          })
        })
    },
    //保存
    saveStrategy(formName) {
      console.log(this.formItem)
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.editId == '') {
            this.$http
              .post(this.$api.addPigSalePassageway, {
                passagewayName: this.formItem.passagewayName,
                passagewayPosition: this.formItem.passagewayPosition,
                deviceId: this.formItem.deviceId,
                deviceWeightId: this.formItem.deviceWeightId,
                controlAccount: this.formItem.controlAccount
              })
              .then(res => {
                if (res.statusCode == 200) {
                  this.$message({
                    type: 'success',
                    message: '新增成功'
                  })
                  this.centerDialogVisible = false
                  this.getPigSalePassageway()
                }
              })
          } else {
            //编辑保存
            this.$http
              .patch(this.$api.updatePigSalePassageway, {
                id: this.editId,
                passagewayName: this.formItem.passagewayName,
                passagewayPosition: this.formItem.passagewayPosition,
                deviceId: this.formItem.deviceId,
                deviceWeightId: this.formItem.deviceWeightId,
                controlAccount: this.formItem.controlAccount
              })
              .then(res => {
                if (res.statusCode == 200) {
                  this.$message({
                    type: 'success',
                    message: '修改成功'
                  })
                  this.centerDialogVisible = false
                  this.getPigSalePassageway()
                }
              })
          }
        } else {
          return false
        }
      })
    },
    getPigSalePassageway(obj) {
      let that = this
      this.$http
        .get(this.$api.getPigSalePassageway, {
          ...obj,
          current: that.pager.currentPage,
          size: that.pager.pageSize
        })
        .then(res => {
          this.tableData = res.data.records
          this.pager.total = res.data.total
        })
    },
    tableBtnHandle(arg) {
      if (arg[0] == 'editDetail') {
        //编辑
        console.log(arg[1])
        //this.setDialog(arg[1]);

        
        this.$set(this.formItem,'passagewayName',arg[1].passagewayName);
        this.$set(this.formItem,'passagewayPosition',arg[1].passagewayPosition);
        this.$set(this.formItem,'deviceId',arg[1].deviceId);
        this.$set(this.formItem,'deviceWeightId',arg[1].deviceWeightId);
        this.$set(this.formItem,'deviceName',arg[1].deviceName);
        this.$set(this.formItem,'deviceWeightName',arg[1].deviceWeightName);
        this.$set(this.formItem,'controlAccount',arg[1].controlAccount);

        this.editId = arg[1].id
        // this.formItem.passagewayName = arg[1].passagewayName
        // this.formItem.passagewayPosition = arg[1].passagewayPosition
        // this.formItem.deviceId = arg[1].deviceId
        // this.formItem.deviceWeightId = arg[1].deviceWeightId
        // this.formItem.deviceName = arg[1].deviceName
        // this.formItem.deviceWeightName = arg[1].deviceWeightName
        // this.formItem.controlAccount = arg[1].controlAccount
        this.centerDialogVisible = true
      } else {
        this.$http.delete(this.$api.delPigSalePassageway(arg[1].id)).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.getPigSalePassageway()
        })
      }
    },
    onSearch(val) {
      this.$set(this.pager, 'currentPage', 1)
      this.obj = val
      this.getPigSalePassageway(val)
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.getPigSalePassageway(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.getPigSalePassageway(this.obj)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
  height: calc(100vh - 333px) !important;
  overflow-y: auto !important;
}
.bindDevice {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  height: calc(100vh - 132px);
  overflow-y: hidden;
  .el-select {
    width: 194px;
  }
}
.addbtnbox {
  margin-bottom: 10px;
}
</style>
