<template>
  <div class="loglist">
    <searchForm :formOptions='formOptions' @onSearch='onSearch' :btnItems='btnItems' @setSelect = 'setSelect'  />
    <page-table
      :tableData='tableData'
      :tableLabel='tableLabel'
      :pager='pager'
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
    ></page-table>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
export default {
  name: 'logList',
  components: {
    pageTable,
    searchForm
  },
  data(){
    return{
      btnItems: 'search' + 'reset',
      formOptions: [
        {
          label: '栋舍名称',
          prop: 'buildName',
          element: 'el-input',
          placeholder: '栋舍名称'
        },
        {
          label: '栋舍列表',
          prop: 'buildId',
          element: 'el-select',
          options: this.getPigpenBuild(),
          isChange:'change',
        },
        {
          label: '栏位列表',
          prop: 'fieldId',
          element: 'el-select',
          options: []
        },
        {
          label: '设备类型',
          prop: 'deviceType',
          element: 'el-select',
          options: this.getDeviceTypeList()
        },
        {
          label: '日志类型',
          prop: 'type',
          element: 'el-select',
          options: [
            {
              value:'',
              label:'全部'
            },
            {
              value:'离线',
              label:'离线'
            },
            {
              value:'故障',
              label:'故障'
            }
          ]
        },

      ],
      tableData: [],
      tableLabel: [
        { label: '栋舍', param: 'buildName' },
        { label: '栏位', param: 'fieldName' },
        { label: '设备号', param: 'snNumber' },
        { label: '设备类型', param: 'deviceType' },
        { label: '日志类型', param: 'type' },
        { label: '发生时间', param: 'faultDate' },
        // { label: '异常说明', param: 'strategyName' }
      ],

      pager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      },
      buildId:''
    }
  },
  mounted() {
    this.getExceptionrecordList()
  },
  watch:{
    buildId(val){
      this.getPigpenFiled(val)
    }
  },
  methods: {
    //获取栋舍id
    setSelect(val){
      this.buildId = val
    },
    //获取栏位列表
    getPigpenFiled(buildId){
      if(buildId !=''){
        let list =[];
        this.$http.get(this.$api.getPigpenFiled,{
          buildId:buildId
        }).then(res=>{
          if(res.statusCode == 200){
            res.data.map(item=>{
              list.push({'label':item.name,'value':item.id})
            })
          }
        })
        this.formOptions[2].options = list;
      }else{
        this.$message({
          type: 'warning',
          message: '请先选择栋舍'
        });
      }
    },
    //查询日志分页列表
    getExceptionrecordList(obj){
      let that = this;
      this.$http.get(this.$api.getExceptionrecordList,{
        ...obj,
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res=>{
        console.log(res)
        if(res.statusCode == 200){
          this.tableData = res.data.records
          this.pager.total = res.data.total
        }
      })
    },
    //获取栋舍列表
    getPigpenBuild(){
      let list =[];
      this.$http.get(this.$api.getPigpenBuild).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'label':item.name,'value':item.id})
          })
        }
      })
      return list
    },
    //获取设备列表
    getDeviceTypeList(){
      let list =[];
      this.$http.get(this.$api.getDeviceTypeList).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'label':item,'value':item})
          })
        }
      })
      return list
    },
    onSearch(val) {
      this.obj = val;
      console.log(val)
      this.getExceptionrecordList(val)
    },
     //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.getExceptionrecordList(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.getExceptionrecordList(this.obj)
    },
  },
}
</script>

<style lang="scss" scoped>
  .loglist{
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    height: calc(100vh - 132px);
    overflow-y: scroll;
  }
</style>
