<template>
  <div style="background-color: white;padding: 10px;border-radius: 8px;height: calc(100vh - 132px);overflow-y: hidden">
    <!--    <searchForm ref='search' :formOptions="formOptions"  @onSearch="onSearch" :btnItems='btnItems' @onExport='onExport' @setSelect = 'setSelect'/>-->

    <el-form ref="search" :model="form" label-width="85px" :inline="true">
      <el-form-item label="栋舍:" class="select" prop="buildingId">
        <el-select style="width: 110px;" v-model="form.buildingId" size="mini" clearable placeholder="请选择栋舍" @change="changeBuildType">
          <el-option :label="item.label" :value="item.value" :key="index.value" v-for="(item, index) in buildTypeList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="select" prop="buildId">
        <span slot="label">
          <el-tooltip class="item" effect="dark" placement="top" content="原栋舍"><i class="el-icon-info"></i></el-tooltip>
          单元/区:
        </span>
        <el-select style="width: 110px;" v-model="form.buildId" size="mini" clearable placeholder="请选择单元/区">
          <el-option :label="item.name" :value="item.id" :key="index" v-for="(item, index) in buildNameList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="check" label="设备名称:" prop="deviceName">
        <el-input style="width: 110px;" size="mini" v-model="form.deviceName"></el-input>
      </el-form-item>
      <el-form-item class="select" label="设备类型:" prop="passagewayTypes">
        <el-select style="width: 120px;" v-model="form.passagewayTypes" size="mini" :multiple="true" :collapse-tags="true" clearable placeholder="请选择设备类型">
          <el-option :label="item.label" :value="item.value" :key="index" v-for="(item, index) in formOptions[4].options"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="select" label="状态:" prop="online">
        <el-select style="width: 110px;" v-model="form.online" size="mini" clearable placeholder="请选择状态">
          <el-option label="在线" :value="1"></el-option>
          <el-option label="离线" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="onSearch" size="mini">搜索</el-button>
        <el-button type="default" @click="onReset" size="mini">重置</el-button>
        <el-button type="primary" @click="onExport" size="mini">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="title">
      共 <span style="color:black">{{ totalDevice }}</span> 条, 在线 <span style="color:black">{{ onlineDevice }}</span
      >, 离线 <span style="color:red">{{ offlineDevice }}</span>
    </div>
    <page-table
      v-if="hardReset"
      v-loading="loading"
      :tableData="tableData"
      :tableLabel="tableLabel"
      :tableOption="tableOption"
      class="gdtable"
      :pager="pager"
      :toTop="toTop"
      :tableId="'evnDeviceList'"
      @pagination="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleButton="tableBtnHandle(arguments)"
      @changeTop="changeTop"
      @cancelTop="cancelTop"
    ></page-table>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
import moment from 'moment'
import { exportExcel, tranBuildType } from '../../libs/tools'
import store from '../../store'
import axios from 'axios'
import { getDownload } from '../../libs/axios'
export default {
  name: 'evnDeviceList',
  components: {
    pageTable,
    searchForm
  },
  computed: {
    deviceName() {
      return this.$route.query.deviceName
    }
  },

  data() {
    return {
      loading: true,
      buildType: '',
      hardReset: true,
      visibleForm: true,
      totalDevice: 0,
      onlineDevice: 0,
      offlineDevice: 0,
      btnItems: 'search' + 'export' + 'reset',
      obj: {
        buildType: undefined,
        buildId: undefined,
        buildingId: undefined,
        deviceName: undefined
      },
      day: 1,
      count: 0,
      buildTypeList: [],
      buildNameList: [],
      form: {
        buildingId: '',
        buildId: '',
        deviceName: '',
        online: '',
        passagewayTypes: ''
      },
      formOptions: [
        {
          label: '栋舍',
          prop: 'buildingId',
          element: 'el-select',
          isChange: 'change',
          options: this.getBuildType()
        },
        {
          label: '单元/区',
          prop: 'buildId',
          element: 'el-select',
          options: []
        },
        {
          label: '设备名称',
          prop: 'deviceName',
          element: 'el-input',
          initValue: '',
          placeholder: '请输入设备名称'
        },
        {
          label: '状态',
          prop: 'online',
          element: 'el-select',
          options: [
            { label: '在线', value: '1' },
            { label: '离线', value: '0' }
          ]
        },
        {
          label: '通道类型',
          prop: 'passagewayTypes',
          element: 'el-select',
          multiple: 1,
          options: [
            { label: '室内温', value: '室内温' },
            { label: '室外温', value: '室外温' },
            { label: '湿度', value: '湿度' },
            { label: '氨气浓度', value: '氨气浓度' },
            { label: '水帘', value: '水帘' },
            { label: '定速风机', value: '定速风机' }
          ]
        }
      ],
      tableData: [],
      tableLabel: [
        {
          label: '栋舍',
          param: 'buildingName'
        },
        {
          label: '单元/区',
          param: 'buildName'
        },
        {
          label: '单元类型',
          param: 'buildType'
        },
        {
          label: '设备名称',
          param: 'deviceName'
        },
        {
          label: '通道名称',
          param: 'name'
        },
        {
          label: '类型',
          param: 'type'
        },
        {
          label: '值',
          param: 'value'
        },
        {
          label: '状态',
          param: 'online',
          isOnline: true
        }
      ],
      toTop: {
        label: '置顶',
        options: [{ label: '详情', methods: 'toTop' }]
      },
      tableOption: {
        label: '操作',
        options: [{ label: '详情', methods: 'editDetail' }]
      },
      pager: {
        pageSize: 10, // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0 //列表总数
      }
    }
  },
  watch: {
    buildType(val) {
      this.getAllPigpen(val)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.deviceName) {
        let that = this
        this.form.deviceName = this.deviceName
      }
      console.log(this.form)
      this.getEvnPage(this.form)
      this.getLogCount(this.form)
      this.getAllPigpen()
    },
    setSelect(val) {
      this.buildType = val
    },
    changeBuildType() {
      this.form.buildName = undefined
      this.getAllPigpen()
    },
    //环控
    getEvnPage(obj) {
      let that = this

      this.$http
        .get(this.$api.evnPage, {
          ...obj,
          current: that.pager.currentPage,
          size: that.pager.pageSize
        })
        .then(res => {
          if (res.data) {
            let arr = res.data.records
            arr.map(item => {
              item.buildType = tranBuildType(item.buildType)
              if (item.online) {
                item.online = '在线'
                item.onlineTime = item.finalPostDate
              } else {
                item.online = '离线'
                item.offlineTime = item.finalPostDate
              }
              if (item.value != null) {
                if (item.type == '水量计') {
                  item.value = item.value + ' 吨'
                } else if (item.type == '电表') {
                  item.value = item.value + ' 度'
                } else if (item.type == '氨气浓度') {
                  item.value = item.value + ' ppm'
                } else if (item.type == '湿度') {
                  item.value = item.value + '%RH'
                } else if (item.type == '室外温') {
                  item.value = item.value + ' ℃'
                } else if (item.type == '室内温') {
                  item.value = item.value + ' ℃'
                } else if (item.type == '重量设备') {
                  item.value = item.value + ' 吨'
                }
              } else {
                item.value = '--'
              }
              if (item.value == 1 && (item.type == '定速风机' || item.type == '水帘')) {
                item.value = '开启'
              }
              if (item.value == 0 && (item.type == '定速风机' || item.type == '水帘')) {
                item.value = '关闭'
              }
            })
            // this.$set
            this.tableData = arr
            this.pager.total = res.data.total
            this.loading = false
            this.hardReset = true
          } else {
            this.loading = false
            this.hardReset = true
          }
        })
    },
    //环控统计
    getLogCount(obj) {
      let that = this
      this.$http
        .get(this.$api.statusCount, {
          ...obj,
          deviceType: '环控'
        })
        .then(res => {
          if (res.data) {
            that.totalDevice = res.data.total
            that.onlineDevice = res.data.online
            that.offlineDevice = res.data.offline
            // that.count = res.data.amount
          }
        })
    },
    //单元类型列表
    getBuildType() {
      let arr = []
      this.$http
        .get(this.$api.getBuildList, {
          deviceType: '采集盒'
        })
        .then(res => {
          if (res.data) {
            let list = Array.from(new Set(res.data))
            list.map(item => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
            this.buildTypeList = arr
          }
        })
      return arr
    },
    loadTable(val) {
      this.hardReset = false
      this.loading = true
      this.getEvnPage(val)
      this.getLogCount(val)
    },
    tableBtnHandle(arg) {
      // console.log(arg[0])
      let passagewayId = arg[1].id
      this.$router.push({
        path: 'seo',
        query: {
          passagewayId: passagewayId,
          buildType: arg[1].buildType,
          buildName: arg[1].buildName,
          deviceName: arg[1].deviceName,
          type: arg[1].type
        },
        params: {}
      })
      localStorage.setItem('routename', 'seo')
      localStorage.setItem('routetarget', 'seo')
      this.$store.commit('selectMenu', {
        router: 'setting/seo',
        name: '综合查询',
        target: 'seo'
      })
    },
    changeTop(val) {
      this.$http.patch(this.$api.changeTop(val), {}).then(res => {})
    },
    cancelTop(val) {
      this.$http.patch(this.$api.cancelTop(val), {}).then(res => {})
    },
    //翻页
    handleCurrentChange(page) {
      this.pager.currentPage = page

      let val = this.form
      let obj = {
        deviceTypes: '',
        buildType: '',
        buildName: '',
        deviceName: '',
        online: undefined
      }
      console.log(this.obj)
      if (val.buildType) {
        obj.buildType = val.buildType
      }
      if (val.buildName) {
        obj.buildName = val.buildName
      }
      if (val.deviceName) {
        obj.deviceName = val.deviceName
      }
      if (val.online != undefined) {
        obj.online = val.online
      }

      if (val.passagewayTypes) {
        let arr = val.passagewayTypes
        obj.deviceTypes = arr.join(',')
        this.loadTable(obj)
      } else {
        this.loadTable(obj)
      }
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.form)
    },
    onReset() {
      this.$refs.search.resetFields()
      this.loadTable(this.form)
    },
    onSearch() {
      this.$set(this.pager, 'currentPage', 1)
      this.hardReset = false
      let val = this.form
      let obj = {
        deviceTypes: '',
        buildingId: '',
        buildId: '',
        deviceName: '',
        online: undefined
      }
      if (val.buildingId) {
        obj.buildingId = val.buildingId
      }
      if (val.buildId) {
        obj.buildId = val.buildId
      }
      if (val.deviceName) {
        obj.deviceName = val.deviceName
      }
      if (val.online != undefined) {
        obj.online = val.online
      }

      if (val.passagewayTypes) {
        let arr = val.passagewayTypes
        obj.deviceTypes = arr.join(',')
        this.loadTable(obj)
      } else {
        this.loadTable(obj)
      }
    },
    //栋舍列表
    getAllPigpen() {
      let arr = []
      let that = this
      this.formOptions[1].options = []
      this.$http
        .get(this.$api.getAllPigpen, {
          deviceType: '采集盒',
          buildingId: that.form.buildingId
        })
        .then(res => {
          if (res.data) {
            res.data.map(item => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
            this.buildNameList = res.data
            // this.formOptions[1].options  = arr
          }
        })
    },
    onExport() {
      this.loadTable(this.form)
      this.exportEvnList(this.form)
    },
    //列表导出
    exportEvnList() {
      // let obj = this.form
      // let urls = store.state.baseUrl+this.$api.exportEvnList +'?deviceType=采集盒&current=-1&size=-1&farmId=' + store.state.farmId
      // let buildType = ""
      // let buildName = ""
      // let online = ""
      // let deviceName = ""
      // let deviceTypes = ""
      // if (obj.buildType){
      //   buildType = obj.buildType
      //   urls = urls + '&buildType=' + buildType
      // }
      // if (obj.buildName){
      //   buildName = obj.buildName
      //   urls = urls + '&buildName=' + buildName
      // }
      // if (obj.deviceName){
      //   deviceName = obj.deviceName
      //   urls = urls + '&deviceName=' + deviceName
      // }
      // if (obj.online){
      //   online = obj.online
      //   urls = urls + '&online=' + online
      // }
      // if (obj.passagewayTypes){
      //   deviceTypes = obj.passagewayTypes.join(',')
      //   deviceTypes = urls + '&deviceTypes=' + deviceTypes
      // }
      // window.location.href = urls

      let obj = this.form
      axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token')
      let headers = {
        'x-auth-token': localStorage.getItem('token')
      }
      getDownload(
        this.$api.exportEvnList,
        {
          farmId: store.state.farmId,
          ...obj
        },
        headers
      ).then(res => {
        let blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        // downloadElement.download = decodeURIComponent(res.headers.filename); //下载后文件名
        downloadElement.download = `环控设备列表.xlsx` //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放blob对象
      })
      this.$refs.search.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
  height: calc(100vh - 327px) !important;
  overflow-y: auto !important;
}
.title {
  margin-bottom: 10px;
  color: #606266;
}
::v-deep .el-range-separator {
  padding: 0 5px;
  line-height: 22px;
  width: 7%;
  color: #303133;
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
::v-deep .el-select {
  width: 150px;
}
</style>
