<template>
  <div style='background-color: white;padding: 10px;border-radius: 8px;height: calc(100vh - 132px);overflow-y: hidden'>
    <searchForm :formOptions="formOptions"  @onSearch="onSearch" :btnItems='btnItems' @onExport='onExport' @setSelect = 'setSelect'/>
    <div class='title'>{{ startTime }} 至 {{ endTime }} 共{{day}}天 合计  <span style='color:black'>{{ pager.total }}</span> 条 </div>
    <page-table
      v-loading="loading"
      :tableData="tableData"
      :tableLabel="tableLabel"
      :tableOption="tableOption"
      class="gdtable"
      :pager="pager"
      :tableId='"eleLog"'
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
      @handleButton='tableBtnHandle(arguments)'
    ></page-table>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from "@/components/searchForm";
import moment from 'moment'
import { exportExcel, tranBuildType } from '../../libs/tools'
import store from '../../store'
import axios from "axios";
import {getDownload} from "../../libs/axios";

export default {
  name:'electricWarnLog',
  components:{
    pageTable,
    searchForm
  },
  data(){
    return{
      selectBuildId:'',
      buildType: '',
      loading:true,
      btnItems: 'search'+'export'+'reset',
      startTime: moment().format('yyyy/MM/DD'),
      endTime: moment().format('yyyy/MM/DD'),
      obj: {
        buildType:undefined,
        buildingId:undefined,
        buildId:undefined,
        deviceName:undefined,
        startTime: moment().format('yyyy-MM-DD') + ' 00:00:00',
        endTime: moment().format('yyyy-MM-DD') + ' 23:59:59'
      },
      day: 1,
      formOptions: [
        {
          label: '栋舍',
          prop: 'buildingId',
          element: 'el-select',
          options: this.getBuildType(),
          isChange:'change'
        }, {
          label: '单元/区',
          prop: 'buildId',
          element: 'el-select',
          showTooltip:true,
          options: []
        },
        {
          label: '设备名称',
          prop: 'deviceName',
          element: 'el-input',
          placeholder:'请输入设备名称'
        },
        {
          label: '时间',
          prop: 'date',
          initValue: [new Date(),new Date()],
          type: 'daterange',
          element: 'el-date-picker'
        }
      ],
      tableData: [],
      tableLabel: [
        { label: '栋舍名称', param: 'buildingName' },
        { label: '单元/区名称', param: 'buildName' },
        {
          label: '单元类型',
          param: 'buildType'
        },{
          label: '开始时间',
          param: 'startTime'
        },{
          label: '结束时间',
          param: 'endTime'
        },{
          label: '持续时间(分钟)',
          param: 'continued'
        }, {
          label: '设备名称',
          param: 'deviceName'
        }
      ],
      tableOption: {

      },
      pager:{
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total:0,  //列表总数
      }
    }
  },
  mounted() {
    this.init()
  },
  watch:{
    buildType(val){
      this.getAllPigpen(val)
    }
  },
  methods:{
    setSelect(val){
      this.selectBuildId = val
      this.buildType = val
    },
    init(){
      this.getElectricWarnLog(this.obj)
      this.getAllPigpen()
    },
    //断电报警历史记录
    getElectricWarnLog(obj) {
      let that = this
      this.$http.get(this.$api.electricWarnLog, {
        ...obj,
        deviceType:'断电报警',
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res => {
        if (res.data){
          let arr = res.data.records
          arr.map(item => {
            item.buildType = tranBuildType(item.buildType)
          })
          this.tableData = arr
          this.pager.total = res.data.total
          this.loading = false
        }else {
          this.loading = false
        }
      })
    },
    //单元类型列表
    getBuildType() {
      let arr = []
      this.$http.get(this.$api.getBuildList, {
        deviceType:'断电报警',
      }).then(res => {
        if (res.data){
          res.data.map(item=>{
            arr.push({
              label: item.name,
              value: item.id
            })
          })
        }
      })
      return arr
    },
    loadTable(val){
      this.loading = true
      this.getElectricWarnLog(val)
    },
    tableBtnHandle(arg){
      console.log(arg[0])
      console.log(arg[1])
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },
    onSearch (val) {
      if (val==null){
        return
      }
      if(val.date!=null){
        this.day =  moment(val.date[1]).diff(moment(val.date[0]),'d') + 1
        this.obj.startTime = moment(val.date[0]).format('yyyy-MM-DD') + ' 00:00:00'
        this.startTime = moment(val.date[0]).format('yyyy/MM/DD')
        this.obj.endTime = moment(val.date[1]).format('yyyy-MM-DD') + ' 23:59:59'
        this.endTime = moment(val.date[1]).format('yyyy/MM/DD')
      }else {
        this.$message.warning("请选择日期")
        return
      }
      this.$set(this.pager,'currentPage',1)
      this.obj.buildId = val.buildId
      this.obj.buildingId = val.buildingId
      this.obj.buildType = val.buildType
      this.obj.deviceName = val.deviceName
      this.loadTable(this.obj)
    },
    //栋舍列表
    getAllPigpen(val) {
      let arr = []
      this.formOptions[1].options = []
      this.$http.get(this.$api.getAllPigpen, {
        deviceType:'断电报警',
        buildingId:this.selectBuildId
      }).then(res => {
        if (res.data){
          res.data.map(item => {
            arr.push({
              label: item.name,
              value: item.id
            })
          })
          this.formOptions[1].options = arr
        }
      })
      return arr
    },
    onExport(val){
      if(val.date!=null){
        this.day =  moment(val.date[1]).diff(moment(val.date[0]),'d') + 1
        this.obj.startTime = moment(val.date[0]).format('yyyy-MM-DD') + ' 00:00:00'
        this.startTime = moment(val.date[0]).format('yyyy/MM/DD')
        this.obj.endTime = moment(val.date[1]).format('yyyy-MM-DD') + ' 23:59:59'
        this.endTime = moment(val.date[1]).format('yyyy/MM/DD')
      }else {
        this.$message.warning("请选择日期")
        return
      }
      this.obj.buildId = val.buildId
      this.obj.buildingId = val.buildingId
      this.obj.buildType = val.buildType
      this.obj.deviceName = val.deviceName
      this.loadTable(this.obj)
      this.exportHisWaterList(this.obj)
    },
    //列表导出
    exportHisWaterList(obj) {
      // let urls = store.state.baseUrl+this.$api.exportHisEleWarnList +'?deviceType=断电报警&current=-1&size=-1&farmId=' + store.state.farmId
      // let buildType = ""
      // let buildName = ""
      // let startTime = ""
      // let endTime = ""
      // let deviceName = ""
      // if (obj.buildType){
      //   buildType = obj.buildType
      //   urls = urls + '&buildType=' + buildType
      // }
      // if (obj.buildName){
      //   buildName = obj.buildName
      //   urls = urls + '&buildName=' + buildName
      // }
      // if (obj.startTime){
      //   startTime = obj.startTime
      //   urls = urls + '&startTime=' + startTime
      // }
      // if (obj.endTime){
      //   endTime = obj.endTime
      //   urls = urls + '&endTime=' + endTime
      // }
      // if (obj.deviceName){
      //   deviceName = obj.deviceName
      //   urls = urls + '&deviceName=' + deviceName
      // }
      // window.location.href = urls
      axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
      let headers = {
        'x-auth-token': localStorage.getItem('token')
      }
      getDownload(this.$api.exportHisEleWarnList,{
        farmId:store.state.farmId,
        ...obj
      },headers).then((res) => {
        let blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        // downloadElement.download = decodeURIComponent(res.headers.filename); //下载后文件名
        downloadElement.download = `断电报警历史记录.xlsx`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放blob对象
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 327px) !important;
    overflow-y: auto !important;
}
.title {
  margin-bottom: 10px;
  color: #606266;

}
</style>
