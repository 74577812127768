<template>
  <div class="strategy">
    <searchForm :formOptions='formOptions' @onSearch='onSearch' :btnItems='btnItems' @setSelect2 = 'setSelect2' @setSelect = 'setSelect' />
    <!-- <div class='title'>共 {{ totalDevice }} 条 </span></div> -->
    <page-table
      :tableData='tableData'
      :tableLabel='tableLabel'
      :tableOption='tableOption'
      class="gdtable"
      :pager='pager'
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
      @handleButton='tableBtnHandle(arguments)'
    ></page-table>
    <el-dialog
      title="通道设置"
      :visible.sync="centerDialogVisible"
      width="70%"
      center>
      <el-form :inline="true" :model="formItem" ref="formItem" :label-position="labelPosition" label-width="120px" class="demo-form-inline">
          <el-form-item label="设备类型" prop="deviceType">
            <el-input v-model="formItem.deviceType" placeholder="设备类型" disabled></el-input>
          </el-form-item>
          <el-form-item label="序列号" prop="snNumber">
            <el-input v-model="formItem.snNumber" placeholder="序列号" disabled></el-input>
          </el-form-item>
          <el-form-item label="设备名称" prop="deviceName">
            <el-input v-model="formItem.deviceName" placeholder="设备名称" disabled></el-input>
          </el-form-item>
          <el-form-item label="绑定栋舍" prop="buildingName">
            <el-input v-model="formItem.buildingName" placeholder="绑定栋舍" disabled></el-input>
          </el-form-item>
          <el-form-item label="绑定单元/区" prop="buildName">
            <el-input v-model="formItem.buildName" placeholder="绑定单元/区" disabled></el-input>
          </el-form-item>
          <el-form-item label="绑定策略" prop="strategyName">
            <el-input v-model="formItem.strategyName" placeholder="绑定策略" disabled></el-input>
          </el-form-item>
        </el-form>
      <TableEdit
        v-if='centerDialogVisible'
        :datas="sxdataTable"
        @update:FathersaveRow="FathersaveRow"
        @update:FatherdeleteRow="FatherdeleteRow"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStrategy">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
import TableEdit from '@/components/TableEdit'

export default {
  name: 'deviceTongdao',
  components: {
    pageTable,
    searchForm,
    TableEdit
  },
  data() {
    return {
      selectBuildId:'',
      labelPosition:'right',
      editId:'',
      chooseName:'',
      sxdataTable: {
        tableTitle: [
          {prop:'name',label:'通道名称',type:'input'},
          {prop:'code',label:'通道编号',type:'input'},
          {prop:'type',label:'通道类型',type:'select',options:this.getPassageway()}
        ],
        tableData:[]
      },
      sxdataTable2: {
        tableTitle: [
          {prop:'name',label:'通道名称',type:'input'},
          {prop:'code',label:'通道编号',type:'input'},
          {prop:'type',label:'通道类型',type:'select',options:this.getPassageway()},
          {prop:'range',label:'量程',type:'input'},
        ],
        tableData:[]
      },
      centerDialogVisible: false,
      totalDevice:0,
      obj:undefined,
      btnItems: 'search' + 'reset',
      formOptions: [

        {
          label: '序列号|名称',
          prop: 'snOrName',
          element: 'el-input',
          placeholder: '序列号|名称'
        },
        {
          label: '设备类型',
          prop: 'deviceType',
          element: 'el-select',
          options: this.getDeviceTypeList()
        },
        {
            label: '栋舍',
            prop: 'buildingId',
            element: 'el-select',
            isChange: 'change',
            options: this.getBuildType()
          }, {
            label: '单元/区',
            prop: 'buildId',
            element: 'el-select',
            initValue:'',
            isChange: 'change2',
            showTooltip:true,
            options: []
          },

      ],
      tableData: [],
      tableLabel: [
        { label: '序列号', param: 'snNumber' },
        { label: '名称', param: 'deviceName' },
        { label: '类型', param: 'deviceType' },
        { label: '绑定栋舍', param: 'buildingName' },
        { label: '绑定单元/区', param: 'buildName' },
        { label: '策略', param: 'strategyName' }
      ],
      tableOption: {
        label: '操作',
        options: [
          { label: '通道设置', methods: 'editDetail' }
        ]
      },
      pager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      },
      formItem:{
        deviceId:'',//设备id
        snNumber:'',//序列号
        deviceName:'',//设备名称
        deviceType:'',//设备类型
        buildingName:'',//已绑栋舍
        buildName:'',//已绑单元/区
        strategyName:'',//策略
      },
      tdlist:[]
    }
  },
  watch:{
    selectBuildId(val){
      this.getAllPigpen(val)
    }
  },
  mounted() {
    this.getDevicePage();
    this.getAllPigpen();
    //获取通道列表
    //this.getPassageway();
  },
  methods: {

    //获取栋舍id
    setSelect(val){

      console.log("=======================")
      this.selectBuildId = val;
      this.formOptions[3].initValue=''
    },
    setSelect2(){

    },
    getAllPigpen(val){
      let arr = []
      let buildId='';
      console.log("=======================")
      if(val){
        buildId = val;
      }else{
        buildId = this.selectBuildId;
      }
      this.formOptions[3].options  = []
      this.$http.get(this.$api.getAllPigpen, {
        buildingId:buildId
      }).then(res => {
        if (res.data){
          res.data.map(item => {
            arr.push({
              label: item.name,
              value: item.id
            })
          })
          this.formOptions[3].options  = arr
        }
      })
    },
    //栋舍列表
    getBuildType() {
      let arr = []
      this.$http.get(this.$api.getBuildList, {
      }).then(res => {
        if (res.data){
          res.data.map(item=>{
            arr.push({
              label: item.name,
              value: item.id
            })
          })
        }
      })
      return arr
    },
    //通道列表
    getPassageway(){
      let list =[]
      this.$http.get(this.$api.getPassageway).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'value':item,'label':item})
          })
        }
      })
      return list;
    },

    //获取单元列表
    getPigpenBuild(val){
      let list =[];
      this.$http.get(this.$api.getAllPigpen,{
        buildingId:val
      }).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'label':item.name,'value':item.id})
          })
        }
      })
      return list
    },
    //获取设备列表
    getDeviceTypeList(){
      let list =[];
      this.$http.get(this.$api.getDeviceTypeList).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'label':item,'value':item})
          })
        }
      })
      return list
    },
    saveStrategy(){
      this.centerDialogVisible = false
    },
    FatherdeleteRow(val){
      if (val.id){
        this.$http.delete(this.$api.delPassageway(val.id)).then(res=>{
          if(res.statusCode == 200 ){
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.getPassagewaylistByDeviceId(this.formItem.deviceId)
          }
        }).catch(err=>{
          this.$message({
            type: 'error',
            message: err.message
          });
          this.getPassagewaylistByDeviceId(this.formItem.deviceId)
        })
      }
    },
    FathersaveRow(val){
      //新增编辑保存通道设置
      if(val.id){
        console.log(111)
        if (val.code&&val.name&&val.type){
          let param = {
            id:val.id,
            code:val.code,
            name:val.name,
            type:val.type
          }
          if(this.formItem.deviceType === '料塔'){
            param.range = val.range * 1000
          }
          this.$http.patch(this.$api.updatePassageway,param).then(res=>{
            if(res.statusCode == 200){
              this.$message({
                type: 'success',
                message: '修改成功'
              });
              this.getPassagewaylistByDeviceId(this.formItem.deviceId)
            }else {
              this.$message.error(res.message)
            }
          }).catch(err => {
            this.$message.error(err.message)
          })
        }else {
          this.$message({
            type: 'error',
            message: '保存失败，请输入有效数据'
          });
        }
      }else{
        if (val.name&&val.name&&val.type){
          let param = {
            code:val.code,
            name:val.name,
            type:val.type
          }
          if(this.formItem.deviceType === '料塔'){
            param.range = val.range * 1000
          }
          this.$http.post(this.$api.addPassageway,{
            deviceId:this.formItem.deviceId,
            passInfos:[param]
          }).then(res=>{
            if(res.statusCode == 200){
              this.$message({
                type: 'success',
                message: '新增成功'
              });
              this.getPassagewaylistByDeviceId(this.formItem.deviceId)
            }
          }).catch(err => {
            this.$message.error(err.message)
          })
        }else {
          this.$message({
            type: 'error',
            message: '保存失败，请输入有效数据'
          });
        }

      }
      //保存通道
      //console.log(this.sxdataTable.tableData)
    },
    //设备列表
    getDevicePage(obj) {
      let that = this
      this.$http.get(this.$api.getDevicePage, {
        ...obj,
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res => {
        this.tableData = res.data.records
        this.pager.total = res.data.total
      })
    },

    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.getDevicePage(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.getDevicePage(this.obj)
    },
    getPassagewaylistByDeviceId(deviceId){
      this.$http.get(this.$api.getPassagewaylistByDeviceId,{
        deviceId:deviceId
      }).then(res=>{
           res.data.map(item=>{
            item.isSet = false;
            item.range = item.range / 1000;
          })
          this.sxdataTable.tableData = res.data;
          this.sxdataTable2.tableData = res.data;

      })
    },
    tableBtnHandle(arg) {
      if(arg[0]=='editDetail'){
        //编辑
        if (arg[1].deviceType === '料塔'){
          this.sxdataTable.tableTitle = [
            {prop:'name',label:'通道名称',type:'input'},
            {prop:'code',label:'通道编号',type:'input'},
            {prop:'type',label:'通道类型',type:'select',options:this.getPassageway()},
            {prop:'range',label:'量程(t)',type:'input'},
          ]
        }else {
          this.sxdataTable.tableTitle = [
            {prop:'name',label:'通道名称',type:'input'},
            {prop:'code',label:'通道编号',type:'input'},
            {prop:'type',label:'通道类型',type:'select',options:this.getPassageway()},
          ]
        }
        this.$set(this.formItem,'deviceId',arg[1].deviceId);
        this.$set(this.formItem,'snNumber',arg[1].snNumber);
        this.$set(this.formItem,'deviceName',arg[1].deviceName);
        this.$set(this.formItem,'deviceType',arg[1].deviceType);
        this.$set(this.formItem,'buildingName',arg[1].buildingName);
        this.$set(this.formItem,'buildName',arg[1].buildName);
        this.$set(this.formItem,'strategyName',arg[1].strategyName);

        // this.formItem.deviceId = arg[1].deviceId;
        // this.formItem.snNumber = arg[1].snNumber;
        // this.formItem.deviceName = arg[1].deviceName;
        // this.formItem.deviceType = arg[1].deviceType;
        // this.formItem.buildingName = arg[1].buildingName;
        // this.formItem.buildName = arg[1].buildName;
        // this.formItem.strategyName = arg[1].strategyName;
        this.getPassagewaylistByDeviceId(arg[1].deviceId);
        this.centerDialogVisible = true;
      }
    },
    onSearch(val) {
      this.obj = val;
      this.getDevicePage(val)
      //this.loadTable(val)
    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 296px) !important;
    overflow-y: auto !important;
}
.title{
  margin-bottom: 10px;
}
.strategy{
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  height: calc(100vh - 132px);
  overflow-y: hidden;
  .addbtnbox{
    margin-bottom:10px;
  }
  .grid-content{
    margin-bottom:10px;
    .el-input{
      width:200px;
    }
  }
}
</style>
