<template>
  <div id='app'>
    <el-row>
      <el-col :span='24'>
        <el-table size='mini' :data='tableData' border style='width: 100%' highlight-current-row>
          <!-- <el-table-column type="index"></el-table-column> -->
          <el-table-column
            v-for='(item,index) in tableTitle'
            :key='index'
            :label='item.label'

            :prop='item.prop'>
            <template #default='scope'>
             <span v-if='scope.row.isSet'>
               <input v-if="item.type =='input'" class='iteminput' placeholder='请输入' :value='sel[item.prop]'
                      @change='inputChange($event,item.prop)'>
               <input v-if="item.type =='minInput'" class='iteminput' :disabled='minDisabled' placeholder='请输入'
                      :value='sel[item.prop]'
                      @change='inputChange($event,item.prop)'>
               <input v-if="item.type =='maxInput'" class='iteminput' :disabled='maxDisabled' placeholder='请输入'
                      :value='sel[item.prop]'
                      @change='inputChange($event,item.prop)'>
               <span style='display: inline-block;width: 20%;text-align: center;font-size: 16px'
                     v-if="item.type =='minInput'||item.type =='maxInput'">{{ dw }}</span>
               <el-select v-if="item.type =='select'&&item.change=='isChange'" v-model='sel[item.prop]'
                          placeholder='请选择' @change='changeVal'>
                <el-option
                  v-for='it in item.options'
                  :key='it.value'
                  :label='it.label'
                  :value='it.value'>
                </el-option>
              </el-select>
               <el-select v-if="item.type =='select'&&item.change!='isChange'" v-model='sel[item.prop]'
                          placeholder='请选择'>
                <el-option
                  v-for='it in item.options'
                  :key='it.value'
                  :label='it.label'
                  :value='it.value'>
                </el-option>
              </el-select>
             </span>
              <span v-else>
                <span>{{ scope.row[item.prop] }}</span>
                <!-- <span v-if="item.type =='input'">{{scope.row[item.prop]}}</span>
                <span v-if="item.type =='select'" >{{scope.row[item.prop].split('/')[1]}}</span> -->
              </span>
            </template>
          </el-table-column>
          <el-table-column label='操作' align='center' width='200'>
            <template #default='scope'>
             <span class='el-tag el-tag--primary  el-tag--mini' style='cursor: pointer; margin-right:10px;'
                   @click.stop='saveRow(scope.row,scope.$index)'>
               确定
             </span>
              <span class='el-tag el-tag--primary el-tag--mini' style='cursor: pointer; margin-right:10px;'
                    @click='editRow(scope.row,scope.$index)'>
               编辑
             </span>
              <span class='el-tag el-tag--danger el-tag--mini' style='cursor: pointer; margin-right:10px;'
                    @click='deleteRow(scope.$index,tableData,scope.row)'>
               删除
             </span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span='24'>
        <div class='el-table-add-row' style='width: 99.2%;' @click='addRow()'><span>+ 添加</span></div>
      </el-col>
      <el-col :span='24' style='border-top: 1px solid #EBEEF5;margin-top: 10px'>
        <el-table
          :data="tableDataBot"
          style="width: 100%"
        >
          <el-table-column
            prop="valueType"
            label="类型"
           >
          </el-table-column>
          <el-table-column
            prop="desc"
            label="规则"
            >
          </el-table-column>
          <el-table-column
            prop="isUse"
            label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isUse"
              >
<!--              {{scope.row.isUse}}-->
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- <span>{{tableData}}</span> -->
  </div>
</template>

<script>

export default ({
  props: {
    datas: Object,
    tableDataX: Array,

  },
  data() {
    return {
      dw: '',
      minDisabled: false,
      maxDisabled: false,
      tableTitle: [],
      tableData: [],
      sel: {}, //选中行
    }
  },
  computed:{
    tableDataBot(){
      return this.tableDataX
    }
  },
  watch: {
    //普通的watch监听
    datas: {
      immediate: true,
      deep: true,
      handler: function(newName) {
        // console.log(newName.tableData)
        this.tableData = newName.tableData
      }
    },
    tableDataBot:{
      immediate: true,
      deep: true,
      handler: function(newName) {
        this.$emit('update:tableDataX',newName)
      }
    },
    tableDataX:{
      immediate: true,
      deep: true,
      handler: function(newName) {
        this.tableDataX = newName
      }
    }
  },
  mounted() {
    if (this.datas != null) {
      this.tableTitle = this.datas.tableTitle
      this.tableData = this.datas.tableData
    }
  },
  methods: {
    changeVal(val) {
      if (val == '湿度') {
        this.minDisabled = false
        this.maxDisabled = false
        this.dw = '%RH'
      } else if (val == '温度') {
        this.minDisabled = false
        this.maxDisabled = false
        this.dw = '℃'
      } else if (val == '氨气浓度') {
        this.maxDisabled = false
        this.minDisabled = false

        this.dw = 'ppm'
      } else if (val == '水') {
        this.maxDisabled = false
        this.minDisabled = false
        this.dw = '吨'
      } else if (val == '电') {
        this.maxDisabled = false
        this.minDisabled = false
        this.dw = '度'
      } else if (val == '料塔余量') {
        this.minDisabled = false
        this.maxDisabled = false
        this.dw = '吨'
      } else if (val == '日耗料') {
        this.minDisabled = false
        this.maxDisabled = false
        this.dw = 'kg'
      }
    },
    addRow() {
      for (const i of this.tableData) {
        if (i.isSet) {
          return
        }
      }
      let data = {
        key: {
          isSet: true
        }
      }
      // console.log(this.tableTitle)
      this.tableTitle.forEach((item) => {
        data.key[item.prop] = ''
      })
      this.tableData.push(JSON.parse(JSON.stringify(data.key)))
      // console.log(this.sel)
      // console.log(this.tableData)
      this.sel = JSON.parse(JSON.stringify(data.key))
    },
    inputChange1(e, prop) {
      this.sel[prop] = e.target.value
      // console.log(this.sel)
    },
    inputChange(e, prop) {
      this.sel[prop] = e.target.value
      // console.log(this.sel)
    },
    saveRow(row, index) {

      let bool = true
      if (row.isSet) {
        const datas = JSON.parse(JSON.stringify(this.sel))
        for (const k in datas) {
          row[k] = datas[k] //将sel里面的value赋值给这一行。ps(for....in..)的妙用，细心的同学发现这里我并没有循环对象row
        }
        if (row.valueType != null) {
          if ((row.minValue != null && row.minValue != '') || (row.maxValue2 != null && row.maxValue2 != '')) {
            bool = false
          } else {
            this.$message.warning('最小值,最大值必填一个')
          }
        }

        // if (row.valueType=='温度'||row.valueType=='湿度'){
        //   if (row.minvalue!=null&&row.minvalue!=''&&row.maxvalue2!=null&&row.maxvalue2!=''){
        //     bool = false
        //   }else {
        //     this.$message.warning("最小值和最大值必填")
        //   }
        // }
        // if (row.valueType=='氨气浓度'||row.valueType=='水'||row.valueType=='电'){
        //   if (row.maxvalue2){
        //     bool = false
        //   }else {
        //     this.$message.warning("最大值必填")
        //   }
        // }
        // if (row.valueType=='料塔'){
        //   if (row.minvalue!=null&&row.minvalue!=''){
        //     bool = false
        //   }else {
        //     this.$message.warning("最小值必填")
        //   }
        // }
        // if (row.valueType=='断电报警'){
        //   if (row.alarmType){
        //     bool = false
        //   }else {
        //     this.$message.warning("报警方式必填")
        //   }
        // }
        row.isSet = bool
        this.$emit('update:FathersaveRow', row)
      }
    },
    editRow(row) {
      if (row.valueType == '湿度') {
        this.minDisabled = false
        this.maxDisabled = false
        this.dw = '%RH'
      } else if (row.valueType == '温度') {
        this.minDisabled = false
        this.maxDisabled = false
        this.dw = '℃'
      } else if (row.valueType == '氨气浓度') {
        this.maxDisabled = false
        this.minDisabled = false
        this.dw = 'ppm'
      } else if (row.valueType == '水') {
        this.maxDisabled = false
        this.minDisabled = false
        this.dw = '吨'
      } else if (row.valueType == '电') {
        this.maxDisabled = false
        this.minDisabled = false
        this.dw = '度'
      } else if (row.valueType == '日耗料') {
        this.minDisabled = false
        this.maxDisabled = false
        this.dw = 'kg'
      } else if (row.valueType == '料塔余量') {
        this.minDisabled = false
        this.maxDisabled = false
        this.dw = '吨'
      }

      for (const i of this.tableData) {
        if (i.isSet) {
          this.$message({
            type: 'warning',
            message: '请保存当前编辑项'
          })
          return
        }

      }

      row.isSet = true
      this.sel = row
    },
    deleteRow(index, rows, row) {
      // console.log(row)
      this.$emit('update:FatherdeleteRow', row)
      rows.splice(index, 1)
    }
  }
})
</script>
<style lang='scss'>
.iteminput {
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding-left: 5px;
  width: 80%;
}

.el-table-add-row {
  margin-top: 10px;
  width: 100%;
  height: 34px;
  border: 1px dashed #c1c1cd;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  line-height: 34px;
}
</style>
