<template>
  <div style='height: calc(100vh - 132px);overflow-y: scroll'>
    <el-row class='fullHeight'>
      <el-col :span='19' class='fullHeight'>
        <el-row :gutter='10'>
          <el-col :span='12' class='fullHeight'>
            <el-card class='box-card' style='min-height:200px;margin-bottom:10px;width: auto' v-if='eleWarn=="true"'>
              <div slot='header' class='clearfix card-title'>
                <span style='font-size: 16px;'><i class='iconfont' v-html="'&#xe659;'"></i> 断电告警</span>
                <el-button style='float: right; padding: 3px 0; color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto("setting/electricWarnLog","历史记录","electricWarnLog")'>
                  查看详情<i class='el-icon-arrow-right'></i></el-button>
              </div>
              <div class='main21 flex-col'>
                <div class='eleWarn' v-for='(item,index) in eleWarnData' :key='index'
                     style='border-bottom: 1px solid rgba(220, 224, 233, 0.4);'>
                  <div class='bd3 flex-row'>
                    <span class='word211'>{{ item.statusDate | dateFormat   }}</span>
                    <span class='info2'>{{ item.deviceName }}</span>
                    <div :class='colorOfType(1,item.buildType)'>
                      <span class='txt2 font-6px'>{{ item.buildTypeName }}</span>
                    </div>
                    <span class='txt6' v-if='item.status==2'>{{ item.eventDesc }}</span>
                    <div class='layer2' v-if='item.status==0||item.status==1'>
                      <span class='txt3'>{{ item.eventDesc }}</span>
                    </div>
                    <div class='layer3 flex-col iconfont' v-html="'&#xe60e;'"
                         v-if='item.status==0||item.status==1'></div>
                  </div>
                </div>
                <el-empty v-if='eleWarnData.length<1' :image-size="40"></el-empty>
              </div>
            </el-card>
            <el-card class='box-card' style='min-height:250px;margin-bottom:10px;width: auto' v-if='evn=="true"'>
              <div slot='header' class='clearfix card-title3'>
                <span style='font-size: 16px'>
                  <i class='iconfont' v-html="'&#xe60d;'"></i>
                  实时环控
                </span>
                <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto("setting/evnDeviceList","环控设备列表","evnDeviceList")'>
                  查看详情<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <span v-for='(item,index) in evnData' :key='index' @click='toDeviceList(item)'>
                <div v-if='item.arrLength>2'>
                  <div class='flex-row' style='margin-left: 16px'>
                    <span class='word17'>{{ item.deviceName }}</span>
                      <div :class='colorOfType(3,item.buildType)'>
                        <span class='word18'>{{ item.buildTypeName }}</span>
                      </div>
                  </div>
                </div>
                <div class='layer8 flex-row min-width' >
                  <div class='flex-row' style='margin-right: 16px' v-if='item.arrLength<=2'>
                    <span class='word17'>{{ item.deviceName }}</span>
                      <div :class='colorOfType(3,item.buildType)'>
                        <span class='word18'>{{ item.buildTypeName }}</span>
                      </div>
                  </div>
                  <div class='wrap4' v-if='item.temperature'>
                    <span class='info8'>{{ item.temperature.passValue }}</span>
                    <span class='word19'>℃</span>
                    <span class='word-c19'>{{ item.temperature.passName }}</span>
                  </div>
                  <div class='wrap5' v-if='item.humidity'>
                    <span class='word20'>{{ item.humidity.passValue }}</span>
                    <span class='word21'>%RH</span>
                    <span class='word-c21'>{{ item.humidity.passName }}</span>
                  </div>
                  <div class='wrap6' v-if='item.ammonia'>
                    <div>
                      <span class='word22'>{{ item.ammonia.passValue }}</span>
                      <span class='txt18'>ppm</span>
                    </div>
                    <span class='txt18'>{{ item.ammonia.passName }}</span>
                  </div>
                  <el-tooltip placement="top" v-if='item.fan'>
                    <template slot='content'>{{item.fan.passValue}}</template>
                    <div class='wrap7 flex-col' v-if='item.fan&&item.fan.passValue!="--"'>
                      <i class='iconfont icon2' v-html="'&#xe9ed;'" v-if='item.fan.passValue=="关闭"'></i>
                      <i class='iconfont icon2-on' v-html="'&#xe9ed;'" v-if='item.fan.passValue=="开启"'></i>
                      <span class='info9' >{{ item.fan.passName }}</span>
                    </div>
                  </el-tooltip>
                  <el-tooltip placement="top" v-if='item.curtain'>
                    <template slot='content'>{{item.curtain.passValue}}</template>
                    <div class='wrap8 flex-col' v-if='item.curtain&&item.curtain.passValue!="--"'>
                      <i class='iconfont label6' v-html="'&#xe9ee;'" v-if='item.curtain.passValue=="关闭"'></i>
                      <i class='iconfont label6-on' v-html="'&#xe9ee;'" v-if='item.curtain.passValue=="开启"'></i>
                      <span class='txt19' >{{ item.curtain.passName }}</span>
                    </div>
                  </el-tooltip>

                <i class='iconfont label7 ' style='float: right' v-html="'&#xe600;'" @click='toDeviceList(item)'></i>
                </div>
              </span>
              <el-empty v-if='evnData.length<1' :image-size="40"></el-empty>
            </el-card>
            <el-card class='box-card' style='min-height:200px;margin-bottom:10px;width: auto' v-if='pigSale=="true"'>
              <div slot='header' class='clearfix card-title'>
                <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe66f;'"></i> 卖猪盘点</span>
                <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto("setting/pigSaleLog","历史记录","pigSaleLog")'
                >查看详情<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <div class='main21 flex-col'>
                <div v-for='item in pigSaleData' :key='item.id'>
                  <div class='outer3 '>
                    <div class='group4'>
                      <span class='info4' v-if='item.createDate'>{{ item.createDate | dateFormat }}</span>
                      <!--2021/06/07&nbsp;12:20-13:20-->
                      <div class='section1'>
                        <span class='info5'>{{ item.passagewayName }}过道卖出{{ item.updateCount?item.updateCount:item.pigCount }}头</span>
                        <span class='word7'>{{ item.type }}</span>
                        <span class='txt14'>&nbsp;总重{{ item.updateWeight?Math.round(item.updateWeight/10)/100: Math.round(item.pigWeight/10)/100 }}吨</span>
                      </div>
                    </div>
                    <div style='float: right;margin: 2px 17px 0 0'>
                      <div class='group5  iconfont' v-html="'&#xe692;'"></div>
                      <span class='word8' @click='showModel(item.url)'>查看视频</span>
                    </div>
                  </div>
                </div>
                <el-empty v-if='pigSaleData.length<1' :image-size="40"></el-empty>
              </div>
            </el-card>
            <el-card class='box-card' style='min-height:250px;width: auto;' v-if='foot=="true"'>
              <div slot='header' class='clearfix card-title2'>
                <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe623;'"></i> 料塔一览</span>
                <span class='title-water'><span class='title-nav'>余料合计{{ foodCount?foodCount:'--' }}<span v-if='foodCount'>吨</span></span></span>
                <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto("setting/foodDeviceList","料塔设备列表","foodDeviceList")'>
                  查看详情<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <div style='margin: 0px 16px 12px 16px;'>
                <div class='main43' v-for='item in foodList' :key='item.deviceId'>
                  <div class='main44 flex-row'>
                    <span class='txt63'>{{ item.deviceName }}</span>
                    <div :class='colorOfType(4, item.buildType)' v-if='item.buildType'>
                      <span class='word130'>{{ item.buildTypeName }}</span>
                    </div>
                  </div>
                  <div class='main45 flex-row'>
                    <div class='mod22 flex-col'>
                      <div class='block9 flex-row'>
                        <span class='word133'>余</span>
                        <span class='info53'>{{ item.residue?item.residue:'--'}}</span>
                        <span class='info54'>吨</span>
                        <span class='txt64'>，</span>
                        <span class='info55'>日均耗料</span>
                        <span class='word134'>{{ item.avgAmount!=0.0?item.avgAmount:'--' }}</span>
                        <span class='word135'>kg</span>
                      </div>
                    </div>
                  </div>
                </div>
                <el-empty v-if='foodList.length<1' :image-size="40"></el-empty>
              </div>
            </el-card>
          </el-col>
          <el-col :span='12' class='fullHeight'>
            <el-card class='box-card' style='min-height:250px;width: auto;margin-bottom:10px'>
              <div slot='header' class='clearfix card-title'>
                <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe62a;'"></i> 关注事项</span>
                <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto(" follow/followRecord","关注记录","followRecord")'>
                  查看详情<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <div class='main10 flex-col'>
                <div class='outer9 flex-row' v-for='item in followEventData' :key='item.id'>
                  <div class='section3 flex-col' v-if='item.status==2'></div>
                  <div class='section3-1 flex-col' v-if='item.status==0||item.status==1'></div>
                  <div class='section4'>
                    <span class='word42'>{{ item.buildName }}&nbsp;{{ item.deviceName }}</span>
<!--                    <span class='word43' v-if='item.status==2'>{{ item.eventDesc}}</span>-->
                    <span class='word43 word-none' v-if='item.status==2'>{{ item.desc}} 报警{{ item.continued}}</span>

                    <el-tooltip class="item word53-tip" effect="dark"  placement="top-end">
                      <span class='word43 word53-tip' v-if='item.status==2'>{{ item.desc }} 报警{{ item.continued }}</span>
                      <template slot='content'>
                        <span v-if='item.status==2'>{{ item.desc }} 报警{{ item.continued }}</span>
                      </template>
                    </el-tooltip>

                    <span class='word53 word-none' v-if='item.status==0||item.status==1'>{{ item.desc }}{{ item.continued }}</span>
                    <el-tooltip class="item word53-tip" effect="dark"  placement="top-end">
                      <span class='word53 word53-tip' v-if='item.status==0||item.status==1'>{{ item.desc }}{{ item.continued }}</span>
                      <template slot='content'>
                        <span v-if='item.status==0||item.status==1'>{{ item.desc }} {{ item.continued }}</span>
                      </template>
                    </el-tooltip>
                  </div>
                  <div class='section5'>
                    <span class='word44'>{{ item.statusDate | dateFormat }}</span>
                  </div>
                </div>
                <el-empty v-if='followEventData.length<1' :image-size="40"></el-empty>
              </div>
            </el-card>
            <el-card class='box-card' style='min-height:250px;width: auto;margin-bottom:10px' v-if='ele=="true"'>
              <div slot='header' class='clearfix card-title2'>
                <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe619;'"></i> 24小时用电量</span>
                <span class='title-water'><span class='title-nav'>过去24小时用电{{ eleCount? eleCount:'--'}}<span v-if='eleCount'>度</span></span></span>
                <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto("setting/electricLog","电表历史记录","electricLog")'>
                  查看详情<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <div class='main14'>
                <div v-for='(item,index) in eleList' :key='item.deviceId?item.deviceId:index' class='box-water'>
                  <div class='main19 flex-row'>
                    <span class='txt43 word-none'>{{ item.deviceName }}</span>
                    <el-tooltip class="item word53-tip" effect="dark"  placement="top-end">
                      <span class='txt43 word53-tip'>{{ item.deviceName }}</span>
                      <template slot='content'>
                        <span >{{ item.deviceName }}</span>
                      </template>
                    </el-tooltip>

                    <div :class='colorOfType(2, item.buildType)'>
                      <span class='word67'>{{ item.buildTypeName }}</span>
                    </div>
                    <div :class='iconSort(index)'></div>
                  </div>
                  <div class='main20 flex-row'>
                    <div class='bd24 flex-col iconfont' v-html="'&#xe60c;'"></div>
                    <span class='word69'>{{ item.amount | dataFormat()}}</span>
                    <span class='word72' style='font-size: 10px' v-if='item.amount'>度</span>
                    <span class='txt44'>24h用电</span>
                    <span class='word71'>{{ item.amountCount!=0.0 ? item.amountCount : '--' }}</span>
                    <span class='word72' style='font-size: 10px' v-if='item.amountCount!=0.0'>度</span>
                  </div>
                </div>
                <el-empty v-if='eleList.length<1' :image-size="40"></el-empty>
              </div>
            </el-card>
            <el-card class='box-card' style='min-height:250px;width: auto;' v-if='water=="true"'>
              <div slot='header' class='clearfix card-title2'>
                <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe9ef;'"></i> 24小时用水量</span>
                <span class='title-water'>
                  <span class='title-nav'>过去24小时用水{{ waterCount?waterCount:'--' }}<span v-if='waterCount'>m³</span></span>
                </span>
                <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto("setting/waterLog","水表历史记录","waterLog")'>
                  查看详情<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <div class='main14'>
                <div v-for='(item,index) in waterList' :key='item.deviceId?item.deviceId:index' class='box-water'>
                  <div class='main19 flex-row'>
                    <span class='txt43 word-none'>{{ item.deviceName }}</span>
                    <el-tooltip class="item word53-tip" effect="dark"  placement="top-end">
                      <span class='txt43 word53-tip'>{{ item.deviceName }}</span>
                      <template slot='content'>
                        <span >{{ item.deviceName }}</span>
                      </template>
                    </el-tooltip>
                    <div :class='colorOfType(2, item.buildType)'>
                      <span class='word67'>{{ item.buildTypeName }}</span>
                    </div>
                    <div :class='iconSort(index)'></div>
                  </div>
                  <div class='main20 flex-row'>
                    <div class='bd24 flex-col iconfont' v-html="'&#xe60c;'"></div>
<!--                    <span class='word69'>{{ item.amount != null && item.amount != 0 ? item.amount : 0 }}</span>-->
                    <span class='word69'>{{ item.amount | dataFormat() }}</span>
                    <span class='word70' v-if='item.amount'>m³</span>
                    <span class='txt44'>24h用水</span>
                    <span class='word71'>{{ item.amountCount!=0.0 ? item.amountCount : '--' }}</span>
                    <span class='word72' v-if='item.amountCount!=0.0'>m³</span>
                  </div>
                </div>
                <el-empty v-if='waterList.length<1' :image-size="40"></el-empty>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span='5' class='fullHeight' style='padding:0 0 10px 10px '>
        <el-card class='box-card' style='min-height:250px;width: auto;'>
          <div slot='header' class='clearfix card-title2'>
            <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe611;'"></i> 分享沟通</span>
            <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                       @click='goto("setting/messageLog","历史消息","messageLog")'
            >查看详情<i class='el-icon-arrow-right'></i>
            </el-button>
          </div>
          <div class='main25 flex-col'>
            <div class='box29 flex-col'>
              <div class='outer16 flex-col'></div>
              <div class='outer17 flex-col'>
                <div v-for='(item,index) in messageData' :key='index'>
                  <div class='main26 flex-row'>
                    <span class='word121'>{{ item.account }}</span>
                    <span class='info49'>{{ item.createdTime | dateFormat  }}</span>
                  </div>
                  <div class='main27 flex-row'>
                      <span class='txt58'>
                        {{ item.content }}
                      </span>
                  </div>
                </div>
              </div>
              <div style='margin: 0 16px'>
                <el-input type='textarea' style='margin-top: 5px;' v-model='messageForm.content'></el-input>
              </div>
              <div>
                <el-button type='primary' size='small' style='margin: 8px 16px 8px 0;float: right'
                           @click='addCommunication'>
                  发送
                </el-button>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class='box-card ' style='height: 365px;width: auto;margin-top: 10px'>
          <div slot='header' class='clearfix'>
            <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe639;'"></i> 我的收藏</span>
          </div>
          <div style='height: 300px;overflow-y: scroll;scrollbar-width: none;padding: 4px' class='scroll_hidden'>
            <div class='card' v-for='(item,index) in collectionList' :key='index'>
              <el-card shadow='hover'>
                <div slot='header' class='clearfix'>
                  <span><i class='iconfont' v-html="'&#xe626;'" style='color: #358AFF'></i></span>
                  <span style='float: right;opacity: 0'
                        @click='deleteCollection(item.id)'
                  >
                  <i class='delete iconfont' v-html="'&#xe777;'" style='color: #B74105'></i></span>
                </div>
                <div class='card-main' @click='goto(item.router,item.menuName,item.target)'>
                  {{ item.menuName }}
                </div>
              </el-card>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title='视频播放'
      :visible.sync='editVisible'
      width='50%'
    >
      <video id='exampleVideo' width='100%' height='100%'  controls="controls" autoplay
             :src='urlVideo'>您的设备不支持video
      </video>
    </el-dialog>

  </div>
</template>
<script>
import { tranBuildType } from '../../libs/tools'
import { mapState } from 'vuex'
import store from '../../store'
import Template from '../setting/template'
import moment from 'moment'
import pigSaleList from '../setting/pigSaleList'
import axios from '../../api'
import loginApi from '../../api/user/login'

export default {
  components: { Template },
  inject: ['reload'],
  data() {
    return {
      evn:false,
      water:false,
      ele:false,
      foot:false,
      eleWarn:false,
      pigSale:false,
      urlVideo: '',
      editVisible: false,
      farmId: store.state.farmId,
      datax: [
        {
          index: 1,
          is: false
        }, {
          index: 2,
          is: false
        }, {
          index: 3,
          is: true
        },
        {
          index: 4,
          is: true
        }
      ],
      eleWarnData: [],
      evnData: [],
      evnList: [],
      waterList: [],
      waterCount: 0,
      eleList: [],
      eleCount: 0,
      foodList: [],
      foodCount: 0,
      pigSaleData: [],
      messageData: [],
      messageForm: {
        content: '',
        farmId: store.state.farmId
      },
      collectionList: [],
      followEventData: []
    }
  },
  computed: {
    isFollow() {
      return this.$store.state.farmId　　//需要监听的数据
    }
  },
  watch: {
    isFollow(newVal, oldVal) {
      this.init()
    }
  },
  created() {
  },
  mounted() {
    if (store.state.farmId != '') {
      this.init()
      // this.clearTabs()
    }
  },
  filters: {
    dataFormat(msg) {
      if (msg==null){
        return '--'
      }
      if (msg>1000){
        let item  = '0'
        if (Math.floor(msg%1000)<100){
           item = item + '' + Math.floor(msg%1000)
        }else {
          item = Math.floor(msg%1000)
        }
        return Math.floor(msg/1000) +' '+ item
      }else {
        return msg
      }
    },
    dateFormat(msg) {
      let day = moment(new Date()).diff(moment(msg),'d')
      let year = moment(new Date()).diff(moment(msg),'y')
      if (day<1){
        let hour = moment(msg).format('HH')
        if( moment(msg).format('HH')>12){
          return '下午' + moment(msg).format('HH:mm')
        }else {
          return '上午' + moment(msg).format('HH:mm')
        }
      }else {
        if (day<=2){
          return '昨天' +  moment(msg).format('HH:mm')
        }
        if (day>=2&&year<=0){
          return  moment(msg).format('MM-DD HH:mm')
        }
        if (year>0){
          return moment(msg).format('yyyy-MM-DD HH:mm')
        }
      }
    }
  },
  methods: {
    //列表权限
    getAppInfo() {
      this.$http.get(this.$api.getAppInfo, {}).then(res => {
        // console.log(res.data)
        if (res.data){
          res.data.map(item=>{
            if (item.appName=='实时环控'){
              this.evn = item.isOpen
            }else if (item.appName=='水量计') {
              this.water = item.isOpen
            }else if (item.appName=='电表') {
              this.ele = item.isOpen
            }else if (item.appName=='料塔') {
              this.foot = item.isOpen
            }else if (item.appName=='断电报警') {
              this.eleWarn = item.isOpen
            }else if (item.appName=='卖猪盘点') {
              this.pigSale = item.isOpen
            }
          })
        }
      })
    },
    toDeviceList(item){
      this.$router.push({
        name:'evnDeviceList',
        query: {
          deviceName:item.deviceName
        }
      })
      localStorage.setItem('routename', "evnDeviceList")
      localStorage.setItem('routetarget', "evnDeviceList")
      this.$store.commit('selectMenu', {
        router: "setting/evnDeviceList",
        name: "环控设备列表",
        target: "evnDeviceList"
      })
    },
    showModel(url) {
      this.urlVideo = url
      this.editVisible = true
    },
    init() {
      this.getAppInfo()
      this.getElectricUsed()
      this.getElectricUsedCount()
      this.getWaterUsed()
      this.getWaterUsedCount()
      this.getFoodUsed()
      this.getFeedResidueCount()
      this.getEvnStatusPage()
      this.getPigSaleLog()
      this.getFollowEvent()
      this.getElectricWarnLog()
      this.getCommunicationPage()
      this.listCollection()
    },
    clearTabs() {
      let arr = []
      loginApi.getMenusBySysCode('iot').then(res => {
        if (res.code === 200) {
          if (res.result) {
            res.result.map(item => {
              if (item.children) {
                item.children.map(x => {
                  arr.push(x)
                })
              } else {
                arr.push(item)
              }
            })
          }
          if (arr) {
            arr.map(item => {
              this.$store.commit('closeTab', item)
            })
          }
        }
      })
    },

    iconSort(index) {
      return {
        group7: true,
        'flex-col': true,
        iconSort1: index % 4 == 0,
        iconSort2: index % 4 == 1,
        iconSort3: index % 4 == 2,
        iconSort4: index % 4 == 3
      }
    },
    colorOfType(index, type) {
      let obj = {}
      if (index == 1) {
        obj = { layer1: true, 'flex-col': true }
      }
      if (index == 2) {
        obj = { group6: true, 'flex-col': true }
      }
      if (index == 3) {
        obj = { wrap3: true, 'flex-col': true }
      }
      if (index == 4) {
        obj = { mod20: true, 'flex-col': true }
      }
      if (type == 0) obj.color0 = true
      if (type == 1) obj.color1 = true
      if (type == 2) obj.color2 = true
      if (type == 3) obj.color3 = true
      if (type == 4) obj.color4 = true
      if (type == 5) obj.color5 = true
      if (type == 6) obj.color6 = true
      if (type == 7) obj.color7 = true
      if (type == 8) obj.color8 = true
      if (type == 9) obj.color9 = true
      if (type == 10) obj.color10 = true

      return obj

    },
    goto(path, name, target) {
      this.$router.push({ name: target })
      localStorage.setItem('routename', target)
      localStorage.setItem('routetarget', target)
      this.$store.commit('selectMenu', {
        router: path,
        name: name,
        target: target
      })
      this.reload()
    },
    //电列表
    getElectricUsed() {
      this.$http.get(this.$api.getElectricUsed, {
        current: 1,
        size: 6
      }).then(res => {
        if (res.data) {
          this.eleList = res.data.records
          if (this.eleList) {
            this.eleList.map(item => {
              item.buildTypeName = tranBuildType(item.buildType)
            })
          }
        }
      })
    },
    //电合计
    getElectricUsedCount() {
      this.$http.get(this.$api.getElectricUsedCount, {}).then(res => {
        this.eleCount = res.data

      })
    },
    //水列表
    getWaterUsed() {
      this.$http.get(this.$api.getWaterUsed, {
        current: 1,
        size: 6
      }).then(res => {
        if (res.data) {
          this.waterList = res.data.records
          if (this.waterList) {
            this.waterList.map(item => {
              item.buildTypeName = tranBuildType(item.buildType)
            })
          }
        }
      })
    },
    //水合计
    getWaterUsedCount() {
      this.$http.get(this.$api.getWaterUsedCount, {}).then(res => {
        this.waterCount = res.data

      })
    },
    //料塔一览
    getFoodUsed() {
      this.$http.get(this.$api.getFeedUsed, {
        current: 1,
        size: 6
      }).then(res => {
        if (res.data) {
          this.foodList = res.data.records
          if (this.foodList) {
            this.foodList.map(item => {
              item.residue = Math.floor(item.residue*10)/10
              item.buildTypeName = tranBuildType(item.buildType)
            })
          }
        }
      })
    },
    //料塔合计
    getFeedResidueCount() {
      this.$http.get(this.$api.getFeedResidueCount, {}).then(res => {
        this.foodCount = Math.floor(res.data/100)/10
      })
    },
    //环控
    getEvnStatusPage() {
      this.$http.get(this.$api.getEvnStatusPage, {
        current: 1,
        size: 4
      }).then(res => {
        if (res.data) {
          this.evnData = res.data.records
          if (this.evnData) {
            this.evnData.map(item => {
              let length = 0
              item.buildTypeName = tranBuildType(item.buildType)
              let wd = []
              let sd = []
              let aq = []
              let fj = []
              let sl = []
              if (item.passDataList.length>0){
                item.passDataList.map(device=>{
                  if (device.passType=='室内温'||device.passType=='室外温'){
                    wd.push(device)
                  }else if (device.passType=='湿度'){
                    sd.push(device)
                  }else if (device.passType=='氨气浓度'){
                    aq.push(device)
                  }else if (device.passType=='定速风机'&&device.passValue!='--'){
                    fj.push(device)
                  }else if (device.passType=='水帘'&&device.passValue!='--'){
                    sl.push(device)
                  }
                })
                if (wd.length>0){
                  item.temperature = wd[0]
                  length++
                }
                if (sd.length>0){
                  item.humidity = sd[0]
                  length++
                }
                if (aq.length>0){
                  item.ammonia = aq[0]
                  length++
                }
                if (fj.length>0){
                  item.fan = fj[0]
                  length++
                }
                if (sl.length>0){
                  item.curtain= sl[0]
                  length++
                }

                item.arrLength = length
              }

            })
            console.log(this.evnData)
          }
        }
      })
    },
    //卖猪盘点
    getPigSaleLog() {
      this.$http.get(this.$api.pigSaleLog, {
        deviceType: '卖猪盘点',
        current: 1,
        size: 3
      }).then(res => {
        if (res.data) {
          this.pigSaleData = res.data.records
          console.log(this.pigSaleData)

        }
      })
    },
    //断电告警
    getElectricWarnLog() {
      let that = this
      this.$http.get(this.$api.getFollowEvent, {
        type: '断电报警',
        current: 1,
        size: 4
      }).then(res => {
        if (res.data) {
          this.eleWarnData = res.data.records
          if (this.eleWarnData) {
            this.eleWarnData.map(item => {
              item.buildTypeName = tranBuildType(item.buildType)
            })
          }
        }
      })
    },

    //关注事项
    getFollowEvent() {
      let that = this
      this.$http.get(this.$api.getFollowEvent, {
        current: 1,
        size: 7
      }).then(res => {
        if (res.data) {
          this.followEventData = res.data.records
        }
      })
    },

    //分享沟通
    getCommunicationPage() {
      this.$http.get(this.$api.getCommunicationPage, {
        current: 1,
        size: 10
      }).then(res => {
        if (res.data) {
          this.messageData = res.data.records
        }
      })
    },
    //添加分享沟通
    addCommunication() {
      if (this.messageForm.content == null || this.messageForm.content.trim() == '') {
        this.$message.error('消息不能为空')
        return
      }
      if (this.messageForm.content.length > 150) {
        this.$message.error('消息长度不能超过150字')
        return
      }
      this.$http.post(this.$api.addCommunication, {
        ...this.messageForm
      }).then(res => {
        if (res.statusCode == 200) {
          this.$message.success('消息发送成功')
          this.messageForm.content = ''
        } else {
          this.$message.error('消息发送失败')
        }
        this.getCommunicationPage()
      })
    },
    //收藏列表
    listCollection() {
      this.$http.get(this.$api.listCollection, {}).then(res => {
        if (res.data) {
          res.data.map(item => {
            item.target = item.router.substring(item.router.indexOf('/') + 1, item.router.length)
          })
          this.collectionList = res.data
        }
      })
    },
    //取消收藏
    deleteCollection(id) {
      let that = this
      that.$http.delete(this.$api.deleteCollection(id), {}).then(res => {
        if (res.statusCode == 200) {
          this.$message.success('取消成功')
          that.listCollection()
          that.reload()
        }
      })
    }
  }
}
</script>
<style scoped>

.scroll_hidden::-webkit-scrollbar {
  display: none;
}

>>> .el-textarea__inner {
  padding: 5px 5px !important;
}

>>> .el-card__header {
  padding: 12px;
}

>>> .el-card__body {
  padding: 0px;
}

.card-title {
  min-width: 230px;
  overflow-x: hidden;
}

.card-title2 {
  /*min-width: 350px;*/
  overflow-x: hidden;
}

.card-title3 {
  min-width: 230px;
  overflow-x: hidden;
}

.min-width {
  min-width: 510px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}


.box-card {
  width: 480px;
}

.fullHeight {
  height: 100%
}


.main21 {
  min-width: 450px;
  margin: 0 16px
  /*overflow-x: auto;*/
}

.main21:hover {
  /*overflow-y: scroll;*/
}

.bd3 {
  z-index: auto;
  /*width: 450px;*/
  height: 20px;
  margin: 16px 1px;
}

.word21 {
  z-index: 692;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  margin-top: 2px;
  margin-left: 2px;
}

.info2 {
  z-index: 695;
  /*width: 80px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-left: 20px;
}

.layer1 {
  z-index: 686;
  height: 16px;
  border-radius: 3px;
  width: 36px;
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 5px;
}

.color0 {
  background-color: #375EEF;
}

.color1 {
  background-color: #375EEF;
}

.color2 {
  background-color: #647BD0;
}

.color3 {
  background-color: #349CD2;
}

.color4 {
  background-color: #31BFBB;
}

.color5 {
  background-color: #36BD7F;
}

.color6 {
  background-color: #52BD36;
}

.color7 {
  background-color: #99B52F;
}

.color8 {
  background-color: #E3E3E3;
}

.color9 {
  background-color: #CACACA;
}

.color10 {
  background-color: #AEB6C4;
}

.txt2 {
  z-index: 687;
  width: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  line-height: 8px;
  text-align: left;
}

.font-6px {
  transform: scale(0.7);
  font-size: 12px;
}

.txt6 {
  z-index: 697;
  /*width: 155px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(11, 136, 95, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-left: 33px;
}

.layer2 {
  z-index: 698;
  /*width: 112px;*/
  height: 20px;
  overflow-wrap: break-word;
  text-align: left;
  margin-left: 33px;
  font-size: 0;
}

.txt3 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(210, 94, 46, 0.8);
  line-height: 20px;
}

.txt4 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: #BC4D4D;
  line-height: 20px;
}

.word2 {
  font-size: 14px;
  font-family: PingFangSC-Medium;
  color: #BC4D4D;
  line-height: 20px;
}

.word3 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: #BC4D4D;
  line-height: 20px;
}

.layer3 {
  z-index: 704;
  width: 15px;
  height: 16px;
  color: #BC4D4D;
  font-size: 18px;
  margin: 2px 0 0 2px;
}

/*====card2=====*/
.main10 {
  z-index: 204;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}

.outer9 {
  z-index: auto;
  padding: 8px 0px;
  margin: 0 16px;
  border-bottom: 1px solid rgba(220, 224, 233, 0.4)

}

.section3 {
  z-index: 217;
  height: 12px;
  width: 12px;
  background-color: rgba(11, 136, 95, 1);
  border-radius: 50%;
  margin-top: 3px;
}

.section3-1 {
  z-index: 217;
  height: 12px;
  width: 12px;
  background-color: #D25E2E;
  border-radius: 50%;
  margin-top: 3px;
}

.section4 {
  z-index: 207;
  height: 20px;
  overflow-wrap: break-word;
  text-align: left;
  margin-left: 12px;
  font-size: 0;
}

.word42 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(42, 42, 55, 1);
  line-height: 20px;
}

.word43 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  margin-left: 10px;
  color: rgba(11, 136, 95, 1);
  line-height: 20px;
}

.word53 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  margin-left: 10px;
  color: #D25E2E;
  line-height: 20px;
}
.word53-tip{
  display: none;
}
.section5 {
  z-index: 211;
  height: 17px;
  overflow-wrap: break-word;
  text-align: left;
  font-size: 0;
  margin-left: auto;

  /*margin: 2px 0 0 200px;*/
}

.txt31 {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: rgba(42, 42, 55, 1);
  line-height: 17px;
}

.word44 {
  font-size: 12px;
  font-family: PingFangSC-Medium;
  color: rgba(175, 175, 175, 1);
  line-height: 17px;
}

/*  =====card3====*/
.outer3 {
  z-index: auto;
  /*width: 599px;*/
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(220, 224, 233, 0.4);
}

.group4 {
  z-index: auto;
  /*width: 221px;*/
  display: inline-block;
}

.info4 {
  z-index: 752;
  /*width: 184px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  text-align: left;
  align-self: flex-start;
}

.section1 {
  z-index: 753;
  width: 270px;
  overflow-wrap: break-word;
  text-align: left;
  margin-top: 4px;
  font-size: 0;
}

.info5 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.word7 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(53, 138, 255, 1);
  line-height: 20px;
}

.txt14 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.group5 {
  z-index: 756;
  width: 13px;
  height: 13px;
  font-size: 14px;
  color: #358AFF;
  display: inline-block;
  /*margin: 9px 0 0 305px;*/
}

.group5:hover, .word8:hover {
  cursor: pointer
}

.word8 {
  z-index: 755;
  /*width: 56px;*/
  display: inline-block;
  overflow-wrap: break-word;
  color: rgba(53, 138, 255, 1);
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin: 7px 0 0 4px;
}

/*======card4=========*/
.iconSort1 {
  background: url(../../assets/img/index/a1.png) 100% no-repeat;
}

.iconSort2 {
  background: url(../../assets/img/index/a2.png) 100% no-repeat;
}

.iconSort3 {
  background: url(../../assets/img/index/a3.png) 100% no-repeat;
}

.iconSort4 {
  background: url(../../assets/img/index/a4.png) 100% no-repeat;
}

.title-water {
  display: inline-block;
  z-index: 525;
  border-radius: 16px;
  background-color: #f5f9ff;
  margin-left: 6px;
  /*width: 170px;*/
  /*height: 20px;*/
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
}

.title-evn {
  display: inline-block;
  z-index: 525;
  border-radius: 16px;
  background-color: rgba(53, 138, 255, 0.05);
  margin-left: 6px;
  height: 20px;
  width: 63%;
  justify-content: center;
  align-items: center;
}

.title-nav {
  color: rgba(42, 42, 55, 0.6);
  display: inline-block;
  width: 100%;
  font-size: 12px;
  /*text-align: center*/
}

.box-water {
  display: inline-block;
  width: 50%;
  padding: 8px 15px 8px 0;
  border-bottom: 1px solid rgba(220, 224, 233, 0.4);
}

.main19 {
  z-index: auto;
  width: 280px;
  height: 20px;
}

.main20 {
  z-index: auto;
  width: 280px;
  height: 25px;
  margin: 4px 0 0 1px;
}

.main14 {
  z-index: 519;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  /*width: 610px;*/
  margin-left: 17px;
  justify-content: center;
}

.bd24 {
  z-index: 613;
  width: 12px;
  font-size: 12px;
  height: 12px;
  margin-top: 4px;
  opacity: 0.4;
}

.word69 {
  z-index: 596;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 4px;
}

.word70 {
  z-index: 582;
  /*width: 14px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: left;
  margin: 3px 0 0 3px;
}

.txt44 {
  z-index: 558;
  /*width: 38px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin: 8px 0 0 25px;
}

.word71 {
  z-index: 560;
  display: block;
  overflow-wrap: break-word;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2A2A37;
  line-height: 28px;
  text-align: left;
  margin-left: 4px;
}

.word72 {
  display: block;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  margin: 9px 0 0 3px;
  font-weight: 500;
  color: #2A2A37;
}

.word73 {
  z-index: 599;
  width: 45px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 4px;
}

.txt45 {
  z-index: 591;
  width: 14px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: left;
  margin: 3px 0 0 3px;
}

.word74 {
  z-index: 590;
  width: 5px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 8px;
  font-family: PingFangSC-Regular;
  line-height: 11px;
  text-align: left;
  margin-top: 9px;
}

.word75 {
  z-index: 570;
  width: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin: 8px 0 0 32px;
}

.word76 {
  z-index: 572;
  width: 21px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 4px;
}

.word77 {
  z-index: 571;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin: 8px 0 0 3px;
}

.txt43 {
  z-index: 533;
  /*display: block;*/
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2A37;
}

.group6 {
  z-index: 542;
  height: 16px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 6px;
}

.word67 {
  z-index: 543;
  display: block;
  width: 36px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  transform: scale(0.7);
  line-height: 8px;
  text-align: left;
}

.group7 {
  z-index: 982;
  width: 45px;
  margin-left: 6px;
  /*margin: 0 0 0 6px;*/
}

/*=====card5====*/
.layer8 {
  z-index: 999;
  margin: 5px 17px 0 17px;
  /*width: 603px;*/
  height: 50px;
  border-bottom: 1px solid rgba(220, 224, 233, 0.4);;
}

.layer8:hover {
  cursor: pointer
}

.word17 {
  z-index: 282;
  /*width: 81px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-top: 12px;
  padding-right: 4px;
}

.wrap3 {
  z-index: 286;
  height: 16px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}

.word18 {
  z-index: 290;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  transform: scale(0.7);
  /*line-height: 8px;*/
  text-align: left;
}

.wrap4 {
  z-index: 254;
  height: 22px;
  overflow-wrap: break-word;
  text-align: left;
  font-size: 0;
}

.info8 {
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.8);
  line-height: 22px;
}

.word19 {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: rgba(177, 177, 177, 0.8);
  line-height: 22px;
}

.word-c19 {
  display: block;
  font-size: 12px;
  /*width: 40px;*/
  font-family: PingFangSC-Regular;
  color: rgba(177, 177, 177, 0.8);
  line-height: 22px;
}

.wrap5 {
  z-index: 262;
  /*width: 52px;*/
  height: 26px;
  overflow-wrap: break-word;
  text-align: left;
  margin-left: 25px;
  /*margin-left: 3%;*/
  font-size: 0;
}

.word20 {
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: rgba(42, 42, 55, 1);
  line-height: 22px;
}

.word211 {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: rgba(175, 175, 175, 1);
  line-height: 22px;
  /*width: 19%;*/
  /*overflow: hidden;*/
  /*white-space: nowrap;*/
  /*text-overflow: ellipsis;*/
}

.word-c21 {
  display: block;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: rgba(175, 175, 175, 1);
  line-height: 26px;
}

.wrap6 {
  z-index: 270;
  width: 65px;
  height: 22px;
  overflow-wrap: break-word;
  text-align: left;
  margin-left: 25px;
  font-size: 0;
}

.word22 {
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: rgba(42, 42, 55, 1);
  line-height: 22px;
}

.txt18 {
  font-size: 12px;
  margin-left: 1px;
  font-family: PingFangSC-Regular;
  color: rgba(183, 183, 183, 1);
  line-height: 22px;
}

.wrap7 {
  z-index: auto;
  min-width: 55px;
  height: 34px;
  margin-left: 20px;
  margin-top: 1px;
}

.icon2 {
  z-index: 302;
  width: 19px;
  height: 19px;
  align-self: center;
}

.icon2-on {
  z-index: 302;
  width: 19px;
  height: 19px;
  color: #2F99F3;
  align-self: center;
}

.info9 {
  z-index: 274;
  /*width: 25px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(183, 183, 183, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: center;
  /*align-self: flex-start;*/
  margin-top: 9px;
}

.wrap8 {
  z-index: auto;
  width: 40px;
  height: 33px;
  margin: 1px 0 0 25px;
}

.label6 {
  z-index: 366;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-top: 1px;
  align-self: center;
}

.label6-on {
  z-index: 366;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-top: 1px;
  color: #2F99F3;
  align-self: center;
}

.txt19 {
  z-index: 278;
  display: block;
  overflow-wrap: break-word;
  color: rgba(183, 183, 183, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: center;
  margin-top: 9px;
}

.label7 {
  z-index: 227;
  width: 16px;
  height: 16px;
  margin-top: 11px;
  margin-left: auto;
  color: #2F99F3;
  /*margin: 7px 0 0 60px;*/
}

.label7:hover {
  cursor: pointer
}

/*  ========料塔===== */
.main43 {
  display: inline-block;
  width: 50%;
}

.main44 {
  z-index: auto;
  width: 250px;
  height: 20px;
  margin-top: 12px;
}

.txt63 {
  z-index: 498;
  /*width: 54px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
}

.mod20 {
  z-index: 790;
  height: 16px;
  border-radius: 3px;
  /*width: 30px;*/
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 5px;
  padding: 1px;
}

.word130 {
  z-index: 791;
  /*width: 18px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  transform: scale(0.7);
  line-height: 8px;
  text-align: left;
}

.word131 {
  z-index: 501;
  width: 54px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-left: 228px;
}

.mod21 {
  z-index: 799;
  height: 14px;
  border-radius: 3px;
  background-color: rgba(225, 157, 95, 1);
  width: 30px;
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 5px;
}

.word132 {
  z-index: 800;
  width: 18px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 6px;
  font-family: PingFangSC-Regular;
  line-height: 8px;
  text-align: left;
}

.main45 {
  z-index: auto;
  width: 250px;
  height: 41px;
  margin-top: 8px;
  justify-content: space-between;
}

.mod22 {
  z-index: 648;
  height: 41px;
  border-radius: 4px;
  background-color: rgba(244, 244, 245, 1);
  width: 220px;
  justify-content: center;
  align-items: center;
}

.block9 {
  z-index: auto;
  /*width: 180px;*/
  height: 25px;
  justify-content: space-between;
}

.word133 {
  z-index: 843;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.info53 {
  z-index: 888;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  padding: 0 5px;
}

.info54 {
  z-index: 837;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.txt64 {
  z-index: 951;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.info55 {
  z-index: 825;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.word134 {
  z-index: 849;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  padding: 0 5px;
}

.word135 {
  z-index: 831;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.mod23 {
  z-index: 651;
  height: 41px;
  border-radius: 4px;
  background-color: rgba(244, 244, 245, 1);
  width: 187px;
  justify-content: center;
  align-items: center;
}

.bd21 {
  z-index: auto;
  width: 163px;
  height: 25px;
  justify-content: space-between;
}

.txt65 {
  z-index: 846;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.word136 {
  z-index: 891;
  width: 27px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
}

.txt66 {
  z-index: 840;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.word137 {
  z-index: 954;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.word138 {
  z-index: 828;
  width: 40px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.info56 {
  z-index: 852;
  width: 32px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
}

.word139 {
  z-index: 834;
  width: 12px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

/*====分享沟通======*/
.main25 {
  z-index: 3;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  /*box-shadow: 0px 2px 8px 0px rgba(221, 221, 221, 1);*/
  align-self: flex-start;
  justify-content: flex-start;
  /*padding-top: 10px;*/
}

.outer15 {
  z-index: auto;
  height: 25px;
  width: 318px;
  margin-left: 10px;
}

.outer152 {
  z-index: auto;
  height: 25px;
  width: 275px;
  padding-top: 10px;
  margin-left: 10px;
}

.box30 {
  z-index: 27;
  width: 21px;
  height: 21px;
  line-height: 21px;
  margin-top: 3px;
}

.info48 {
  z-index: 4;
  width: 72px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  margin-left: 2px;
}

.word120 {
  z-index: 7;
  width: 64px;
  display: block;
  overflow-wrap: break-word;
  color: #409EFF;
  font-size: 14px;
  text-align: left;
  margin: -7px 0 0 152px;
}

.outer16 {
  /*margin-top: 16px;*/
}

.outer17 {
  z-index: 11;
  height: 245px;
  /*width: 318px;*/
  overflow-y: scroll;
  scrollbar-width: none;
  /*background-color: rgba(242, 242, 242, 1);*/
}

.outer17::-webkit-scrollbar {
  display: none;
}

.main26 {
  z-index: auto;
  height: 28px;
  margin-left: 16px;
}

.word121 {
  z-index: 12;
  width: 52px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.8);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  margin-top: 6px;
}

.info49 {
  z-index: 16;
  width: 168px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.3);
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  margin: 10px 12px 0 87px;
}


.main27 {
  z-index: auto;
  margin: 6px 16px 0 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(211, 215, 223, 0.4);

}

.txt58 {
  z-index: 20;
  display: block;
  width: 245px;
  overflow-wrap: break-word;
  color: rgba(60, 66, 73, 0.6);
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}

>>> .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
}

/*===收藏===*/
.collection-card {
  overflow-y: scroll;
}

/*定义滚动条样式（高宽及背景）*/
.collection-card::-webkit-scrollbar, .outer17::-webkit-scrollbar {
  width: 6px; /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
}

/*定义滚动条轨道（凹槽）样式*/
.collection-card::-webkit-scrollbar-track, .outer17::-webkit-scrollbar {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); /* 较少使用 */
  border-radius: 3px;
}

/*定义滑块 样式*/
.collection-card::-webkit-scrollbar-thumb, .outer17::-webkit-scrollbar {
  border-radius: 3px;
  height: 100px; /* 滚动条滑块长度 */
  background-color: #ccc;
}

.card {
  display: inline-block;
  padding: 4px;
  width: 50%;
}

>>> .card .el-card__body {
  padding: 0;
  font-size: 14px;
  /*width: 146px;*/
  height: 65px;
  background-color: #F2F2F2;
}

>>> .card .el-card.is-hover-shadow:hover {
  border-radius: 4px;
  box-sizing: border-box;
  transition: box-shadow .5s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  text-decoration: none;
}

>>> .card .el-card {
  /*width: 146px;*/
}

>>> .card .el-card__header {
  padding: 2px 3px;
  background-color: #ECECEC;
  border-bottom: 1px solid #EBEEF5;
  box-sizing: border-box;
}

.delete:hover {
  cursor: pointer
}

.card-main {
  /*width: 135px;*/
  height: 65px;
  text-align: center;
  line-height: 65px;
}

.card-main:hover {
  cursor: pointer;
}

.card:hover span:nth-child(2) {
  opacity: 1 !important;
  transition: 1.5s;
  -webkit-transition: 1.5s;
}

/*======低分辨率=====*/
@media screen and (max-width: 1600px) {
  .word211 {
    font-size: 12px;
    font-family: PingFangSC-Regular;
    color: rgba(175, 175, 175, 1);
    line-height: 22px;
    width: 19%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .main21 {
    min-width: auto;
    margin-left: 17px;
  }

  .info2 {
    z-index: 695;
    width: 20%;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-family: PingFangSC-Regular;
    line-height: 20px;
    text-align: left;
    margin-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .layer2 {
    z-index: 698;
    width: 30%;
    height: 20px;
    overflow-wrap: break-word;
    text-align: left;
    margin-left: 33px;
  }

  .txt3 {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .section3 {
    z-index: 217;
    height: 10px;
    width: 10px;
    background-color: rgba(11, 136, 95, 1);
    border-radius: 50%;
    margin-top: 5px;
  }

  .section3-1 {
    z-index: 217;
    height: 10px;
    width: 10px;
    background-color: #D25E2E;
    border-radius: 50%;
    margin-top: 5px;
  }

  .section4 {
    width: 60%;
  }

  .word42 {
    display: inline-block;
    /*width: 35%;*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .word43 {
    font-size: 14px;
    color: rgba(11, 136, 95, 1);
    line-height: 20px;
    display: inline-block;
    margin-left: 10px;
    width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .word53 {
    font-size: 14px;
    color: #D25E2E;
    line-height: 20px;
    margin-left: 10px;
    display: inline-block;
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .word53-tip{
    display: inline-block;
  }

  .word-none{
    display: none;
  }

  .title-evn {
    display: none;
  }

  .title-water {
    width: 40%;
    display: inline-block;
    z-index: 525;
    border-radius: 16px;
    background-color: #f5f9ff;
    margin-left: 6px;
    /*height: 20px;*/
    padding: 4px 0 4px 8px;
    justify-content: center;
    align-items: center;
  }

  .box-water {
    display: inline-block;
    width: 225px;
    padding: 8px 15px 8px 0;
    border-bottom: 1px solid rgba(220, 224, 233, 0.4);
  }

  .txt43 {
    z-index: 533;
    /*display: block;*/
    overflow-wrap: break-word;
    color: rgba(42, 42, 55, 1);
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    font-weight: 400;
    color: #2A2A37;
    width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .txt44 {
    margin: 8px 0 0 10px;
  }
}

</style>
