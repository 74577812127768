<template>
  <div style="background-color: white;padding: 10px;border-radius: 8px;height: calc(100vh - 132px);overflow-y: hidden">
    <searchForm :formOptions="formOptions" @onSearch="onSearch" :btnItems="btnItems" @onExport="onExport" />
    <div class="title">
      {{ startTime }} <span v-if="startTime">至</span> {{ endTime }} 共 <span style="color:black">{{ pager.total }}</span> 条 合计卖猪头数
      <el-tooltip class="item" effect="dark" content="因部分摄像头无盘点功能或用户未补填数据，“合计头数”不一定等于实际售猪数量。" placement="top-start">
        <i class="el-icon-warning-outline"></i>
      </el-tooltip>
      ：<span style="color:black">{{ count }}</span> 头， 重量：<span style="color:black">{{ weight }}</span> 吨
    </div>
    <page-table
      v-loading="loading"
      :tableData="tableData"
      :tableLabel="tableLabel"
      :tableOption="tableOption"
      class="gdtable"
      :pager="pager"
      :tableId="'eleLog'"
      @pagination="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleButton="tableBtnHandle(arguments)"
    ></page-table>
    <el-dialog title="视频播放" :visible.sync="editVisible" width="50%">
      <!--      poster="../../assets/img/video.png"-->
      <!--      <video id="exampleVideo" width='100%' height='100%' controls autoplay :src="urlVideo">您的设备不支持video</video>-->

      <video width="100%" height="100%" controls style="background-color: black" :src="urlVideo">
        您的浏览器不支持 HTML5 video 标签。
      </video>
    </el-dialog>
    <el-dialog title="视频播放" :visible.sync="editVisible2" width="50%">
      <!--      poster="../../assets/img/video.png"-->
      <!--      <video id="exampleVideo" width='100%' height='100%' controls autoplay :src="urlVideo">您的设备不支持video</video>-->

      <video width="100%" height="100%" controls style="background-color: black" :src="urlEnvVideo">
        您的浏览器不支持 HTML5 video 标签。
      </video>
    </el-dialog>
    <el-dialog title="记录详情" :visible.sync="detailLocalVisible" width="60%">
      <el-button @click="printDetails()" type="primary" v-print="printOption" size="small">打印</el-button>
      <div id='printableArea'>
        <el-descriptions class="margin-top" title="基础信息" :column="2" size="small" border>
          <el-descriptions-item label="过道名称">
            {{ detailLog.passagewayName }}
          </el-descriptions-item>
          <el-descriptions-item label="卖猪类型">
            {{ detailLog.type }}
          </el-descriptions-item>
          <el-descriptions-item label="状态">
            {{ detailLog.endDate ? '已结束' : '未结束' }}
          </el-descriptions-item>
          <el-descriptions-item label="开始时间">
            {{ detailLog.createDate }}
          </el-descriptions-item>
          <el-descriptions-item label="结束时间">
            {{ detailLog.endDate }}
          </el-descriptions-item>
          <el-descriptions-item label="猪只批次号">
            {{ detailLog.batchId }}
          </el-descriptions-item>
          <el-descriptions-item label="销售人员">
            {{ detailLog.salePerson }}
          </el-descriptions-item>
          <el-descriptions-item label="客户/订单号">
            {{ detailLog.customer }}
          </el-descriptions-item>
          <el-descriptions-item label="异常信息">
            {{ detailLog.message }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions class="margin-top" title="卖猪头数" :column="2" size="small" border>
          <el-descriptions-item label="AI盘点头数">
            {{ detailLog.pigCount }}
          </el-descriptions-item>
          <el-descriptions-item label="头数差">
            {{ detailLog.countDiffer }}
          </el-descriptions-item>
          <el-descriptions-item label="确认头数">
            {{ detailLog.manualCount }}
          </el-descriptions-item>
          <el-descriptions-item label="称重有效重量(kg)">
            {{ detailLog.pigWeight }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions class="margin-top" title="称重记录" size="small" border> </el-descriptions>
        <el-table :data="salePigList" style="width: 100%" size="small">
          <el-table-column prop="printTime" label="打印时间"> </el-table-column>
          <el-table-column prop="manualCount" label="确认头数"></el-table-column>
          <el-table-column prop="originalCount" label="原始确认头数"></el-table-column>
          <el-table-column prop="weight" label="重量（kg）"></el-table-column>
          <el-table-column prop="originalWeight" label="原始重量"></el-table-column>
          <el-table-column prop="isDelete" label="状态" width="60">
            <template slot-scope="scope">
              {{ scope.row.isDelete == 1 ? '无效' : '有效' }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button @click="modification(scope.row,'edit')" type="text" size="small">修改</el-button>
              <el-button @click="handleClick2(scope.row)" type="text" size="small">{{ scope.row.isDelete == 1 ? '恢复' : '剔除' }}</el-button>
              <el-button @click="modification(scope.row,'check')" type="text" size="small">修改记录</el-button>
              <!-- <el-button @click="showVideoUrl(scope.row)" type="text" size="small">录像</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog title="修改称重记录" :visible.sync="editRecordVisible" width="90%">
      <el-form v-if='isEditRecord' :inline="true" :rules="rules" :model="formItem" ref="formItem" :label-position="labelPosition" label-width="120px" class="demo-form-inline">
        <el-row>
          <el-col :span='12'>
            <el-form-item label="确认头数" prop="manualCount" style='width: 100%'>
              <el-input-number style='width: 100%' v-model="formItem.manualCount" controls-position="right" :min="0"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label="重量(kg)" prop="weight" style='width: 100%'>
              <el-input-number style='width: 100%' v-model="formItem.weight" controls-position="right" :min="0"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="修改原因" prop="remark" style='width: 100%'>
              <el-input v-model="formItem.remark" style='width: 215px' type="textarea" :rows="2" placeholder="修改原因"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div v-if='isEditRecord' style='font-weight: bold;font-size: 18px;margin-bottom: 10px'>修改记录</div>
      <el-table :data="editRecordList" class='editRecordTable' style="width: 100%;" size="small">
        <el-table-column prop="originalCount" width='90' label="原始头数(1)"></el-table-column>
        <el-table-column prop="adjustmentCount" width='90' label="调整头数(2)"></el-table-column>
        <el-table-column prop="afterCount" width='120'>
          <template slot="header">
            <el-popover
              placement="top-start"
              trigger="hover"
              content="计算方式：原始头数(1) + 调整头数(2)">
              <i slot="reference" class='el-icon-info'></i>
            </el-popover>
            <span style='margin-left: 5px'>修改后头数(3)</span>
          </template>
        </el-table-column>
        <el-table-column prop="beforeCount" width='120' label="本次修改前头数(4)"></el-table-column>
        <el-table-column prop="originalWeight" width='90' label="原始重量(5)"></el-table-column>
        <el-table-column prop="adjustmentWeight" width='90' label="调整重量(6)"></el-table-column>
        <el-table-column prop="afterWeight" width='160'>
          <template slot="header">
            <el-popover
              placement="top-start"
              trigger="hover"
              content="计算方式：原始重量(5) + 调整重量(6)">
              <i slot="reference" class='el-icon-info'></i>
            </el-popover>
            <span style='margin-left: 5px'>修改后重量（kg）(7)</span>
          </template>
        </el-table-column>
        <el-table-column prop="beforeWeight" width='120' label="本次修改前重量(8)"></el-table-column>
        <el-table-column prop="remark" label="修改原因(9)"></el-table-column>
        <el-table-column prop="createdTime" label="修改时间(10)"></el-table-column>
      </el-table>

      <span v-if='isEditRecord' slot="footer" class="dialog-footer">
        <el-button @click="editRecordVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEditRecord('formItem')">保 存</el-button>
      </span>
    </el-dialog>

    <el-dialog title="卖猪录像" :visible.sync="videoUrlVisible" width="40%" style="text-align:center;">
      <video width="400" height="320" controls style="background-color: black" :src="videoUrl">
        <!--        <source :src="detailLog.url" type="video/mp4">-->
        <!--        <source :src="detailLog.url" type="video/ogg">-->
        您的浏览器不支持 HTML5 video 标签。
      </video>
    </el-dialog>

    <el-dialog title="记录详情" :visible.sync="detailVisible" width="50%">
      <el-descriptions class="margin-top" title="基础信息" :column="2" size="small" border>
        <el-descriptions-item label="过道名称">
          {{ detailLog.passagewayName }}
        </el-descriptions-item>
        <el-descriptions-item label="卖猪类型">
          {{ detailLog.type }}
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          {{ detailLog.endDate ? '已结束' : '未结束' }}
        </el-descriptions-item>
        <el-descriptions-item label="开始时间">
          {{ detailLog.createDate }}
        </el-descriptions-item>
        <el-descriptions-item label="结束时间">
          {{ detailLog.endDate }}
        </el-descriptions-item>
        <el-descriptions-item label="猪只批次号">
          {{ detailLog.batchId }}
        </el-descriptions-item>
        <el-descriptions-item label="销售磅单号">
          {{ detailLog.saleNo }}
        </el-descriptions-item>
        <el-descriptions-item label="销售人员">
          {{ detailLog.salePerson }}
        </el-descriptions-item>
        <el-descriptions-item label="客户/订单号">
          {{ detailLog.customer }}
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions class="margin-top" title="卖猪头数" :column="2" size="small" border>
        <el-descriptions-item label="AI盘点头数">
          {{ detailLog.pigCount }}
        </el-descriptions-item>
        <el-descriptions-item label="修正后头数">
          <el-input v-model="detailLog.updateCount" size="small"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="称重有效重量">
          {{ detailLog.pigWeight }}
        </el-descriptions-item>
        <el-descriptions-item label="修正后重量(kg)">
          <el-input v-model="detailLog.updateWeight" size="small"></el-input>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="称重记录" size="small" border> </el-descriptions>
      <el-table :data="salePigList" style="width: 100%" size="small">
        <el-table-column prop="createDate" label="上传时间"> </el-table-column>
        <el-table-column prop="weight" label="重量（kg）"> </el-table-column>
        <el-table-column prop="isDelete" label="状态">
          <template slot-scope="scope">
            {{ scope.row.isDelete == 1 ? '无效' : '有效' }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">{{ scope.row.isDelete == 1 ? '恢复' : '剔除' }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-descriptions class="margin-top" title="卖猪视频" size="small" border> </el-descriptions>

      <video width="160" height="120" controls style="background-color: black" :src="detailLog.url">
        <!--        <source :src="detailLog.url" type="video/mp4">-->
        <!--        <source :src="detailLog.url" type="video/ogg">-->
        您的浏览器不支持 HTML5 video 标签。
      </video>

      <!--      <video id="exampleVideo2" width='160px' height='120px' style='background-color: black' controls autoplay :src="detailLog.url">您的设备不支持video</video>-->

      <template slot="footer" class="el-dialog__footer">
        <el-button @click="submitAll" type="primary" size="small">保存</el-button>
        <el-button @click="() => (this.detailVisible = false)" type="primary" size="small">取消</el-button>
      </template>
    </el-dialog>
    <el-dialog title="环境视频下载" :visible.sync="envVideoVisible" width="50%">
      <el-table
        :data="envVideoTableData"
        style="width: 100%">
        <el-table-column
          prop="name"
          label="视频名称">
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作">
          <template slot-scope="scope">
            <el-button @click="downloadEnv(scope.row)" type="text" size="small">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable2'
import searchForm from '@/components/searchForm'
import moment from 'moment'
import { exportExcel, tranBuildType } from '../../libs/tools'
import store from '../../store'
import Template from './template'
import axios from 'axios'
import { getDownload } from '../../libs/axios'
import print from 'vue-print-nb'

export default {
  name: 'pigSaleLog',
  components: {
    Template,
    pageTable,
    searchForm
  },
  directives: {
    print
  },
  data() {
    return {
      videoUrl: '',
      envVideoTableData: [],
      videoUrlVisible: false,
      envVideoVisible: false,
      detailLocalVisible: false,
      editRecordVisible: false,
      isEditRecord: false,
      editRecordList: [],
      formItem:{
        manualCount: 0,
        weight: 0,
        remark: '',
      },
      printOption: {
        id: 'printableArea', // 打印元素的id 不需要携带#号
        popTitle: '记录详情', // 页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
      },
      labelPosition: 'right',
      rules:{
        manualCount: [{ required: true, message: '请输入确认头数', trigger: 'blur' }],
        weight: [{ required: true, message: '请输入重量', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入修改原因', trigger: 'blur' }],
    },
      urlVideo: '',
      detailLog: {},
      editVisible: false,
      editVisible2: false,
      detailId: '',
      urlEnvVideo: '',
      detailVisible: false,
      loading: true,
      btnItems: 'search' + 'export' + 'reset',
      // startTime: moment().format('yyyy/MM/DD'),
      startTime: '',
      // endTime: moment().format('yyyy/MM/DD'),
      endTime: '',
      obj: {
        saleState: undefined,
        pigType: undefined,
        passagewayName: undefined,
        // startTime: moment().format('yyyy-MM-DD') + ' 00:00:00',
        startTime: '',
        // endTime: moment().format('yyyy-MM-DD') + ' 23:59:59'
        endTime: ''
      },
      count: 0,
      weight: 0,
      formOptions: [
        {
          label: '类型',
          prop: 'pigType',
          element: 'el-select',
          initValue: '',
          options: [
            { label: '保育猪', value: '保育猪' },
            { label: '肥猪', value: '肥猪' },
            { label: '标定', value: '标定' },
          ]
        },
        {
          label: '过道名称',
          prop: 'passagewayName',
          element: 'el-input',
          placeholder: '请输入过道名称'
        },
        // {
        //   label: '状态',
        //   prop: 'saleState',
        //   element: 'el-select',
        //   initValue:'',
        //   options: [
        //     { label: '全部', value: '' },
        //     { label: '卖猪中', value: 0 },
        //     { label: '已结束', value: 1 }
        //   ]
        // },
        {
          label: '时间',
          prop: 'date',
          type: 'daterange', //monthrange | datetimerange | daterange |date
          element: 'el-date-picker'
        }
      ],
      tableData: [],
      salePigList: [],

      tableLabel: [
        {
          label: '批次号',
          param: 'batchId'
        },
        {
          label: '猪只类型',
          param: 'type'
        },
        {
          label: '总重量(kg)',
          param: 'weight',
          align: 'right'
        },
        {
          label: '均重(kg)',
          param: 'avgWeight',
          align: 'right'
        },
        {
          label: 'AI头数',
          param: 'count',
          align: 'right'
        },
        {
          label: '确认头数',
          param: 'manualCount',
          align: 'right',
          isRevamp: true
        },
        {
          label: '头数差',
          param: 'countDiffer',
          align: 'right',
          showCountDiffer: true
        },
        {
          label: '卖猪时间',
          param: 'createDate',
          width: 150
        },
        {
          label: '结束时间',
          param: 'endDate',
          width: 150
        }
      ],
      tableOption: {
        label: '操作',
        options: [
          // { label: '环境视频', methods: 'envVideo', type: 'text' },
          { label: '详情', methods: 'edit', type: 'text' },
          { label: '视频', methods: 'video', type: 'text' },
          { label: '下载', methods: 'downloadVideo', type: 'text' },
          { label: '下载环境视频', methods: 'downloadEnvVideo', type: 'text' }
        ],
        width: 210
      },
      tableVideo: {},

      pager: {
        pageSize: 10, // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0 //列表总数
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    showVideoUrl(data) {
      this.videoUrl = data.videoUrl
      if (this.videoUrl) {
        this.videoUrlVisible = true
      } else {
        this.$message.warning('视频还未上传，请稍后再试')
      }
    },
    submitAll() {
      let obj = {
        farmId: store.state.farmId,
        list: this.salePigList
      }
      this.$http.post(this.$api.updatePigsaledetailedlist, obj).then(res => {})
      this.$http.post(this.$api.updatePigSaleInfo, this.detailLog).then(res => {})
      this.detailVisible = false
      this.init()
    },
    handleClick2(item) {
      if (item.isDelete == 1) {
        item.isDelete = 0
      } else {
        item.isDelete = 1
      }
      let obj = {
        farmId: store.state.farmId,
        list: this.salePigList
      }
      this.$http.post(this.$api.updatePigsaledetailedlist, obj).then(res => {})
    },
    //打印
    printDetails(){

    },
    modification(item,type) {
      // if (item.isEditing){
      //   item.isEditing = 0
      //   let obj = {
      //     id: item.id,
      //     manualCount: item.manualCount,
      //     weight: item.weight,
      //   };
      //   this.$http.post(this.$api.correctPigsaledetailedlist, obj).then(res => {})
      // }else {
      //   item.isEditing = 1
      // }
      this.isEditRecord = type === 'edit';
      this.formItem.manualCount = item.manualCount;
      this.formItem.weight = item.weight;
      this.formItem.remark = '';
      this.formItem.id = item.id;
      this.$http.get(this.$api.PigDetailModifyRecordList, { detailId: item.id }).then(res => {
        this.editRecordVisible = true;
        let arr = res.data;
        arr.forEach(i =>{
          i.adjustmentCount = i.afterCount - item.originalCount;
          i.adjustmentWeight = i.afterWeight - item.originalWeight;
          i.originalCount = item.originalCount;
          i.originalWeight = item.originalWeight;
        })
        this.editRecordList = arr;
      })
    },
    saveEditRecord(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
            this.$http.post(this.$api.correctPigsaledetailedlist, {
              manualCount: this.formItem.manualCount,
              remark: this.formItem.remark,
              weight: this.formItem.weight,
              id: this.formItem.id,
            }).then(res => {
                if (res.statusCode == 200) {
                  this.$message({
                    type: 'success',
                    message: '修改成功'
                  })
                  this.editRecordVisible = false
                  this.getPigSaleInfo(this.detailId)
                }
              })
        } else {
          return false
        }
      })
    },
    handleClick(item) {
      if (item.isDelete == 1) {
        item.isDelete = 0
      } else {
        item.isDelete = 1
      }
    },
    init() {
      this.getPigSaleLog(this.obj)
      this.getLogCount(this.obj)
    },
    //卖猪盘点历史记录
    getPigSaleLog(obj) {
      let that = this
      this.$http
        .get(this.$api.pigSaleLog, {
          ...obj,
          deviceType: '销售称重秤',
          current: that.pager.currentPage,
          size: that.pager.pageSize
        })
        .then(res => {
          if (res.data) {
            let arr = res.data.records
            arr.map(item => {
              if (item.updateWeight != null) {
                item.weight = item.updateWeight
              } else {
                item.weight = item.pigWeight
              }
              if (item.updateCount != null) {
                item.count = item.updateCount
              } else {
                item.count = item.pigCount
              }

              if (item.manualCount != 0 && item != null) {
                item.avgWeight = Math.floor((item.weight / item.manualCount) * 100) / 100
              } else if (item.pigCount != 0){
                item.avgWeight = Math.floor((item.weight / item.pigCount) * 100) / 100
              }else {
                item.avgWeight = 0
              }
            })

            this.tableData = arr
            this.pager.total = res.data.total
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    //卖猪详情
    getPigSaleInfo(passagewayId) {
      let that = this
      this.$http
        .get(this.$api.getPigSaleInfo, {
          informationId: passagewayId
        })
        .then(res => {
          this.salePigList = res.data
        })
    },
    //卖猪盘点历史统计
    getLogCount(obj) {
      let that = this
      this.$http
        .get(this.$api.getPigSaleLogCount, {
          ...obj,
          deviceType: '销售称重秤'
        })
        .then(res => {
          if (res.data.amount) {
            that.count = res.data.amount
            that.weight = res.data.weight
          }
        })
    },
    //单元类型列表
    getBuildType() {
      let arr = []
      this.$http
        .get(this.$api.getBuildType, {
          deviceType: '销售称重秤'
        })
        .then(res => {
          if (res.data) {
            res.data.map(item => {
              arr.push({
                label: tranBuildType(item),
                value: item
              })
            })
          }
        })
      return arr
    },
    loadTable(val) {
      this.loading = true
      this.getPigSaleLog(val)
      this.getLogCount(val)
    },
    tableBtnHandle(arg) {
      if (arg[0] == 'video') {
        if (arg[1].url == null) {
          this.$message.error('无卖猪视频')
          return
        }
        this.editVisible = true
        this.urlVideo = arg[1].url
      } else if (arg[0] == 'edit') {
        this.detailLog = arg[1]
        if (arg[1].clientType === 'local') {
          this.detailLocalVisible = true
        } else {
          this.detailVisible = true
        }
        this.detailId = arg[1].id
        this.getPigSaleInfo(arg[1].id)
      } else if (arg[0] == 'downloadVideo') {
        if (arg[1].url == null) {
          this.$message.error('无卖猪视频')
          return
        }
        let urls = store.state.baseUrl + this.$api.downloadVideo + '?informationId=' + arg[1].id
        window.location.href = urls
      } else if (arg[0] == 'envVideo') {
        if (arg[1].envUrl == null) {
          this.$message.error('无环境视频')
          return
        }
        this.editVisible2 = true
        this.urlEnvVideo = arg[1].envUrl
      }else if (arg[0] === 'downloadEnvVideo'){
        if (arg[1].envUrl == null) return this.$message.error('无环境视频')
        const urls = arg[1].envUrl.split(';');
        if (urls.length > 1) {
          this.envVideoTableData = [];
          urls.forEach((item,index) =>{
            this.envVideoTableData.push({
              name: '环境视频' + (index + 1),
              url: item
            })
          })
          this.envVideoVisible = true;
        }else {
          window.location.href = urls
        }
      }
    },
    //下载环境视频
    downloadEnv(e){
      window.location.href = e.url;
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },
    onSearch(val) {
      if (val == null) {
        return
      }
      if (val.date != null) {
        this.obj.startTime = moment(val.date[0]).format('yyyy-MM-DD') + ' 00:00:00'
        this.startTime = moment(val.date[0]).format('yyyy/MM/DD')
        this.obj.endTime = moment(val.date[1]).format('yyyy-MM-DD') + ' 23:59:59'
        this.endTime = moment(val.date[1]).format('yyyy/MM/DD')
      } else {
        this.obj.startTime = null
        this.startTime = null
        this.obj.endTime = null
        this.endTime = null
      }
      if (val.passagewayName) {
        this.obj.passagewayName = val.passagewayName
      }
      // if (val.pigType) {
      //   this.obj.pigType = val.pigType
      // }
      // else {
      //   this.$message.warning("请选择日期")
      //   return
      // }
      this.$set(this.pager, 'currentPage', 1)
      this.obj.pigType = val.pigType
      this.obj.buildName = val.buildName
      this.obj.buildType = val.buildType
      this.obj.deviceName = val.deviceName
      this.obj.saleState = val.saleState
      this.loadTable(this.obj)
    },
    //栋舍列表
    getAllPigpen() {
      let arr = []
      this.$http
        .get(this.$api.getAllPigpen, {
          deviceType: '卖猪盘点'
        })
        .then(res => {
          if (res.data) {
            res.data.map(item => {
              arr.push({
                label: item.name,
                value: item.name
              })
            })
          }
        })
      return arr
    },
    onExport(val) {
      // if(val.date!=null){
      if (val.data != null) {
        this.day = moment(val.date[1]).diff(moment(val.date[0]), 'd') + 1
        this.obj.startTime = moment(val.date[0]).format('yyyy-MM-DD') + ' 00:00:00'
        this.startTime = moment(val.date[0]).format('yyyy/MM/DD')
        this.obj.endTime = moment(val.date[1]).format('yyyy-MM-DD') + ' 23:59:59'
        this.endTime = moment(val.date[1]).format('yyyy/MM/DD')
      } else {
        this.obj.startTime = null
        this.startTime = null
        this.obj.endTime = null
        this.endTime = null
      }
      if (val.passagewayName) {
        this.obj.passagewayName = val.passagewayName
      }
      if (val.pigType) {
        this.obj.pigType = val.pigType
      }
      // }else {
      //   this.$message.warning("请选择日期")
      //   return
      // }
      this.obj.buildName = val.buildName
      this.obj.buildType = val.buildType
      this.obj.deviceName = val.deviceName
      this.obj.saleState = val.saleState
      this.loadTable(this.obj)
      this.exportHisPigSaleList(this.obj)
    },
    //列表导出
    exportHisPigSaleList(obj) {
      // let urls = store.state.baseUrl+this.$api.exportHisPigSaleList +'?deviceType=卖猪盘点&current=-1&size=-1&farmId=' + store.state.farmId
      // let buildType = ""
      // let buildName = ""
      // let startTime = ""
      // let endTime = ""
      // let deviceName = ""
      // let saleState = ""
      // if (obj.buildType){
      //   buildType = obj.buildType
      //   urls = urls + '&buildType=' + buildType
      // }
      // if (obj.buildName){
      //   buildName = obj.buildName
      //   urls = urls + '&buildName=' + buildName
      // }
      // if (obj.startTime){
      //   startTime = obj.startTime
      //   urls = urls + '&startTime=' + startTime
      // }
      // if (obj.endTime){
      //   endTime = obj.endTime
      //   urls = urls + '&endTime=' + endTime
      // }
      // if (obj.deviceName){
      //   deviceName = obj.deviceName
      //   urls = urls + '&deviceName=' + deviceName
      // }
      // if (obj.saleState){
      //   saleState = obj.saleState
      //   urls = urls + '&saleState=' + saleState
      // }
      // window.location.href = urls
      axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token')
      let headers = {
        'x-auth-token': localStorage.getItem('token')
      }
      getDownload(
        this.$api.exportHisPigSaleList,
        {
          farmId: store.state.farmId,
          ...obj
        },
        headers
      ).then(res => {
        let blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        // downloadElement.download = decodeURIComponent(res.headers.filename); //下载后文件名
        downloadElement.download = `卖猪盘点历史记录.xlsx` //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放blob对象
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
  height: calc(100vh - 327px) !important;
  overflow-y: auto !important;
}
.editRecordTable{
  width: 100%;
}
::v-deep .editRecordTable .el-table__body-wrapper{
  max-height: 250px;
  overflow-y: auto!important;
}
.title {
  margin-bottom: 10px;
  color: #606266;
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
.margin-top {
  margin-top: 20px;
}
.el-input {
  width: 150px;
}
::v-deep .el-descriptions .is-bordered td,
::v-deep .el-descriptions .is-bordered th {
  border: 1px solid #ebeef5;
  width: 150px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
@page {
  size: auto;
  margin: 0mm;
}
</style>
