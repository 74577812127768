<template>
  <div>
    检测管理
  </div>
</template>

<script>
export default {
  name: "detection"
}
</script>

<style scoped>

</style>
