<template>
  <div style='background-color: white;padding: 10px;border-radius: 8px;height: calc(100vh - 132px);overflow-y: scroll'>
    <searchForm style='min-width: 700px' :formOptions="formOptions"  @onSearch="onSearch" :btnItems='btnItems'/>
    <div style='margin-bottom: 10px'>
<!--      <el-button type='primary' size='small' @click='resetCommunication'>撤回上一条</el-button>-->
      <el-button type='primary' size='small' @click="dialogFormVisible = true">新增</el-button>
    </div>
    <page-table
      v-loading="loading"
      :tableHeight="645"
      :tableData="tableData"
      :tableLabel="tableLabel"
      :tableOption="tableOption"
      :pager="pager"
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
      @handleButton='tableBtnHandle(arguments)'
    ></page-table>

    <el-dialog title="新增消息" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCommunication('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from "@/components/searchForm";
import moment from 'moment'
import { exportExcel, tranBuildType } from '../../libs/tools'
import store from '../../store'
export default {
  name:'messageLog',
  components:{
    pageTable,
    searchForm
  },
  data(){
    return{
      loading : true,
      btnItems: 'search'+'reset',
      startTime: moment().format('yyyy/MM/DD'),
      endTime: moment().format('yyyy/MM/DD'),
      form:{
        content:'',
        sysCode:'iot',
        regionId: store.state.farmId
      },
      rules:{
        content: [
          { required: true, message: '请填写消息内容', trigger: 'blur' },
          { min: 1, max: 150, message: '长度在 1 到 150 个字符', trigger: 'blur' }
        ]
      },
      formLabelWidth: '100px',
      dialogFormVisible:false,
      obj: {
        account:undefined,
        startTime: moment().subtract('days', 6).format('YYYY/MM/DD') + ' 00:00:00',
        endTime: moment().add('days',1).format('yyyy/MM/DD') + ' 00:00:00'
      },
      day: 1,
      count: 0,
      formOptions: [
        {
          label: '发送者',
          prop: 'account',
          element: 'el-input'
        },
        {
          label: '时间',
          prop: 'date',
          initValue: [new Date(moment().subtract('days', 6).format('YYYY-MM-DD')),new Date()],
          type: 'daterange',
          element: 'el-date-picker'
        }
      ],
      tableData: [],
      tableLabel: [
        {
          label: '姓名',
          param: 'createdBy'
        },{
          label: '时间',
          param: 'createdTime'
        },{
          label: '内容',
          param: 'content'
        }
      ],
      tableOption: {
        label: '操作',
        options: [
          { label: '撤回', methods: 'editDetail' },
        ]
      },
      pager:{
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total:0,  //列表总数
      }
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      this.getCommunicationPage(this.obj)
    },

    addCommunication(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post(this.$api.addCommunication, {
              ...this.form
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('消息发送成功')
              } else {
                this.$message.error('消息发送失败')
              }
              this.dialogFormVisible = false
              this.getCommunicationPage(this.obj)
            })
          } else {
            return false;
          }
        });
    },
    getCommunicationPage(obj) {
      let that = this
      this.$http.get(this.$api.getCommunicationPage, {
        ...obj,
        current: that.pager.currentPage,
        size: that.pager.pageSize,
        regionId:store.state.farmId
      }).then(res => {
        if (res.result){
          let arr = res.result.records
          if (arr) {
            arr.map(item => {
              item.buildType = tranBuildType(item.buildType)
            })
          }
          this.tableData = res.result.records
          this.pager.total = res.result.total
          this.loading = false
        }else {
          this.loading = false
        }
      })
    },
    loadTable(val){
      this.loading = true
      this.getCommunicationPage(val)
    },
    tableBtnHandle(arg){
      if (arg[1].resetFlag){
        this.resetCommunication(arg[1].id)
      }else {
        this.$message.warning('发送时间超过十分钟，不可撤回！')
      }

    },
    resetCommunication(id){
      this.$http.delete(this.$api.resetCommunication(id), {
      }).then(res => {
        if (res.code==200){
          this.$message.success(res.message)
          this.getCommunicationPage(this.obj)
        }
      })
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },
    onSearch (val) {
      if (val==null){
        return
      }
      if(val.date!=null){
        this.obj.startTime = moment(val.date[0]).format('yyyy/MM/DD') + ' 00:00:00'
        this.startTime = moment(val.date[0]).format('yyyy/MM/DD')
        this.obj.endTime = moment(val.date[1]).add('days',1).format('yyyy/MM/DD') + ' 00:00:00'
        this.endTime = moment(val.date[1]).format('yyyy/MM/DD')
      }else {
        this.$message.warning("请选择日期")
        return
      }
      this.$set(this.pager,'currentPage',1)
      this.obj.account = val.account
      this.loadTable(this.obj)
    },
  }
}
</script>

<style scoped>
.title {
  margin-bottom: 10px;
  color: #464752;
}
>>>.el-range-separator {
  padding: 0 5px;
  line-height: 22px;
  width: 7%;
  color: #303133;
}
</style>
