<template>
    <div v-loading="loading">
        <h3 v-if="modifyGoodsId">修改商品</h3>
        <h3 v-else>新增商品</h3>
    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
      <el-form-item label="商品名称:" required prop="goodsName">
        <el-input v-model="form.goodsName" style="width: 300px" clearable></el-input>
      </el-form-item>

      <el-form-item label="商品编码:" prop="guid">
        <el-input v-model="form.guid" type="textarea" style="width: 500px" clearable></el-input>
      </el-form-item>

      <el-form-item label="商品图片:" prop="image">
        <img src="/img/admin-logo.jpg" min-width="70" height="70">
      </el-form-item>

      <el-form-item label="商品地址:" prop="address">
        <el-input v-model="form.address" type="textarea" style="width: 500px" clearable></el-input>
      </el-form-item>

      <el-form-item label="电子邮件:" prop="email">
        <el-input v-model="form.email" type="textarea" style="width: 500px" clearable></el-input>
      </el-form-item>

       <el-form-item label="日期:" prop="createTime">
        <el-date-picker v-model="form.createTime" type="date" placeholder="选择日期">
       </el-date-picker>

      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">
          <template v-if="modifyGoodsId">立即修改</template>
          <template v-else>立即创建</template>
        </el-button>
        <el-button type="success" plain @click="resetFrom('form')">重置表单</el-button>
      </el-form-item>
    </el-form>
    </div>
</template>
<script>
export default {
  name: 'GoodsView',
  props: {
    modifyGoodsId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      form: {
        id: 0,
        goodsName: '',
        guid: '',
        address: '',
        email: '',
        createTime: ''
      },
      rules: {
        goodsName: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 1, max: 5, message: '长度限制1到5个字符', trigger: 'blur' }
        ],
        address: [
          { min: 1, max: 30, message: '长度限制1到30个字符', trigger: 'blur' }
        ],
        email: [
          { min: 1, max: 15, message: '长度限制1到15个字符', trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  mounted() {
    this.editInit()
  },
  methods: {
    editInit() {
      console.log(this.modifyGoodsId)
      // 修改初始化
      if (this.modifyGoodsId) {
        this.loading = true
        // this.$api.GoodsApi.getGoodsByID(this.modifyGoodsId)
        //   .then(rep => {
        //     this.form = rep.data.goods
        //     this.loading = false
        //   }).catch(err => {
        //     this.loading = true
        //     console.log(err)
        //   })
      }
    },
    submitForm(formName) {

    },
    resetFrom(formName) {

    }
  }
}
</script>
<style lang="">

</style>
