<template>
  <div class="devicebreak">
    <searchForm :formOptions='formOptions' @onSearch='onSearch2' :btnItems='btnItems'  @setSelect = 'setSelect'   @setSelect2 = 'setSelect2' />

    <page-table
      v-if="hardReset"
      :tableData='tableData2'
      :tableLabel='tableLabel2'
      :tableOption='tableOption2'
      class="gdtable"
      :pager='pager2'
      @pagination='handleCurrentChange2'
      @handleSizeChange='handleSizeChange2'
      @handleButton='tableBtnHandle(arguments)'

    ></page-table>
    <el-dialog
      title="故障报修"
      :visible.sync="centerDialogVisible"
      width="70%"
      >
      <!-- <div class="grid-content bg-purple"> -->
        <el-form  :rules="rules" :model="formItem" ref="formItem" :label-position="labelPosition" label-width="120px" class="demo-form-inline">
          <el-form-item label="问题描述" prop="serviceReason">
            <el-input type="textarea" :rows="2" v-model="formItem.serviceReason" placeholder="问题描述" ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input v-model="formItem.contacts" placeholder="联系人" ></el-input>
          </el-form-item>
          <el-form-item label="故障图片" prop="pictures">
            <el-upload
              class="upload-demo"
              :action="actions"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleSucess"
              :before-remove="beforeRemove"
              :with-credentials='true'
              name="files"
              multiple
              :limit="1"
              list-type="picture"
              :on-exceed="handleExceed"
              :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStrategy('formItem')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import searchForm from '@/components/searchForm';
import pageTable from '@/components/pageTable'
export default {
  
  name: 'deviceBreak',
  components:{
    searchForm,
    pageTable
  },
  data(){
    return{
      selectBuildId:'',
      hardReset:true,
      showSelection:true,
      rules:{
        buildId: [
          { required: true, message: '请选择绑定栋舍', trigger: 'change' }
        ],
      },
      labelPosition:'right',
      centerDialogVisible:false,
      btnItems: 'search' + 'reset',

      formOptions: [
        {
          label: '设备类型',
          prop: 'deviceType',
          element: 'el-select',
          options: this.getDeviceTypeList()
        },
        {
          label: '序列号',
          prop: 'snNumber',
          element: 'el-input',
          placeholder: '设备序列号'
        },
        {
          label: '栋舍',
          prop: 'buildingId',
          element: 'el-select',
          isChange: 'change',
          options: this.getBuildType()
        }, {
          label: '单元/区',
          prop: 'buildId',
          element: 'el-select',
          initValue:'',
          isChange: 'change2',
          showTooltip:true,
          options: []
        },
        {
          label: '栏位列表',
          prop: 'fieldId',
          element: 'el-select',
          options: []
        },
      ],

      tableData2: [],
      tableLabel2: [
        { label: '类型', param: 'deviceType' },
        { label: '序列号', param: 'snNumber' },
        { label: '绑定栋舍', param: 'buildingName' },
        { label: '绑定单元/区', param: 'buildName' },
        { label: '绑定栏位', param: 'fieldName' },
        { label: '名称', param: 'deviceName' },
        { label: '状态', param: 'online' },
        { label: '策略', param: 'strategyName' }
      ],
      tableOption2: {
        label: '操作',
        options: [
          // { label: '报修', methods: 'editDetail' },
          { label: '删除', methods: 'tuisDetail',type:'danger' },
        ]
      },
      pager2: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      },
      formItem:{
        deviceId:'',
        serviceReason:'',//问题描述
        contacts:'',//联系人
        pictures:'',
      },
      buildId:'',
      deviceIds:[],
      actions:'',
      fileList: []
    }
  },
  mounted() {
    this.actions = this.$store.state.baseUrl+'/Repair/uploadImg?farmId='+this.$store.state.farmId
    this.getDevicePage2();
    this.getAllPigpen();
  },
  watch:{
    selectBuildId(val){
      this.getAllPigpen(val)
    },
    buildId(val){
      this.getFiled(val)
    }
  },
  methods:{
    
    //栏位列表
    getFiled(val) {
      let list = []
      this.formOptions[4].options = []
      this.$http.get(this.$api.getPigpenFiled,{
        buildId:val
      }).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'label':item.name,'value':item.id})
          })
          this.formOptions[4].options = list
        }
      })
    },
    setSelect2(val){
      this.buildId = val
    },
    getAllPigpen(val){
      let arr = []
      let buildId='';
      if(val){
        buildId = val;
      }else{
        buildId = this.selectBuildId;
      }
      this.formOptions[3].options  = []
      this.$http.get(this.$api.getAllPigpen, {
        buildingId:buildId
      }).then(res => {
        if (res.data){
          res.data.map(item => {
            arr.push({
              label: item.name,
              value: item.id
            })
          })
          this.formOptions[3].options  = arr
        }
      })
    },
    handleSucess(res,file, fileList){
      this.formItem.pictures = res.data;
      // this.$message({
      //   type: 'success',
      //   message: res.message
      // });
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //获取栋舍id
    setSelect(val){
      this.selectBuildId = val;
      this.formOptions[3].initValue=''
    },
    //保存
    saveStrategy(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.post(this.$api.addRepair,{
            deviceId : this.formItem.deviceId,
            serviceReason : this.formItem.serviceReason,
            contacts : this.formItem.contacts,
            pictures : this.formItem.pictures,
          }).then(res=>{
            if(res.statusCode ==200){
              this.$message({
                type: 'success',
                message: res.message
              });
              this.centerDialogVisible = false;
              this.getDevicePage2();
            }
          })
        } else {
          return false;
        }
      });

    },
    //选中栏位列表
    saveFieldVal(){
      // console.log(111)
      // console.log(this.buildId)
    },
    //保存栋舍id
    saveBuildVal(val){
      //通过选择栋舍id获取栏位列表
      this.getPigpenFiled(val);
    },
    //获取栏位列表
    getPigpenFiled(buildId){
      if(buildId !=''){
        let list =[];
        this.$http.get(this.$api.getPigpenFiled,{
          buildId:buildId
        }).then(res=>{
          if(res.statusCode == 200){
            res.data.map(item=>{
              list.push({'label':item.name,'value':item.id})
            })
          }
        })
        this.formOptions2[4].options = list;
      }else{
        this.$message({
          type: 'warning',
          message: '请先选择单元/区'
        });
      }
    },
    //栋舍列表
    getBuildType() {
      let arr = []
      this.$http.get(this.$api.getBuildList, {
      }).then(res => {
        if (res.data){
          res.data.map(item=>{
            arr.push({
              label: item.name,
              value: item.id
            })
          })
        }
      })
      return arr
    },
    //获取单元列表
    getPigpenBuild(val){
      let arr = []
      this.$http.get(this.$api.getAllPigpen, {
        buildingId:val
      }).then(res => {
        if (res.data){
          res.data.map(item=>{
            arr.push({
              label:item.name,
              value:item.id
            })
          })
        }
        
      })
      return arr
    },
    //已绑定设备列表
    getDevicePage2(obj){
      let that = this
      this.$http.get(this.$api.getDevicePage, {
        ...obj,
        current: that.pager2.currentPage,
        size: that.pager2.pageSize,
        isBind:0
      }).then(res => {
        let arr =  res.data.records
        arr.map(item=>{
          if (item.online==1){
            item.online = '在线'
          }else {
            item.online = '离线'
          }
        })
        this.tableData2 = arr
        this.pager2.total = res.data.total
        this.hardReset = true

      })
    },
    //获取设备列表
    getDeviceTypeList(){
      let list =[];
      this.$http.get(this.$api.getDeviceTypeList).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'label':item,'value':item})
          })
        }
      })
      return list
    },
    //删除
    // confirmButtonClass:'el-button--danger',
    delDataBat(){
      this.$confirm('<p style="color: #de1818">删除后，设备数据无法查询，请慎重！</p>', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(this.$api.delDevice(this.formItem.deviceId)).then(res=>{
        this.$message({
          type: 'success',
          message: res.message
        });
        this.getDevicePage2(this.obj);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '删除失败'
        });
      });

      })
    },
    //未绑定设备进行绑定
    tableBtnHandle(arg) {
      this.formItem.deviceId = arg[1].deviceId;
      if(arg[0]=='editDetail'){
        //编辑
        this.centerDialogVisible = true;
        //this.setDialog(arg[1]);
        this.formItem.serviceReason ='';
        this.formItem.contacts = '';
        this.formItem.pictures = '';
        this.fileList =[];

      }else{
        this.delDataBat();
      }
    },

    onSearch2(val) {
      this.$set(this.pager2,'currentPage',1)
      this.hardReset = false
      this.obj = val;

      this.loadTable(this.obj)
    },
    loadTable(val){
      this.getDevicePage2(val)
    },
     //翻页
    handleCurrentChange2(val) {
      this.pager2.currentPage = val
      this.getDevicePage2(this.obj)
    },
    //页码大小
    handleSizeChange2(val) {
      this.pager2.pageSize = val
      this.getDevicePage2(this.obj)
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 296px) !important;
    overflow-y: auto !important;
}
::v-deep .el-upload-list--picture .el-upload-list__item-name {
  display: none;
}
.devicebreak{
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  height: calc(100vh - 132px);
  overflow-y: hidden;
  .el-select{width:194px;}
}

    .addbtnbox{
      margin-bottom:10px;
    }
</style>
