<template>
  <div style="background-color: white;padding: 10px;border-radius: 8px;height: calc(100vh - 132px);overflow-y: hidden">
    <searchForm :formOptions="formOptions" @onSearch="onSearch" :btnItems="btnItems" @onExport="onExport" @setSelect="setSelect" />
    <div class="title">
      <el-button v-if="btnItems.includes('search')" size="mini" type="primary" class="btn-search" @click="onAdd">新增</el-button>
      共 <span style="color:black">{{ totalDevice }}</span> 条, 在线 <span style="color:black">{{ onlineDevice }}</span
      >, 离线 <span style="color:red">{{ offlineDevice }}</span>
    </div>
    <page-table
      v-if="hardReset"
      v-loading="loading"
      :tableData="tableData"
      :tableLabel="tableLabel"
      :tableOption="tableOption"
      class="gdtable"
      :toTop="toTop"
      :isStatistics="isStatistics"
      :pager="pager"
      :tableId="'waterList'"
      @pagination="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleButton="tableBtnHandle(arguments)"
      @changeTop="changeTop"
      @cancelTop="cancelTop"
      @changeStatistics="changeStatistics"
      @cancelStatistics="cancelStatistics"
    ></page-table>
    <el-dialog :title="手动添加用水" :visible.sync="dialogVisible" :destroy-on-close="true" width="50%" center>
      <el-form :inline="true" :rules="rules" :model="formItem" ref="formItem" label-width="120px" class="demo-form-inline">
        <el-row>
          <el-col :span="12">
            <el-form-item label="栋舍" prop="buildId">
              <el-select style="width:100%" clearable filterable size="small" v-model="formItem.buildId" placeholder="请选择栋舍" @change="changeBuild">
                <el-option v-for="(item, index) in buildList" :key="item.id + index" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单元/区" prop="unitId">
              <el-select style="width:100%" clearable filterable size="small" v-model="formItem.unitId" placeholder="选择单元/区" @change="changeBuild">
                <el-option v-for="item in unitList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="水表数据" prop="readData">
              <el-input-number style="width:80%" :controls="false" v-model="formItem.readData" :min="0" :precision="2" size="small" class="managelist" placeholder="电表数据"></el-input-number>
              <span> m³</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="记录日期" prop="reportTime">
              <el-date-picker
                v-model="formItem.reportTime"
                class="managelist"
                type="date"
                size="small"
                :clearable="false"
                value-format="yyyy-MM-dd hh:mm:ss"
                style="margin-right: 5px;width:100%"
                placeholder="选择日期"
                align="right"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStrategy">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
import login from '../../api/user/login'
import { exportExcel, numberTran, tranBuildType } from '../../libs/tools'
import store from '../../store'
import axios from 'axios'
import { getDownload } from '../../libs/axios'

export default {
  name: 'waterDeviceList',
  components: {
    pageTable,
    searchForm
  },
  data() {
    return {
      buildList: [],
      unitList: [],
      formItem: {
        deviceType: '水量计'
      },
      dialogVisible: false,
      rules: {
        readData: [{ required: true, message: '水表数据不能为空！', trigger: 'input' }],
        reportTime: [{ required: true, message: '记录日期不能为空！', trigger: 'change' }],
        buildId: [{ required: true, message: '栋舍不能为空！', trigger: 'change' }],
        unitId: [{ required: true, message: '单元/区不能为空！', trigger: 'change' }]
      },
      selectBuildId: '',
      loading: true,
      hardReset: true,
      totalDevice: 0,
      onlineDevice: 0,
      offlineDevice: 0,
      obj: undefined,
      buildType: '',
      btnItems: 'search' + 'export' + 'reset',
      formOptions: [
        {
          label: '栋舍',
          prop: 'buildingId',
          element: 'el-select',
          options: this.getBuildType(),
          isChange: 'change'
        },
        {
          label: '单元/区',
          prop: 'buildId',
          element: 'el-select',
          showTooltip: true,
          options: []
        },
        {
          label: '状态',
          prop: 'online',
          element: 'el-select',
          options: [
            { label: '在线', value: '1' },
            { label: '离线', value: '0' }
          ]
        },
        {
          label: '设备名称',
          prop: 'deviceName',
          element: 'el-input',
          placeholder: '请输入设备名称'
        }
      ],
      tableData: [],
      tableLabel: [
        { label: '栋舍名称', param: 'buildingName' },
        { label: '单元/区名称', param: 'buildName' },
        { label: '单元类型', param: 'buildType' },
        { label: '设备名称', param: 'deviceName' },
        { label: '最近表读数(m³)', param: 'amount', align: 'right' },
        { label: '圈数', param: 'rangeNum' },
        { label: '状态', param: 'online', status: 1 }
      ],
      tableOption: {
        label: '操作',
        options: [{ label: '详情', methods: 'editDetail' }]
      },
      toTop: {
        label: '置顶',
        options: [{ label: '详情', methods: 'editDetail' }]
      },
      isStatistics: {
        label: '不统计',
        options: [{ label: '详情', methods: 'editDetail' }]
      },
      pager: {
        pageSize: 10, // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0 //列表总数
      }
    }
  },
  watch: {
    buildType(val) {
      this.getAllPigpen(val)
    }
  },
  mounted() {
    this.getWaterDeviceList()
    this.getStatusCount()
    this.getAllPigpen()
    this.getBuildType()
    this.getUnbindRealBuilds()
  },
  methods: {
    //切换栋舍
    changeBuild(v) {
      if (!v) {
        this.unitList = []
      } else {
        this.setUnitList(v)
      }
    },
    //设置单元列表
    setUnitList(buildId) {
      this.buildList.map(item => {
        if (item.id == buildId) {
          this.unitList = item.children
        }
      })
    },
    //获取用电栋舍列表
    getUnbindRealBuilds() {
      this.$http
        .get(this.$api.getUnbindRealBuilds, {
          deviceType: '水量计'
        })
        .then(res => {
          console.log(res)
          this.buildList = []
          if (res.result) {
            this.buildList = res.result
          }
        })
    },
    saveStrategy() {
      this.$refs.formItem.validate(valid => {
        this.formItem.reportTime = this.formItem.reportTime + ' 00:00:00'

        // this.formItem.readData = this.formItem.readData*1000
        this.$http
          .post(this.$api.addReadingData, {
            readData: this.formItem.readData * 1000,
            reportTime: this.formItem.reportTime,
            buildId: this.formItem.buildId,
            unitId: this.formItem.unitId,
            deviceType: this.formItem.deviceType
          })
          .then(res => {
            this.dialogVisible = false
            this.$message.success('记录成功')
            this.getWaterDeviceList()
          })
      })
    },
    onAdd() {
      this.formItem = {
        deviceType: '水量计'
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.formItem.clearValidate('reportTime')
        this.$refs.formItem.clearValidate('unitId')
        this.$refs.formItem.clearValidate('buildId')
      })
    },

    setSelect(val) {
      console.log(val)
      this.selectBuildId = val
      this.buildType = val
    },
    //水表设备列表
    getWaterDeviceList(obj) {
      let that = this
      this.$http
        .get(this.$api.waterDeviceList, {
          ...obj,
          deviceType: '水量计',
          current: that.pager.currentPage,
          size: that.pager.pageSize
        })
        .then(res => {
          if (res.data) {
            let arr = res.data.records
            if (arr) {
              arr.map(item => {
                if (item.online == 1) {
                  item.online = '在线'
                } else {
                  item.online = '离线'
                }
                item.buildType = tranBuildType(item.buildType)
                item.amount = numberTran(item.amount)
              })
              this.tableData = arr
              this.pager.total = res.data.total
            }
          } else {
            this.tableData = []
            this.pager.total = 0
          }
          this.loading = false
          this.hardReset = true
        })
    },
    //列表导出
    exportWaterList(obj) {
      // let urls = store.state.baseUrl+this.$api.exportWaterList +'?deviceType=水量计&current=-1&size=-1&farmId=' + store.state.farmId
      // let buildType = ""
      // let buildName = ""
      // let online = ""
      // let deviceName = ""
      // if (obj.buildType){
      //   buildType = obj.buildType
      //   urls = urls + '&buildType=' + buildType
      // }
      // if (obj.buildName){
      //   buildName = obj.buildName
      //   urls = urls + '&buildName=' + buildName
      // }
      // if (obj.online){
      //   online = obj.online
      //   urls = urls + '&online=' + online
      // }
      // if (obj.deviceName){
      //   deviceName = obj.deviceName
      //   urls = urls + '&deviceName=' + deviceName
      // }
      // window.location.href = urls
      axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token')
      let headers = {
        'x-auth-token': localStorage.getItem('token')
      }
      getDownload(
        this.$api.exportWaterList,
        {
          farmId: store.state.farmId,
          ...obj
        },
        headers
      ).then(res => {
        let blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        // downloadElement.download = decodeURIComponent(res.headers.filename); //下载后文件名
        downloadElement.download = `水表设备列表.xlsx` //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放blob对象
      })
    },
    //水表统计
    getStatusCount(obj) {
      let that = this
      this.$http
        .get(this.$api.getStatusCount, {
          ...obj,
          deviceType: '水量计'
        })
        .then(res => {
          if (res.data) {
            that.offlineDevice = res.data.offline
            that.onlineDevice = res.data.online
            that.totalDevice = res.data.total
          }
        })
    },
    //栋舍列表
    getAllPigpen(val) {
      let arr = []
      this.formOptions[1].options = []
      this.$http
        .get(this.$api.getAllPigpen, {
          deviceType: '水量计',
          // buildType:val,
          buildingId: this.selectBuildId
        })
        .then(res => {
          if (res.data) {
            res.data.map(item => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
            this.formOptions[1].options = arr
          }
        })
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },
    changeTop(val) {
      this.$http.patch(this.$api.changeTop(val), {}).then(res => {})
    },
    cancelTop(val) {
      this.$http.patch(this.$api.cancelTop(val), {}).then(res => {})
    },
    changeStatistics(val,id) {
      const param = {
        id,
        notCount: 1
      }
      this.$http.post(this.$api.setNotCount, param).then(res => {})
    },
    cancelStatistics(val,id) {
      const param = {
        id,
        notCount: 0
      }
      this.$http.post(this.$api.setNotCount, param).then(res => {})
    },
    tableBtnHandle(arg) {
      let passagewayId = arg[1].passagewayId
      this.$router.push({
        path: 'seo',
        query: {
          passagewayId: passagewayId,
          buildType: arg[1].buildType,
          buildName: arg[1].buildName,
          deviceName: arg[1].deviceName,
          type: arg[1].deviceType
        }
      })
      localStorage.setItem('routename', 'seo')
      localStorage.setItem('routetarget', 'seo')
      this.$store.commit('selectMenu', {
        router: 'setting/seo',
        name: '综合查询',
        target: 'seo'
      })
    },
    onSearch(val) {
      this.$set(this.pager, 'currentPage', 1)
      this.hardReset = false
      this.obj = val
      this.loadTable(val)
    },
    //重载表格
    loadTable(val) {
      this.hardReset = false
      this.loading = true
      this.getWaterDeviceList(val)
      this.getStatusCount(val)
    },

    onExport(val) {
      this.obj = val
      this.loadTable(val)
      this.exportWaterList(val)
    },
    //单元类型列表
    getBuildType() {
      let arr = []
      this.$http
        .get(this.$api.getBuildList, {
          deviceType: '水量计'
        })
        .then(res => {
          console.log(res)
          if (res.data) {
            let list = Array.from(new Set(res.data))
            list.map(item => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
          }
        })
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
  height: calc(100vh - 327px) !important;
  overflow-y: auto !important;
}
.title {
  margin-bottom: 10px;
  color: #606266;
}
</style>
