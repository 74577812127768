<template lang="">
     <div class="container01">
    <div class="box-wrapper">
      <el-tabs v-model="activeName" class="tab-box">
        <el-tab-pane label="商品信息" name="first">
          <div v-if="showGoodsList" class="PageList">
            <el-button type="primary" @click="switchView">
              新增商品
            </el-button>
            <!-- 商品列表 -->
            <GoodsListView @editGoods="editGoods"></GoodsListView>
          </div>
          <div v-if="!showGoodsList" class="add-category">
            <el-button type="primary" @click="switchView">
              返回列表
            </el-button>
            <!-- 商品信息增加或者修改 -->
            <GoodsView :modifyGoodsId="modifyGoodsId"></GoodsView>
          </div>
        </el-tab-pane>
        <el-tab-pane label="商品规格" name="third">
          <h1>ccc</h1>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import GoodsListView from './component/GoodsListView'
import GoodsView from './component/GoodsView'
export default {
  name: 'Goods',
  components: {
    GoodsListView,
    GoodsView
  },
  data() {
    return {
      activeName: 'first',
      showGoodsList: true,
      modifyGoodsId: null
    }
  },
  methods: {
    switchView() {
      this.showGoodsList = !this.showGoodsList
      this.modifyGoodsId = null
    },
    editGoods(goodsid) {
      this.modifyGoodsId = goodsid
      this.showGoodsList = !this.showGoodsList
    }
  }
}
</script>
<style lang="scss" scoped>
    .container01 {
    padding: 8px;
    min-height: 100vh;
    background-color: rgb(240, 242, 245);
    .box-wrapper {
      background-color: #fff;
      border-radius: 3px;
      padding: 0px 10px;
    }
  }

</style>
