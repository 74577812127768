<template>
  <div>
    监控全景
  </div>
</template>

<script>
export default {
  name: "panorama"
}
</script>

<style scoped>

</style>
