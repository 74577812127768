<template>
  <!--  style='min-width: 1100px'-->
  <div>
    <!--    v-loading='loading'-->
    <el-row style='overflow-y: hidden'>
      <el-col :span='12' class='fullHeight minWidth'>
        <el-card class='box'>
          <div slot='header'>

            <el-form ref='form' :model='form' label-width='80px'>
              <el-form-item class='checkbox' label='设备类型'>
                  <el-checkbox-group v-model='form.deviceTypes'>
                    <el-checkbox class='checkbox' label='水量计' v-if="water" name='type'></el-checkbox>
                    <el-checkbox class='checkbox' label='电表' v-if="ele" name='type'></el-checkbox>
                    <el-checkbox class='checkbox' label='料塔' v-if="food" name='type'></el-checkbox>
                    <el-checkbox class='checkbox' label='室内温' v-if="snw" name='type'></el-checkbox>
                    <el-checkbox class='checkbox' label='室外温' v-if="sww"  name='type'></el-checkbox>
                    <el-checkbox class='checkbox' label='湿度' v-if="sd" name='type'></el-checkbox>
                    <el-checkbox class='checkbox' label='氨气浓度' v-if="aq" name='type'></el-checkbox>
                    <el-checkbox class='checkbox' label='水帘' v-if="sl" name='type'></el-checkbox>
                    <el-checkbox class='checkbox' label='定速风机' v-if="fan" name='type'></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              <el-form-item label='单元类型' class='select'>
                <el-select v-model='form.buildType' size='small' clearable placeholder='请选择单元类型'
                           @change='changeBuildType'>
                  <el-option :label='item.name' :value='item.id' :key='index.id'
                             v-for='(item,index) in buildTypeList'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label='单元/区' class='select' style="margin-right:20px;">
                <el-select v-model='form.buildName' size='small' clearable placeholder='请选择单元/区'>
                  <el-option :label='item.name' :value='item.name' :key='index'
                             v-for='(item,index) in buildNameList'></el-option>
                </el-select>
              </el-form-item>

              <el-form-item class='check' label='筛选标签' v-if='tag'>
                <el-tag closable v-if='tag' @close='closeTag'>
                  {{ tag }}
                </el-tag>
              </el-form-item>
              <div style='display:inline-block;margin:6px auto 9px;text-align: center' >
                <el-button type='default' @click='resetDevicePage'
                           size='small'>重置
                </el-button>
                <el-button style='margin-left: 10px' type='primary' @click='onSubmitDevicePage()'
                           size='small'>搜索
                </el-button>
              </div>

            </el-form>
          </div>
          <div v-if='!tag' style='margin-top: 3px;color: #606266;margin-left:12px'>共 <span
            style='color:black;font-size: 18px'>{{ total }} </span>条，
            合计在线 <span style='color:black;font-size: 18px'>{{ online }}</span>，
            离线<span style='color:red;font-size: 18px' @click='offlineSearch'> {{ offline }}</span>
          </div>
          <!--          <div style='margin-bottom: 10px' v-if='!obj.passagewayId'>设备详情</div>-->
          <el-table
            ref='singleTable'
            :data='tableData'
            highlight-current-row
            :height="windowHeight"
            @current-change='rowClick'
            style='width: 100%'
            @row-click='rowClick'>
            <el-table-column
              property='deviceName'
              label='设备名称'
              show-overflow-tooltip
              align='left'>
              <template slot-scope='scope'>
                <span style='color:#606266'>{{ scope.row.deviceName }}
                <el-tag
                  v-if='!scope.row.online' type='warning' size='mini'
                  disable-transitions>离线</el-tag>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              property='deviceType'
              label='设备类型'
              align='left'
            >
            </el-table-column>
            <el-table-column
              property='name'
              label='通道名称'
              align='left'>
            </el-table-column>
            <el-table-column
              property='type'
              label='通道类型'
              align='left'
              width='90'
            >
            </el-table-column>
            <el-table-column
              property='value'
              label='最近表读数'
              align='right'>
            </el-table-column>
          </el-table>
          <el-pagination
            style='text-align: right;'
            :page-sizes='[ 10, 20, 50 , 200]'
            @size-change='handleSizeChange'
            @current-change='handleCurrentChange'
            :current-page.sync='pager.currentPage'
            :page-size.sync='pager.pageSize'
            layout='total, prev, pager, next, sizes'
            :total='pager.total'
          ></el-pagination>
          <!--          <page-table-->
          <!--            :tableHeight='555'-->
          <!--            :tableData='tableData'-->
          <!--            :tableLabel='tableLabel'-->
          <!--            :tableOption='tableOption'-->
          <!--            :toTop='toTop'-->
          <!--            :pager='pager'-->
          <!--            highlight-current-row-->
          <!--            ref='singleTable'-->
          <!--            @pagination='handleCurrentChange'-->
          <!--            @handleSizeChange='handleSizeChange'-->
          <!--            @rowClick='rowClick'-->
          <!--          ></page-table>-->

        </el-card>
      </el-col>
      <el-col :span='12' class='fullHeight minWidth2'>
        <el-card class='box-card'>
          <div slot='header'>
            <div>
              <el-form ref='form' :model='formRight' label-width='80px'>
                <el-form-item class='check' label='时间区间'>
                  <el-date-picker
                    v-model='formRight.data'
                    type='daterange'
                    align='right'
                    size='small'
                    unlink-panels
                    :clearable='false'
                    range-separator='至'
                    start-placeholder='开始日期'
                    end-placeholder='结束日期'
                    @change='changeDate'
                    :picker-options='pickerOptions'>
                  </el-date-picker>
                </el-form-item>
                <el-form-item class='btn'>
                  <el-button type='primary' @click='onSubmit("form")' size='small'>搜索</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div v-if='selectObj.type=="重量设备" && startTime2.substring(0,10) === endTime2.substring(0,10)' style='height: 50px'>
            <el-tooltip class='item' effect='dark' placement='top-start'>
              <template slot='content'>{{ selectObj.deviceName }}-{{ selectObj.name }},{{selectObj.type}}</template>
              <span class='word97'>{{ selectObj.deviceName }}-{{ selectObj.name }}<span style='color: #2F7ECF'> 余料</span></span>
            </el-tooltip>
            <span v-if='isTime' style='float: right;line-height: 40px;'>
              <span style='font-weight: bold'>{{ startTime2 }} ~ {{ endTime2.substring(11) }}</span>
              &nbsp;&nbsp;&nbsp;
<!--              <span style='color: #2C36C3;cursor: pointer;text-decoration: underline;' @click='getFeedTimeData()'-->
<!--                    v-if='end2.substring(11) != "23:59:59"'>返回</span>-->
              <el-button
                @click="getFeedTimeData(startTime2.substring(0,11)+'00:00:00',endTime2.substring(0,11)+'23:59:59')"
                type="text">返回</el-button>
            </span>
            <span v-else style='float: right;line-height: 40px;'>
              <el-button @click="switchoverDay(-1)" type="text" style='margin-right: 10px;'>上一天</el-button>
              <span style='font-weight: bold'>{{ startTime2.substring(0,10) }}</span>
              <el-button @click="switchoverDay(1)" type="text" style='margin-left: 10px;'>下一天</el-button>
            </span>
          </div>
          <div v-if='selectObj.type=="重量设备" && startTime2.substring(0,10) === endTime2.substring(0,10)' style='width: 100%;'>
            <v-chart v-if='isTime' :options='options7'
                     style=' width: 100%;height:380px' ref='splashes' />
            <v-chart v-else
                     :options='options6' @click='splashesClick($event)'
                     style=' width: 100%;height:380px' ref='splashes' />
<!--            <v-chart v-else :options='options5' @click='splashesClick($event)'-->
<!--                     style=' width: 100%;height:380px' ref='splashes' />-->
          </div>
          <div style='margin-top:16px;height: 50px'>
            <el-tooltip class='item' effect='dark' placement='top-start'>
              <template slot='content'>{{ selectObj.deviceName }}-{{ selectObj.name }},{{selectObj.type === '重量设备'?'耗料':selectObj.type}}</template>
              <span class='word97'>{{ selectObj.deviceName }}-{{ selectObj.name }}
                <span style='color: #2F7ECF'> {{selectObj.type === '重量设备'?'来耗料':selectObj.type}}</span>
              </span>
            </el-tooltip>
            <!--            <span class='word97'>{{ selectObj.deviceName }}-{{ selectObj.name }}</span>-->

            <el-form style='display: inline-block;float: right' label-width='0px'>
              <el-form-item label=''>
                <el-radio-group size='small' v-model='radio'>
                  <el-radio-button label='按小时' :disabled='isHour'></el-radio-button>
                  <el-radio-button label='按天' :disabled='isDay'></el-radio-button>
                  <el-radio-button label='按周' :disabled='isWeek'></el-radio-button>
                  <el-radio-button label='按月' :disabled='isMonth'></el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div style='width:100%;'>
            <!--            border-top:1px solid #EBEEF5;-->
            <v-chart v-if='selectObj.type=="水量计"||selectObj.type=="电表"' :options='options'
                     style=' width: 100%;height:380px' ref='ele' />
            <v-chart ref='foot' v-if='selectObj.type=="重量设备"' :options='options1'
                     style='width:100%;height:380px' />
            <v-chart ref='foot' v-if='selectObj.type=="重量设备"' :options='options8'
                     style='width:100%;height:380px' />
<!--            <v-chart ref='foot' v-if='selectObj.type=="重量设备"' :options='copyOptions1'-->
<!--                     style='width:100%;height:380px' />-->
            <v-chart ref='seo'
                     v-if='(selectObj.type=="氨气浓度"||selectObj.type=="室内温"||selectObj.type=="室外温"||selectObj.type=="湿度")&&queryType==0'
                     :options='options2' style='width:100%;height:380px' />
            <v-chart ref='seo2'
                     v-if='(selectObj.type=="氨气浓度"||selectObj.type=="室内温"||selectObj.type=="室外温"||selectObj.type=="湿度")&&queryType!=0'
                     :options='options3' style=' width: 100%;height:380px' />
            <v-chart ref='other'
                     v-if='(selectObj.type=="水帘"||selectObj.type=="定速风机")'
                     :options='options4' style=' width: 100%;height:380px' />
          </div>
          <div style='margin:16px 0 16px;height: 50px;display: flex;align-items: flex-start'>
            <div style='display: flex;flex-direction:column;'>
              <span class='detail-title' v-if='selectObj.type=="水量计"'>{{ selectObj.deviceName }} 用水详情</span>
              <span class='detail-title' v-if='selectObj.type=="水帘"||selectObj.type=="定速风机"'>{{ selectObj.name }} 详情</span>
              <span class='detail-title' v-if='selectObj.type=="电表"'>{{ selectObj.deviceName }} 用电详情</span>
              <span class='detail-title' v-if='selectObj.type=="重量设备"'>{{ selectObj.deviceName }} 耗/来料详情</span>
              <span class='detail-title' v-if='selectObj.type=="室内温"||selectObj.type=="室外温"'>{{ selectObj.deviceName }}
                <span style='color: #2F99F3'>{{ selectObj.name }}</span>
                温度详情
              </span>
              <div>
                 <span style='display: inline-block;margin-left: 0px;line-height: 40px;width: 60px;font-size: 14px;color: #909399'
                     v-if='selectObj.type=="重量设备"'>
                  <i
                  style='display: inline-block;width: 13px;height: 13px;background-color: #2F99F3;margin-right: 2px;border-radius: 4px'></i>
                  耗料
                </span>
                <span style='display: inline-block;margin-left: 12px;line-height: 40px;width: 60px;font-size: 14px;color: #909399'
                      v-if='selectObj.type=="重量设备"'>
                <i
                  style='display: inline-block;width: 13px;height: 13px;background-color: #E19D5F;margin-right: 2px;border-radius: 4px'></i>
                  来料
                </span>
              </div>

              <!--            <span style='display: inline-block;margin-left: 20px'-->
              <!--                  v-if='(selectObj.type=="室内温"||selectObj.type=="室外温")&&queryType!=0'>-->
              <!--              <i style='display: inline-block;width: 10px;height: 10px;background-color: #c23531;margin-right: 5px'></i>-->
              <!--              最高温-->
              <!--            </span>-->
              <span  style='display: inline-block;margin-left: 0px;line-height: 40px;font-size: 14px;color: #909399'
                    v-if='(selectObj.type=="室内温"||selectObj.type=="室外温")&&queryType!=0'>
              <i style='display: inline-block;width: 13px;height: 13px;background-color: #2f4554;margin-right: 2px;border-radius: 4px'></i>
              平均温度
            </span>
              <!--            <span style='display: inline-block;margin-left: 15px'-->
              <!--                  v-if='(selectObj.type=="室内温"||selectObj.type=="室外温")&&queryType!=0'>-->
              <!--              <i style='display: inline-block;width: 10px;height: 10px;background-color: #61a0a8;margin-right: 5px'></i>-->
              <!--              最低温-->
              <!--            </span>-->
              <span style='display: inline-block;margin-left: 20px'
                    v-if='(selectObj.type=="氨气浓度"||selectObj.type=="湿度")&&queryType!=0'>
              <i style='display: inline-block;width: 10px;height: 10px;background-color: #c23531;margin-right: 5px'></i>
              最高
            </span>
              <span style='display: inline-block;margin-left: 15px'
                    v-if='(selectObj.type=="氨气浓度"||selectObj.type=="湿度")&&queryType!=0'>
              <i style='display: inline-block;width: 10px;height: 10px;background-color: #2f4554;margin-right: 5px'></i>
              平均值
            </span>
              <span style='display: inline-block;margin-left: 15px'
                    v-if='(selectObj.type=="氨气浓度"||selectObj.type=="湿度")&&queryType!=0'>
              <i style='display: inline-block;width: 10px;height: 10px;background-color: #61a0a8;margin-right: 5px'></i>
              最低值
            </span>
            </div>
            <el-form style='display: inline-block;margin-left: auto' label-width='80px'>
              <el-form-item class='select'>
                <el-select v-model='selectDate' size='small' @change='changeGirdDate'>
                  <el-option v-for='(item,index) in dataSelectList' :label='item' :value='item'
                             :key='index'></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <el-row v-if='selectObj.type=="水量计"||selectObj.type=="电表"||selectObj.type=="水帘"||selectObj.type=="定速风机"'>
            <el-col :span='4' v-for='(item,index)  in girdData' :key='index'>
              <div class='grid-content bg-purple' style='text-align: center;height: 73px'>
                <span style='height: 50px;width:100%;display: inline-block;line-height: 50px'>
                  {{ item.yvalue ? item.yvalue : '' }}
                    <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="水量计"' style='font-size: 10px'>m³</i>
                    <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="电表"' style='font-size: 10px'>度</i>
                </span>
                <span style='color: #989ca1;' v-if='queryType==0'>{{ item.xtime.substring(5, 11) }}</span>
                <span style='color: #989ca1;' v-if='queryType==1'>{{ item.xtime.substring(8, 10) }}</span>
                <span style='color: #989ca1;' v-if='queryType==2'>{{ item.xtime.substring(5, 8) }}周</span>
                <span style='color: #989ca1;' v-if='queryType==3'>{{ item.xtime.substring(5, 7) }}月</span>
              </div>
            </el-col>
          </el-row>
          <el-row
            v-if='(selectObj.type=="室内温"||selectObj.type=="室外温"||selectObj.type=="氨气浓度"||selectObj.type=="湿度")&&queryType==0'>
            <el-col :span='4' v-for='(item,index)  in girdData' :key='index'>
              <div class='grid-content bg-purple' style='text-align: center'>
                <span
                  style='height: 50px;width:100%;display: inline-block;line-height: 50px'>
                  {{ item.yvalue ? item.yvalue : '' }}
                  <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="室内温"' style='font-size: 10px'>℃</i>
                  <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="室外温"' style='font-size: 10px'>℃</i>
                  <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="氨气浓度"' style='font-size: 10px'>ppm</i>
                  <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="湿度"' style='font-size: 10px'>%RH</i>
                </span>
                <span style='color: #989ca1;' v-if='queryType==0'>{{ item.xtime.substring(5, 11) }}</span>
                <span style='color: #989ca1;' v-if='queryType==1'>{{ item.xtime.substring(8, 10) }}</span>
                <span style='color: #989ca1;' v-if='queryType==2'>{{ item.xtime.substring(5, 8) }}周</span>
                <span style='color: #989ca1;' v-if='queryType==3'>{{ item.xtime.substring(5, 7) }}月</span>
              </div>
            </el-col>
          </el-row>
          <el-row v-if='selectObj.type=="重量设备"'>
            <el-col :span='4' v-for='(item,index)  in girdData' :key='index'>
              <div class='grid-content bg-purple' style='text-align: center'>
                <div style='height: 50px;display: flex;flex-direction:column;justify-content:center '>
                     <span class='grid-box' style='color: #2F99F3;font-size: 14px;font-weight: 600' v-if='item.yvalue && item.yvalue != 0.0'>
                    {{ item.yvalue && item.yvalue != 0.0 ? item.yvalue : '' }}
                    <i v-if='item.yvalue && item.yvalue != 0.0' style='font-size: 8px'>kg</i></span>
                    <span class='grid-box' style='color: #E19D5F;font-size: 14px;font-weight: 600' v-if='item.yvalue2 && item.yvalue2 != 0.0'>
                    {{ item.yvalue2 && item.yvalue2 != 0.0 ? item.yvalue2 : '' }}
                    <i v-if='item.yvalue2 && item.yvalue2 != 0.0' style='font-size: 8px'>kg</i></span>
                </div>
                <span style='color: #989ca1;' v-if='queryType==0'>{{ item.xtime.substring(5, 11) }}</span>
                <span style='color: #989ca1;' v-if='queryType==1'>{{ item.xtime.substring(8, 10) }}</span>
                <span style='color: #989ca1;' v-if='queryType==2'>{{ item.xtime.substring(5, 8) }}周</span>
                <span style='color: #989ca1;' v-if='queryType==3'>{{ item.xtime.substring(5, 7) }}月</span>
              </div>
            </el-col>
          </el-row>
          <el-row
            v-if='(selectObj.type=="室内温"||selectObj.type=="室外温"||selectObj.type=="氨气浓度"||selectObj.type=="湿度")&&queryType!=0'>
            <el-col :span='4' v-for='(item,index)  in girdData' :key='index'>
              <div class='grid-content bg-purple' style='text-align: center'>
                <el-tooltip effect='light' popper-class='classTip' placement='top'>
                  <template slot='content'>
                    <div style='color: #c23531'>最高:{{ item.yvalue ? item.yvalue : '' }}</div>
                    <div style='color: #2f4554'>平均:{{ item.yvalue3 ? Math.floor(item.yvalue3 * 10) / 10 : '' }}</div>
                    <div style='color: #61a0a8'>最低:{{ item.yvalue2 ? item.yvalue2 : '' }}</div>
                  </template>
                  <div style='height: 50px;line-height: 50px'>
                    <!--                  <span class='grid-box2' style='color: #c23531'>{{ item.yvalue }}</span>-->
                    <!--                  <span class='grid-box2' style='color: #2f4554'>{{ item.yvalue3 ? Math.floor(item.yvalue3 * 10) / 10 : '' }}</span>-->
                    <span class='grid-box2'
                          style='color: #2f4554'>{{ item.yvalue3 != null ? Math.floor(item.yvalue3 * 10) / 10 : '' }}
                      <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="室内温"' style='font-size: 10px'>℃</i>
                      <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="室外温"' style='font-size: 10px'>℃</i>
                      <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="氨气浓度"' style='font-size: 10px'>ppm</i>
                      <i v-if='item.yvalue && item.yvalue != 0.0&&selectObj.type=="湿度"' style='font-size: 10px'>%RH</i>
                    </span>
                  </div>
                </el-tooltip>

                <span style='color: #989ca1;' v-if='queryType==0'>{{ item.xtime.substring(5, 11) }}</span>
                <span style='color: #989ca1;' v-if='queryType==1'>{{ item.xtime.substring(8, 10) }}</span>
                <span style='color: #989ca1;' v-if='queryType==2'>{{ item.xtime.substring(5, 8) }}周</span>
                <span style='color: #989ca1;' v-if='queryType==3'>{{ item.xtime.substring(5, 7) }}月</span>
              </div>
            </el-col>
          </el-row>
<!--          <div v-if='selectObj.type=="重量设备"' style='margin-top: 20px'>-->
<!--            <span class='detail-title'>{{ selectObj.deviceName }} 料塔事件</span>-->
<!--            <el-select style='float: right;' v-model='eventName' size='small' clearable placeholder='请选择事件名称'-->
<!--                       @change='changeEventName'>-->
<!--              <el-option :label='item.dictValue' :value='item.dictValue' :key='index.dictValue'-->
<!--                         v-for='(item,index) in eventNameList'></el-option>-->
<!--            </el-select>-->
<!--            <el-table-->
<!--            :data='eventTableData'-->
<!--            :height="windowHeight"-->
<!--            style='width: 100%;margin-top: 10px'>-->
<!--            <el-table-column-->
<!--              property='eventName'-->
<!--              label='事件名称'-->
<!--              width='80'-->
<!--              align='left'>-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--              property='startTime'-->
<!--              label='开始时间'-->
<!--              width='150'-->
<!--              align='left'-->
<!--            >-->
<!--              <template slot-scope='scope'>-->
<!--                <span style='color:#606266'>{{ formattedDate(scope.row.startTime) }}-->
<!--                </span>-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--              property='endTime'-->
<!--              label='结束时间'-->
<!--              width='150'-->
<!--              align='left'>-->
<!--              <template slot-scope='scope'>-->
<!--                <span style='color:#606266'>{{ formattedDate(scope.row.endTime) }}-->
<!--                </span>-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--              property='speed'-->
<!--              label='速度(kg/min)'-->
<!--              align='left'-->
<!--            >-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--              property='remark'-->
<!--              label='备注'-->
<!--              align='left'>-->
<!--            </el-table-column>-->
<!--          </el-table>-->
<!--            <el-pagination-->
<!--              style='text-align: right;'-->
<!--              :page-sizes='[ 10, 20, 50 , 200]'-->
<!--              @size-change='handleSizeChange2'-->
<!--              @current-change='handleCurrentChange2'-->
<!--              :current-page.sync='eventPager.currentPage'-->
<!--              :page-size.sync='eventPager.pageSize'-->
<!--              layout='total, prev, pager, next, sizes'-->
<!--              :total='eventPager.total'-->
<!--            ></el-pagination>-->
<!--          </div>-->
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
import login from '../../api/user/login'
import { numberTran, pickerOptionsRange, tranBuildType } from '../../libs/tools'
import moment from 'moment'
import Template from './template'
import { number } from 'echarts/lib/export'


export default {
  name: 'seo',
  components: {
    Template,
    pageTable,
    searchForm
  },
  data() {
    return {
      pickerMinDate: '',
      eventName: '',
      passType: [],
      eventTableData: [],
      eventNameList: [],
      eventPager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      },
      loading: true,
      water: false,
      ele: false,
      food: false,
      snw: false,
      sww: false,
      sd: false,
      fan: false,
      aq: false,
      sl: false,
      buildTypeList: [],
      buildNameList: [],
      total: 0,
      offline: 0,
      online: 0,
      obj: {},
      startTime: moment().format('yyyy-MM-DD') + ' 00:00:00',
      endTime: moment(new Date()).format('yyyy-MM-DD') + ' 23:59:59',
      startTime2: moment().format('yyyy-MM-DD') + ' 00:00:00',
      endTime2: moment(new Date()).format('yyyy-MM-DD') + ' 23:59:59',
      isTime: false,
      queryType: 0,
      day: 1,
      isHour: false,
      isDay: true,
      isWeek: true,
      isMonth: true,
      radio: '按小时',
      selectObj: {
        type: ''
      },
      selectDate: '',
      dataSelectList: [],
      allData: [],
      girdData: [],
      tipData: [],
      startData: [],
      endData: [],
      form: {
        buildType: '',
        buildName: '',
        deviceTypes: []
      },
      formRight: {
        data: [new Date(), new Date()]
      },

      tableData: [],
      tableLabel: [
        { label: '设备名称', param: 'deviceName', tag: 1, align: 'left' },
        { label: '设备类型', param: 'deviceType' },
        { label: '通道名称', param: 'name' },
        { label: '通道类型', param: 'type', align: 'left' },
        { label: '最近表读数', param: 'value', align: 'right' }
      ],
      tableOption: {},
      toTop: {},
      pager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      },
      options6: {
        title: {
          left: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter(params) {
            // console.log(this)
          }
        },
        grid: {
          top: '6',
          left: '0',
          right: '20',
          bottom: '3%',
          show: true,
          containLabel: true
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            splitNumber: 12,
            splitLine: {
              show: false
            },
            axisLabel: {// 格式化数据只显示时间的小时
              formatter: function (value, index) {
                // let data = new Date(value)
                // let hours = Number(data.getHours()) > 9 ?data.getHours():'0'+data.getHours()
                // return hours + ':00'
                let data = new Date(value);
                let hours = data.getHours();
                let minutes = data.getMinutes();
                // 判断是否为整点或者数据较少时显示时间
                if (minutes === 0) {
                  return (Number(hours) > 9 ?hours : '0'+ hours) + ':00';
                } else {
                  return (Number(hours) > 9 ?hours : '0'+ hours) + ':' + (Number(minutes) > 9 ?minutes : '0'+ minutes);
                }
              }
            },
            // type: 'category',
            type: 'time',
            data: [],
            // axisTick: {
            //   alignWithLabel: true
            // }
          }
        ],
        yAxis: [
          {
            // scale: true, //自适应
            // minInterval : 1,
            min: (value) => {
              let num = 10 ** (value.min.toString().length - 2)
              return Math.floor(value.min / num) * num;
            },
            max: (value) => {
              let num = 10 ** (value.max.toString().length - 2)
              return Math.ceil(value.max / num) * num;
            },
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            type: 'value'
          }
        ],
        series: [
          {
            type: 'scatter',
            color: '#2f7ecf',
            symbolSize: 5,
            data: []
          }
        ]
      },
      options7: {
        title: {
          left: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter(params) {
            // console.log(this)
          }
        },
        grid: {
          top: '6',
          left: '0',
          right: '20',
          bottom: '3%',
          show: true,
          containLabel: true
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {// 格式化数据只显示时间的小时
              formatter: function (value, index) {
                return value.substring(11)
              }
            },
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            // scale: true, //自适应
            // minInterval : 1,
            min: (value) => {
              let num = 10 ** (value.min.toString().length - 2)
              return Math.floor(value.min / num) * num;
            },
            max: (value) => {
              let num = 10 ** (value.max.toString().length - 2)
              return Math.ceil(value.max / num) * num;
            },
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            type: 'value'
          }
        ],
        series: [
          {
            type: 'scatter',
            color: '#2f7ecf',
            symbolSize: 5,
            data: []
          }
        ]
      },
      options5: {
        title: {
          left: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter(params) {
            // console.log(this)
          }
        },
        grid: {
          top: '6',
          left: '0',
          right: '20',
          bottom: '3%',
          show: true,
          containLabel: true
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            interval: 24 * 60 * 60 * 1000, // 固定x轴时间间隔 间隔24小时，也就是一天
            splitNumber: 12,
            splitLine: {
              show: false
            },
            axisLabel: {// 格式化数据只显示时间的小时
              formatter (value, index) {

              }
            },
            // type: 'category',
            type: 'time',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            // scale: true, //自适应
            // minInterval : 1,
            min: (value) => {
              let num = 10 ** (value.min.toString().length - 2)
              return Math.floor(value.min / num) * num;
            },
            max: (value) => {
              let num = 10 ** (value.max.toString().length - 2)
              return Math.ceil(value.max / num) * num;
            },
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            type: 'value'
          }
        ],
        series: [
          // {
          //   name: '',
          //   type: 'line',
          //   data: [],//空数据
          // },
          {
            type: 'scatter',
            color: '#2f7ecf',
            symbolSize: 5,
            data: []
          }
        ]
      },
      options: {
        title: {
          left: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter(params) {
            // console.log(this)
          }
        },
        grid: {
          top: '6',
          left: '0',
          right: '0',
          bottom: '3%',
          show: true,
          containLabel: true
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                // color: '#e7eaed'
                color: '#848484'
              }
            },
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '80%',
            color: '#2f7ecf',
            data: []
          }
        ]
      },
      options1: {
        title: {
          left: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter(params) {
            // console.log(params)
          }
        },
        grid: {
          top: '25 ',
          left: '0',
          right: '0',
          bottom: '3%',
          show: true,
          containLabel: true
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        legend: {
          center: 0,
        },
        yAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            type: 'value'
          },
        ],
        series: [
          {
            name:'耗料',
            type: 'bar',
            barGap:'-10%',
            barWidth: '41%',
            color: '#2f7ecf',
            data: []
          },
        ]
      },
      options8: {
        title: {
          left: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter(params) {
            // console.log(params)
          }
        },
        grid: {
          top: '25 ',
          left: '0',
          right: '0',
          bottom: '3%',
          show: true,
          containLabel: true
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        legend: {
          center: 0,
        },
        yAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            type: 'value'
          }
        ],
        series: [
          {
            name:'来料',
            type: 'bar',
            barWidth: '41%',
            color: '#E19D5F',
            data: []
          }
        ]
      },
      copyOptions1: {
        title: {
          left: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter(params) {
            // console.log(params)
          }
        },
        grid: {
          top: '25 ',
          left: '0',
          right: '0',
          bottom: '3%',
          show: true,
          containLabel: true
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#848484'
              }
            },
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        legend: {
          center: 0,
          data: ['耗料', '来料']
        },
        yAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#2f7ecf'
              }
            },
            type: 'value'
          },
          {
            axisLine: {
              lineStyle: {
                color: '#E19D5F'
              }
            },
            type: 'value'
          }
        ],
        series: [
          {
            name:'耗料',
            type: 'bar',
            barGap:'-10%',
            barWidth: '41%',
            color: '#2f7ecf',
            data: []
          },
          {
            name:'来料',
            type: 'bar',
            yAxisIndex: 1,
            barWidth: '41%',
            color: '#E19D5F',
            data: []
          }
        ]
      },
      options2: {
        title: {
          right: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
          }
        },
        grid: {
          top: '6',
          left: '0',
          right: '0',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#848484'
            }
          },
          data: []
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#848484'
            }
          }
        },
        series: [{
          symbol: 'none',
          data: [],
          type: 'line'
        }]
      },
      options3: {
        title: {
          right: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            // console.log(params)
          }
        },
        legend: {
          center: 0,
          data: ['最高值', '平均值', '最低值']
        },
        grid: {
          top: '20',
          left: '0',
          right: '0',
          bottom: '3%',
          borderColor: '#e7eaed',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          axisLine: {
            lineStyle: {
              color: '#848484'
            }
          },
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              color: '#848484'
            }
          },
          type: 'value'
        },
        series: [
          {
            name: '最高值',
            type: 'line',
            symbol: 'none',
            data: [],
            lineStyle: {
              width: 1
            }
          },
          {
            name: '平均值',
            type: 'line',
            symbol: 'none',
            data: [],
            lineStyle: {
              width: 2
            }
          },
          {
            name: '最低值',
            type: 'line',
            symbol: 'none',
            data: [],
            lineStyle: {
              width: 1
            }
          }
        ]
      },
      options4: {
        title: {
          right: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            // console.log(params)
          }
        },
        legend: {
          data: ['Step Start']
        },
        grid: {
          top: '6',
          left: '0',
          right: '0',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          axisLine: {
            lineStyle: {
              color: '#848484'
            }
          },
          type: 'category',
          data: []
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              color: '#848484'
            },
            onZero: true
          },
          type: 'category',
          data: ['关闭', '开启'],
          min: 0,

          axisTick: {
            alignWithLabel: true
          }
        },
        series: [
          {
            name: '',
            type: 'line',
            symbol: 'none',
            step: 'start',
            data: []
          }
        ]
      },
      pickerOptions: {
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            const day1 = 1095 * 24 * 3600 * 1000
            let maxTime = ''
            let minTime = ''
            if (Date.now() - this.pickerMinDate > day1) {
              maxTime = this.pickerMinDate + day1
              minTime = this.pickerMinDate - day1
              return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now()
            } else {
              maxTime = Date.now()
              minTime = this.pickerMinDate - day1
              return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now()
            }
          } else {
            return time.getTime() > Date.now()
          }

        },
        onPick: (obj) => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date(new Date().toDateString())
              start.setTime(start.getTime())
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近十五天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      windowHeight:200
    }
  },
  computed: {
    passagewayId() {
      return this.$route.query.passagewayId
    },
    tag() {
      if (this.$route.query.passagewayId) {
        return this.$route.query.buildType + ' ' + this.$route.query.buildName + ' ' + this.$route.query.deviceName + ' ' + this.$route.query.type
      } else {
        return null
      }
    }
  },
  watch: {
    windowHeight(val){
      console.log("===")
      console.log(val)
    },
    tableData: function() {
      this.$refs.singleTable.setCurrentRow(this.tableData[0])
    },
    radio(newVal, oldVal) {
      let item = 0
      if (newVal == '按小时') {
        item = 0
      }
      if (newVal == '按天') {
        item = 1
      }
      if (newVal == '按周') {
        item = 2
      }
      if (newVal == '按月') {
        item = 3
      }
      this.queryType = item
      this.onSubmit()
    },
    passagewayId: {
      deep: true,
      immediate: true,
      handler(newVal,oldVal) {
        if (newVal!=null&&oldVal==null){
          this.getAllDeviceList()
        }
      }
    }
  },
  created(){
    window.fullHeight = document.documentElement.clientHeight;
    this.windowHeight = window.fullHeight-348;
    window.onresize=()=>{
      window.fullHeight = document.documentElement.clientHeight;
      if(document.documentElement.clientWidth<1500){
        this.windowHeight = window.fullHeight-428;
      }else{
        this.windowHeight = window.fullHeight-348;
      }

    }

  },
  mounted() {
    this.getAllDeviceStatus()
    this.getAllPigpen()
    this.getDeviceType()
    this.getAllBuildType()
    this.getAllDeviceList()
    this.getEventName()

    //echarts自适应窗口
    window.addEventListener('resize', this.resizeTheChart)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeTheChart)
  },
  methods: {
    //获取事件名称
    getEventName(){
      //暂时不用
      // this.$http.get(this.$api.SysDictItem, {
      //   dictType: 'feed_event_type',
      // }).then(res =>{
      //   this.eventNameList = res.result;
      // })
    },
    //格式化时间
    formattedDate(e) {
      return moment(e).format('YYYY-MM-DD HH:mm:ss')
    },
    getDeviceType(){
      this.$http.get(this.$api.getDeviceType, {
      }).then(res => {
        if (res.data) {
          this.passType = res.data
          this.passType.map(item=>{
            if (item.type=='水量计'){ this.water = true }
            if (item.type=='电表'){ this.ele = true }
            if (item.type=='重量设备'){ this.food = true }
            if (item.type=='室内温'){ this.snw = true }
            if (item.type=='室外温'){ this.sww = true }
            if (item.type=='湿度'){ this.sd = true }
            if (item.type=='氨气浓度'){ this.aq = true }
            if (item.type=='定速风机'){ this.fan = true }
            if (item.type=='水帘'){ this.sl = true }
          })
        }
      })
    },
    /**
     * echarts
     */
    resizeTheChart() {
      if (this.$refs.splashes) {
        this.$refs.splashes.resize()
      }
      if (this.$refs.foot) {
        this.$refs.foot.resize()
      }
      if (this.$refs.ele) {
        this.$refs.ele.resize()
      }
      if (this.$refs.seo) {
        this.$refs.seo.resize()
      }
      if (this.$refs.seo2) {
        this.$refs.seo2.resize()
      }
      if (this.$refs.other) {
        this.$refs.other.resize()
      }
    },
    /**
     * 业务逻辑处理
     */
    showDataOfOptions(yData2) {
      const that = this;
      let tip = function(params) {
        const index = params[0].dataIndex // 因为索引唯一，正好通过这个进行关联
        let res = ''
        if (that.tipData.length > 0) {
          res = `${that.tipData[index]}`
        } else {
          res = `${that.startData[index]} ~ ${that.endData[index]}` + '<br/>' + ` 第 ${params[0].axisValue} 周`
        }
        return res + '<br />' + (params[0].data ? params[0].data : '--')
      }
      let tip2 = function(params) {
        // console.log(params)
        let title = ''
        let index = 0
        let marker0 = ''
        let marker1 = ''
        let marker2 = ''

        let data0 = ''
        let data1 = ''
        let data2 = ''
        if (params.length>=1){
          index = params[0].dataIndex  // 因为索引唯一，正好通过这个进行关联
          marker0 = params[0].marker
          data0 = '最高值:' + (params[0].data != null ? params[0].data : '--')
        }
        if (params.length>=2){
          marker1 = params[1].marker
          data1 = '平均值:' + (params[1].data != null ? params[1].data : '--')
        }
        if (params.length>=3){
          marker2 = params[2].marker
          data2 = '最低值:' + (params[2].data != null ? params[2].data : '--')
        }
        if (that.tipData.length > 0) {
          title = `${that.tipData[index]}`
        } else {
          title = `${that.startData[index]} ~ ${that.endData[index]}` + '<br/>' + ` 第 ${params[0].axisValue} 周`
        }
        return title + '<br/>' + marker0 + data0 + '<br/>' + marker1 + data1 + '<br/>' + marker2 + data2
      }
      let tip3 = function(params) {
        // console.log(params)
        const index = params[0].dataIndex // 因为索引唯一，正好通过这个进行关联
        let res = ''
        let marker0 = ''
        let marker1 = ''
        let data0 = ''
        let data1 = ''
        if (params.length>=1){
          marker0 = params[0].marker
          // marker0 = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#2f7ecf;"></span>`
          if (params[0].data != null){
            // if (typeof params[0].data === 'object'){
            //   data0 = '耗料:' + params[0].data.value + ' kg' + '<br/>' + params[0].marker + '来料:' + yData2[index] + ' kg';
            // }else if (params[0].data!=0.0){
            //   data0 = '耗料:' + params[0].data + ' kg';
            // }else {
            //   data0 = '耗料:' + '--';
            // }
            data0 = params[0].seriesName + ':' + (params[0].data!=0.0 ? params[0].data : '--')
          }
        }
        if (params.length>1){
          marker1 = params[1].marker
          data1 = '来料:' + (params[1].data != null&&params[1].data!=0.0 ? params[1].data : '--')
        }
        if (that.tipData.length > 0) {
          res = `${that.tipData[index]}`
        } else {
          res = `${that.startData[index]} ~ ${that.endData[index]}` + '<br/>' + ` 第 ${params[0].axisValue} 周`
        }
        return res + '<br />' + marker0 + data0 + '<br/>' + marker1 + data1
      }
      this.options.tooltip.formatter = tip
      this.options1.tooltip.formatter = tip3
      this.options8.tooltip.formatter = tip3
      this.copyOptions1.tooltip.formatter = tip3
      this.options2.tooltip.formatter = tip
      this.options3.tooltip.formatter = tip2
      this.options4.tooltip.formatter = tip

    },
    closeTag() {
      this.onSubmitDevicePage()
    },
    offlineSearch(){
      this.onSubmitDevicePage(1)
    },
    changeGirdDate(val) {
      this.girdData = this.allData.filter(item =>
        item.xtime.includes(val)
      )
    },
    resetDevicePage(){
      this.form.buildType = ''
      this.form.buildName = ''
      this.form.deviceTypes = []
      this.pager.currentPage = 1
      this.$router.replace({ query: '' }).catch(res=>{

      })
      this.getAllDeviceList()
      this.getAllDeviceStatus()
    },
    onSubmitDevicePage(val) {
      this.pager.currentPage = 1
      this.$router.replace({ query: '' }).catch(res=>{

      })

      let obj = {
        deviceTypes: this.form.deviceTypes.join(','),
        buildType: this.form.buildType,
        buildName: this.form.buildName
      }
      if (val){
        obj.online = 0
      }
      this.obj = obj
      this.getAllDeviceList(obj)
      this.getAllDeviceStatus(obj)
    },
    onSubmit() {
      this.loading = true
      this.dataSelectList = []
      this.selectDate = undefined
      this.getDataQuery()
      this.getFeedTimeData()
      this.getFeedEvent()
    },
    changeEventName(e){
      this.getFeedEvent();
    },
    //暂时不用
    getFeedEvent(){
      // this.$http.get(this.$api.getFeedEvent, {
      //   passId: this.selectObj.id,
      //   eventName: this.eventName,
      //   startTime: moment(new Date(this.startTime)).format('yyyy-MM-DD'),
      //   endTime: moment(new Date(this.endTime)).format('yyyy-MM-DD'),
      //   current: this.eventPager.currentPage,
      //   size: this.eventPager.pageSize,
      // }).then(res =>{
      //   this.eventTableData = res.result.records;
      //   this.eventPager.total = res.result.total;
      // })
    },
    splashesClick(e){
      // let time = e.data[0].substring(0,10).split('/').join('-');
      // let hour = e.data[0].substring(11,13);
      // let startTime = time + ' ' + hour + ':00:00';
      // let endTime = '';
      // if (hour == '23'){
      //   endTime = time + ' 00:00:00'
      // }else {
      //   let num = Number(hour);
      //   let endHour = num > 9?num + 1: '0' + (num + 1)
      //   endTime = time + ' ' + endHour + ':00:00';
      // }
      let time = this.getBeforeHalfHour(e.data[0])
      this.isTime = true;
      this.getFeedTimeData(time[0],time[1],'1');
    },
    /**
     * 获取前半小时和后半小时
     * @param day 时间
     */
    getBeforeHalfHour(day){
      // 获取当前时间
      const now = new Date(day);

      // 获取当前时间的小时数和分钟数
      const hour = now.getHours();
      const minutes = now.getMinutes();

      // 计算当前时间的前半小时和后半小时的时间戳（单位：毫秒）
      const halfHour = 30 * 60 * 1000; // 30分钟
      const beforeHalfHour = now.getTime() - halfHour;
      const afterHalfHour = now.getTime() + halfHour;

      // 将时间戳转换成 Date 对象
      const beforeDate = moment(new Date(beforeHalfHour)).format('yyyy-MM-DD HH:mm:ss');
      const afterDate = moment(new Date(afterHalfHour)).format('yyyy-MM-DD HH:mm:ss');
      return [beforeDate,afterDate]
    },
    //切换天数
    switchoverDay(day){
      let time = this.moreDate(this.startTime2,day,'yyyy-MM-DD');
      this.getFeedTimeData(time+' 00:00:00',time + ' 23:59:59')
    },
    getFeedTimeData(startTime,endTime,is) {
      this.startTime2 = startTime || this.startTime;
      this.endTime2 = endTime || this.endTime;
      this.isTime = !!is;
      this.$http.get(this.$api.getFeedTimeData, {
        deviceId: this.selectObj.deviceId,
        startTime: startTime || this.startTime,
        endTime: endTime || this.endTime,
      }).then(res => {
        let that = this;
        const tip = function(params) {
          // let data = params[0].data?params[0].data.split(','):'';
          // console.log(params[0].data)
          // return '上报时间：' + (params[0].axisValue ? params[0].axisValue : '--') +
          //   '<br />' + '上报重量：' + (params[0].data ? params[0].data : '--') + ' kg'
          return '上报时间：' + params[0].data[0] + '<br />' + '上报重量：' + params[0].data[1] + ' kg'
        }
        const tip2 = function(params) {
          return '上报时间：' + (params[0].axisValue ? params[0].axisValue : '--') +
            '<br />' + '上报重量：' + (params[0].data ? params[0].data : '--') + ' kg'
        }
        const xTip = function(params) {
          let num = that.getDaysBetween(that.startTime, that.endTime);
          let j = 1;
          for(let i = 1; i <= num; i++){
            j = i
          }
          return that.moreDate(params,0,'MM-DD')
        }
        if (startTime){
          this.options7.series[0].data = [];
          this.options7.xAxis[0].data = res.result.map(i => i.time);
          this.options7.series[0].data = res.result.map(i => i.residue);
          this.options7.tooltip.formatter = tip2;
        }

        this.options5.xAxis[0].axisLabel.formatter = {};
        this.options5.xAxis[0].axisLabel.formatter = xTip;
        // this.options6.xAxis[0].data = res.result.map(i => i.time);
        // this.options6.xAxis[0].data = res.result.map(i => i.time);
        // console.log(this.options6.xAxis[0].data)
        // this.options6.series[1].data = res.result.map(i => i.residue);
        this.options6.series[0].data = [];
        this.options5.series[0].data = [];
        this.options6.series[0].data = res.result.map((item,index) =>{
          // eslint-disable-next-line no-return-assign
          return [index] = [item.time,item.residue];
        });
        this.options5.series[0].data = res.result.map((item,index) =>{
          // eslint-disable-next-line no-return-assign
          return [index] = [item.time,item.residue];
        });
        this.options6.tooltip.formatter = tip;
        this.options5.tooltip.formatter = tip;
      })
    },
    /**
     * @param startDate  开始日期 yyyy-MM-dd
     * @param enDate  结束日期 yyyy-MM-dd
     * @returns {number} 两日期相差的天数
     */
    getDaysBetween(startDate, enDate) {
      const sDate = Date.parse(startDate)
      const eDate = Date.parse(enDate)
      if (sDate > eDate) {
        return 0
      }
      // 这个判断可以根据需求来确定是否需要加上
      if (sDate === eDate) {
        return 1
      }
      const days = (eDate - sDate) / (1 * 24 * 60 * 60 * 1000)
      return Math.floor(Number(days))
    },
    /**
     * 天数添加
     * @param date1 时间
     * @param day 加多少天
     * @param type 格式
     * @returns {string}
     */
    moreDate (date1,day,type) {
      let date = new Date(date1);
      date = date.setDate(date.getDate()+day)
      return moment(new Date(date)).format(type)
    },
    changeDate(val) {
      let day = moment(val[1]).diff(moment(val[0]), 'd') + 1
      this.startTime = moment(val[0]).format('yyyy-MM-DD') + ' 00:00:00'
      this.endTime = moment(val[1]).format('yyyy-MM-DD') + ' 23:59:59'

      this.isHour = !(day <= 1)
      this.isDay = !(day > 1 && day < 180)
      this.isWeek = !(day >= 56 && day < 365)
      this.isMonth = !(day > 182 && day < 1090)

      if (this.isHour) {
        this.radio = '按天'
        if (this.isDay) {
          this.radio = '按周'
          if (this.isWeek) {
            this.radio = '按月'
            if (this.isMonth) {

            } else {
              this.radio = '按月'
            }
          } else {
            this.radio = '按周'
          }
        } else {
          this.radio = '按天'
        }
      } else {
        this.radio = '按小时'
      }
    },
    //全部设备列表
    getAllDeviceList(obj) {
      let that = this
      this.$http.get(this.$api.getAllDeviceList, {
        ...obj,
        passagewayId: this.$route.query.passagewayId,
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res => {
        if (res.data) {
          let arr = res.data.records
          if (arr.length > 0) {
            this.rowClick(res.data.records[0])
            this.selectObj = res.data.records[0]
            this.getDataQuery()
            arr.map(item => {
              item.value = numberTran(item.value)
              if (item.value == false && item.value != 0) {
                item.value = '关闭'
              }
              if (item.value == true && item.value != 1) {
                item.value = '开启'
              }
              if (item.type == '定速风机' || item.type == '水帘') {
                if (item.inline) {
                  item.value = '开启'
                } else {
                  item.value = '关闭'
                }
              }
              if (!item.deviceName) {
                item.deviceName = '--'
              }
              if (item.value) {
                if (item.type == '水量计') {
                  item.value = item.value + ' m³'
                } else if (item.type == '电表') {
                  item.value = item.value + ' 度'
                } else if (item.type == '氨气浓度') {
                  item.value = item.value + ' ppm'
                } else if (item.type == '湿度') {
                  item.value = item.value + '%RH'
                } else if (item.type == '室外温') {
                  item.value = item.value + ' ℃'
                } else if (item.type == '室内温') {
                  item.value = item.value + ' ℃'
                } else if (item.type == '重量设备') {
                  item.value = item.value + ' 吨'
                }
              } else {
                item.value = '--'
              }
            })
          } else {
            this.loading = false
          }
          this.tableData = arr
          this.pager.total = res.data.total
        } else {
          this.loading = false
        }
      })
    },

    //全部设备状态
    getAllDeviceStatus(obj) {
      let that = this
      this.$http.get(this.$api.statusCount, {
        ...obj
      }).then(res => {
        this.total = res.data.total
        this.offline = res.data.offline
        this.online = res.data.online

      })
    },
    //设备数据
    getDataQuery(obj) {
      let that = this
      this.tipData = []
      this.startData = []
      this.endData = []
      this.$http.get(this.$api.dataQuery, {
        deviceId: this.selectObj.deviceId,
        deviceType: this.selectObj.deviceType,
        startTime: this.startTime,
        endTime: this.endTime,
        passagewayId: this.selectObj.id,
        queryType: this.queryType
      }).then(res => {
        let xData = []
        let yData = []
        let yData2 = []
        let yData3 = []
        let dataList = []
        if (res.data) {
          this.allData = res.data
          res.data.map(item => {
            xData.push(item.xtime)
            if (item.currentTime) {
              this.tipData.push(item.currentTime)
            }
            if (item.startTime) {
              this.startData.push(item.startTime)
            }
            if (item.endTime) {
              this.endData.push(item.endTime)
            }

          })
          res.data.map(item => {
            if (this.queryType == 0) {
              let str = null
              this.allData.map(item => {
                if (!(item.xtime.indexOf('/') == -1)) {
                  str = item.xtime.substring(0, 5)
                  dataList.push(str)
                } else {
                  item.xtime = str + ' ' + item.xtime
                }
              })
            }

            if (this.queryType == 1) {
              let str = null
              this.allData.map(item => {
                if (!(item.xtime.indexOf('/') == -1)) {
                  str = item.xtime.substring(0, 7)
                  dataList.push(str)
                } else {
                  item.xtime = str + '|' + item.xtime
                }
              })
              // dataList.push(item.xtime.substring(0, 7))
            }
            if (this.queryType == 2) {
              let str = null
              this.allData.map(item => {
                if (!(item.xtime.indexOf(' ') == -1)) {
                  str = item.xtime.substring(0, 5)
                  dataList.push(str)
                } else {
                  item.xtime = str + ' ' + item.xtime
                }
              })
              // dataList.push(item.xtime.substring(4, 11))
            }

            if (this.queryType == 3) {
              let str = null
              this.allData.map(item => {
                if (!(item.xtime.indexOf('/') == -1)) {
                  str = item.xtime.substring(0, 4)
                  dataList.push(str)
                } else {
                  item.xtime = str + '/' + item.xtime
                }
              })
              // dataList.push(item.xtime.substring(0, 4))
            }
            yData.push(item.yvalue)
            yData3.push(Math.floor(item.yvalue3 * 10) / 10)
            yData2.push(item.yvalue2)
          })
          let i = [];
          yData2.forEach((item,index)=>{
            if (item !== '0' && item !== '0.0' ){
              i.push(index)
            }
          })
          let y = [].concat(yData);
          if (this.selectObj.type == '重量设备'){
            i.forEach(item =>{
              y[item] = {
                value: yData[item],
                symbol: "circle",
                symbolSize: 8,
                itemStyle: {
                  normal: {
                    color: "#E19D5F",
                  },
                },
              };
            })
          }
          const tip = function(params) {
            let ll = ''
            i.forEach(item =>{
              if (params.dataIndex === item){
                ll = yData2[item]
              }
            })
            return ll?'来料 '+ ll + ' kg':''
          }

          //水电+来料
          this.options.xAxis[0].data = xData
          this.options.series[0].data = yData
          // this.options1.series[1].data = yData2
          this.copyOptions1.series[1].data = yData2
          this.options8.series[0].data = yData2
          //耗料
          // this.options1.xAxis[0].data = xData
          // this.options1.series[0].label.formatter = tip
          // this.options1.series[0].data = y
          this.copyOptions1.xAxis[0].data = xData
          this.copyOptions1.series[0].data = yData
          this.options1.xAxis[0].data = xData
          this.options8.xAxis[0].data = xData
          this.options1.series[0].data = yData
          //温度、湿度、氨气 -- 小时
          this.options2.xAxis.data = xData
          this.options2.series[0].data = yData
          //温度、湿度、氨气 -- 天/周/月
          this.options3.xAxis.data = xData
          this.options3.series[0].data = yData
          this.options3.series[2].data = yData2
          this.options3.series[1].data = yData3

          this.options4.xAxis.data = xData
          this.options4.series[0].data = yData

          this.dataSelectList = Array.from(new Set(dataList))
          this.selectDate = this.dataSelectList[0]
          this.girdData = this.allData.filter(item =>
            item.xtime.includes(this.dataSelectList[0])
          )
          // console.log(this.tipData)
          this.showDataOfOptions(yData2)

          this.loading = false
        }
      })
    },
    changeBuildType() {
      this.form.buildName = undefined
      this.getAllPigpen()
    },
    //栋舍列表
    getAllPigpen() {
      let that = this
      let arr = []
      this.$http.get(this.$api.getAllPigpen, {
        buildType: that.form.buildType
      }).then(res => {
        if (res.data) {
          res.data.map(item => {
            arr.push({
              label: item.name,
              value: item.name
            })
          })
          this.buildNameList = res.data
        }
      })
    },
    //单元类型列表
    getAllBuildType() {
      let arr = []
      this.$http.get(this.$api.getBuildType, {}).then(res => {
        if (res.data) {
          res.data.map(item => {
            arr.push({
                id: item,
                name: tranBuildType(item)
              }
            )
          })
          this.buildTypeList = Array.from(new Set(arr))
        }
      })
    },

    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },
    //翻页
    handleCurrentChange2(val) {
      this.eventPager.currentPage = val
      this.loadTable2()
    },
    //页码大小
    handleSizeChange2(val) {
      this.eventPager.pageSize = val
      this.loadTable2()
    },
    rowClick(val) {
      this.loading = true
      this.selectObj = val
      // if (val==null){
      //   return
      // }
      // if (this.selectObj.type == '水量计') {
      //   this.options.title.text = (val.name ? val.name : '水表') + '用水量(m³)'
      // }
      // if (this.selectObj.type == '电表') {
      //   this.options.title.text = (val.name ? val.name : '电表') + '用电量(度)'
      // }
      // if (this.selectObj.type == '重量设备') {
      //   this.options.title.text = (val.name ? val.name : '料塔') + '耗料(kg)'
      //   this.options1.title.text = (val.name ? val.name : '料塔') + '来料(kg)'
      // }
      // if (this.selectObj.type == '室内温' || this.selectObj.type == '室外温') {
      //   this.options2.title.text = (val.name ? val.name : '温度') + '走势图(℃)'
      //   this.options3.title.text = (val.name ? val.name : '温度') + '走势图(℃)'
      // }
      // if (this.selectObj.type == '氨气浓度') {
      //   this.options2.title.text = (val.name ? val.name : '氨气浓度') + '走势图(ppm)'
      //   this.options3.title.text = (val.name ? val.name : '氨气浓度') + '走势图(ppm)'
      // }
      // if (this.selectObj.type == '湿度') {
      //   this.options2.title.text = (val.name ? val.name : '湿度') + '走势图(%RH)'
      //   this.options3.title.text = (val.name ? val.name : '湿度') + '走势图(%RH)'
      // }
      // if (this.selectObj.type == '定速风机') {
      //   this.options4.title.text = (val.name ? val.name : '定速风机')
      // }
      // if (this.selectObj.type == '水帘') {
      //   this.options4.title.text = (val.name ? val.name : '水帘')
      // }
      this.onSubmit()
    },

    onSearch(val) {
      // console.log(val)
    },
    //重载表格
    loadTable(val) {
      this.getAllDeviceList(val)
    },
    //重载表格
    loadTable2() {
      this.getFeedEvent()
    },

    onExport() {
      alert('导出')
    }

  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 398px) !important;
    overflow-y: auto !important;
}
.box {
  height: calc(100vh - 132px);
  width: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.box::-webkit-scrollbar {
  display: none;
}

.box-card {
  height: calc(100vh - 132px);
  width: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.box-card::-webkit-scrollbar {
  display: none;
}

::v-deep .el-button--mini, .el-button--small {
  border-radius: 4px;
}

.detail-title {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-size: 16px;
}

.grid-box {
  width: 100%;
  line-height: 25px;
  display: inline-block;
}

.grid-box2 {
  width: 100%;
  line-height: 40px;
  display: inline-block;
}

.minWidth {
  /*min-width: 455px;*/
}

.minWidth2 {
  padding: 0 0 0 10px;
  /*min-width: 540px;*/
}

.word97 {
  font-size: 16px;
  display: inline-block;
  height: 50px;
  line-height: 40px;
  font-family: PingFangSC-Medium;
  color: #2A2A37;
  font-weight: 600;
}

::v-deep .el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 20px;
  height: 28px;
  line-height: 28px;
  box-sizing: border-box;
  text-align: center;
}

::v-deep .el-form-item__label {
  padding-right: 10px;
}

::v-deep .el-form-item.select {
  display: inline-block;
  /*width: 320px;*/
  margin: 0 0px 8px 0;
}

::v-deep .el-form-item.check {
  display: inline-block;
  /*width: 320px;*/
  margin: 0 15px 0 0;
}

::v-deep .el-table th > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  /*color: #a2a4aa;*/
  font-weight: 500;
}

::v-deep .el-form-item.btn {
  display: inline-block;
  margin: 0 15px 0 0;
}
.btn >>>.el-form-item__content{
  margin-left: 0!important;
}
::v-deep .el-checkbox.checkbox {
  margin-right: 10px;
}

.checkbox ::v-deep .el-checkbox__label {
  padding-left: 3px;
}

.checkbox ::v-deep el-form-item__labell {
  width: 0;
}

::v-deep .el-form-item.checkbox {
  margin-bottom: 0px;
}

::v-deep .el-select {
  width: 150px;
}

::v-deep .el-card__body {
  padding: 10px;
}

::v-deep .el-card__header {
  padding: 16px 10px 16px 10px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2F7ECF;
  border-color: #2F7ECF;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #FFF;
  background-color: #2F7ECF;
  border-color: #2F7ECF;
  box-shadow: -1px 0 0 0 #2F7ECF;
}

::v-deep .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 300px;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #2F7ECF;
}

::v-deep .el-date-editor .el-range-separator {
  padding: 0 5px;
  line-height: 28px;
  width: 7%;
  color: #303133;
}

/*网格*/
.bg-purple-dark {
  background: #d3d9e3;
}

.bg-purple {
  /*background: #f1f4f6;*/
  border: 1px solid #EBEEF5;
}

.bg-purple-light {
  background: #f4f5f8;
}

.grid-content {
  display: block;
  min-height: 76px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

::v-deep .el-input__suffix {
  height: auto;
}

::v-deep .el-checkbox-group {
  font-size: 0;
  /*line-height: 40px;*/
  /*height: 40px;*/
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep .el-pagination {
  white-space: nowrap;
  padding: 12px 5px 0;
  color: #303133;
  font-weight: 700;
}

::v-deep .el-pagination__sizes {
  margin: 0;
  font-weight: 400;
  color: #606266;
}

::v-deep .el-pagination__sizes .el-select {
  width: unset;
}

::v-deep .el-tag.el-tag--warning {
  background-color: #e6a23c;
  border: none;
  color: #FFFFFF;
  font-size: 10px;
}

::v-deep .el-tag--mini {
  height: 18px;
  padding: 0 4px;
  line-height: 18px;
}

.box-card ::v-deep .el-card__body {
  padding-left: 20px;
}

.classTip {
  width: 60px;
}

@media screen and (max-width: 1320px) {
  .word97 {
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .detail-title {
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
