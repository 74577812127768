<template>
  <div class="bindstraegy">
    <searchForm :formOptions='formOptions' @onSearch='onSearch' :btnItems='btnItems'  @setSelect = 'setSelect' />
    <div class="addbtnbox">
      <el-button
        size="mini"
        type="primary"
        class="btn-search"
        @click="onBinding"
      >批量绑定</el-button>
    </div>
    <page-table
      :tableData='tableData'
      :tableLabel='tableLabel'
      :tableOption='tableOption'
      :showSelection ='showSelection'
      class="gdtable"
      :pager='pager'
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
      @handleButton='tableBtnHandle(arguments)'
      @handleSelectionChange="handleSelectionChange"
    ></page-table>
    <el-dialog
      title="绑定策略"
      :visible.sync="centerDialogVisible"
      width="50%"
      center>
      <div class="grid-content2 bg-purple">
        <div class="tabitem" v-if="nobindsingle">
          <span class="itemtitle">序列号：</span>
          <el-input v-model="formItem.snNumber" size="medium" disabled ></el-input>
        </div>
        <div class="tabitem" v-if="nobindsingle">
          <span class="itemtitle">设备类型：</span>
          <el-input v-model="formItem.deviceType" size="medium" disabled ></el-input>
        </div>
        <div class="tabitem" v-if="nobindsingle">
          <span class="itemtitle">绑定栋舍：</span>
          <el-input v-model="formItem.buildingName" size="medium" disabled ></el-input>
        </div>
        <div class="tabitem" v-if="nobindsingle">
          <span class="itemtitle">绑定单元/区：</span>
          <el-input v-model="formItem.buildName" size="medium" disabled ></el-input>
        </div>
        <div class="tabitem" v-if="nobindsingle">
          <span class="itemtitle">设备名称：</span>
          <el-input v-model="formItem.deviceName" size="medium" disabled ></el-input>
        </div>
        <div class="tabitem">
          <span class="itemtitle">策略绑定：</span>
          <el-select v-model="formItem.strategyName"  style="width:200px;" placeholder="请选中绑定策略" @change="saveSelectVal">
            <el-option
              v-for="item in strategyList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStrategy">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import searchForm from '@/components/searchForm';
import pageTable from '@/components/pageTable'
import { tranBuildType } from '../../libs/tools'
export default {
  name:"bindStraegy",
  components:{
    searchForm,
    pageTable
  },
  data(){
    return{
      selectBuildId:'',
      strategyList:[],
      nobindsingle:false,
      showSelection:true,
      btnItems: 'search' + 'reset',
      centerDialogVisible:false,
      formOptions: [

        {
          label: '序列号|名称',
          prop: 'snOrName',
          element: 'el-input',
          placeholder: '设备序列号/名称'
        },
        {
          label: '设备类型',
          prop: 'deviceType',
          element: 'el-select',
          options: this.getDeviceTypeList()
        },
        {
          label: '栋舍',
          prop: 'buildingId',
          element: 'el-select',
          isChange: 'change',
          options: this.getBuildType()
        }, {
          label: '单元/区',
          prop: 'buildId',
          element: 'el-select',
          initValue:'',
          showTooltip:true,
          options: []
        },
        {
          label: '策略',
          prop: 'strategyId',
          element: 'el-select',
          options: this.getAllStrategyList()
        },
        {
          label: '策略绑定状态',
          prop: 'isBindStrategy',
          initValue:'',
          element: 'el-select',
          options: [
            {
              label: '全部',
              value: ''
            },{
              label: '已绑定',
              value: '0'
            },{
              label: '未绑定',
              value: '1'
            },
          ]
        },

      ],
      tableData: [],
      tableLabel: [
        { label: '序列号', param: 'snNumber' },
        { label: '名称', param: 'deviceName' },
        { label: '类型', param: 'deviceType' },
        { label: '已绑栋舍', param: 'buildingName' },
        { label: '已绑单元/区', param: 'buildName' },
        { label: '已绑策略', param: 'strategyName' }
      ],
      tableOption: {
        label: '操作',
        options: [
          { label: '绑定策略', methods: 'editDetail' }
        ]
      },
      pager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      },
      formItem:{
        snNumber:'',//序列号
        deviceName:'',//设备类型
        deviceType:'',//设备类型
        buildingName:'',//已绑栋舍
        buildName:'',//已绑单元
        strategyId:'',//已绑策略id
        strategyName:'',//已绑策略名称
      },
      deviceIds:[],
      
    }
  },
  watch:{
    selectBuildId(val){
      console.log("==+++++++++++===")
      console.log(val)
      this.getAllPigpen(val)
    }
  },
  mounted() {
    this.getDevicePage();
    this.getEnvironmentaltacticsList();
    this.getAllPigpen()
  },
  methods: {
    setSelect(val){
      this.selectBuildId = val;
      this.formOptions[3].initValue=''
    },
    //栋舍类型列表
    getBuildType() {
      let arr = []
      this.$http.get(this.$api.getBuildList, {
      }).then(res => {
        if (res.data){
          res.data.map(item=>{
            arr.push({
              label: item.name,
              value: item.id
            })
          })
        }
      })
      return arr
    },
    //选择下拉保存
    saveSelectVal(val){
      this.formItem.strategyId = val;
    },
    //保存单个绑定
    saveStrategy(){
      if(this.formItem.deviceId == ''){
        //表示批量绑定
      }else{
        this.deviceIds = [this.formItem.deviceId];
      }
      // if (this.formItem.strategyId){
        this.$http.post(this.$api.bindEnvironmentaltactics,{deviceIds:this.deviceIds,strategyId:this.formItem.strategyId}).then(res=>{
          if(res.statusCode == 200){
            this.$message({
              type: 'success',
              message: '绑定成功'
            });
            this.getDevicePage();
            this.centerDialogVisible = false;
          }
        })
      // }else {
      //   this.$message.warning("请选择策略！")
      // }

    },
    //选中集合
    handleSelectionChange(rows){
       //rows为选中行的数据的集合
      //勾选后，需要往后台传递什么数据，从rows中获取即可
      this.deviceIds = []
      rows.map(item=>{
        this.deviceIds.push(item.deviceId)
      })
      // this.deviceIds = this.deviceIds.join(',')
    },
    //批量绑定
    onBinding(){
      if(this.deviceIds.length ==0){
        this.$message({
          type: 'warning',
          message: '请选中要绑定的设备'
        });
      }else{
        this.nobindsingle = false;
        this.centerDialogVisible = true;
        this.formItem.strategyId = "";
        this.formItem.deviceId = "";
      }
    },
    //分页设备列表
    getDevicePage(obj){
      let that = this
      this.$http.get(this.$api.getDeviceStrategyPage, {
        ...obj,
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res => {
        // let arr =  res.data.records
        // arr.map(item=>{
        //   if (item.online==1){
        //     item.online = '在线'
        //   }else {
        //     item.online = '离线'
        //   }
        // })
        // console.log(res)
        this.tableData = res.data.records
        this.pager.total = res.data.total
      })
    },
    //获取设备列表
    getDeviceTypeList(){
      let list =[];
      this.$http.get(this.$api.getDeviceTypeList).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'label':item,'value':item})
          })
        }
      })
      return list
    },
    //获取单元列表
    // getPigpenBuild(val){
    //   let list =[];
    //   this.$http.get(this.$api.getPigpenBuild).then(res=>{
    //     console.log(1111)
    //     if(res.statusCode == 200){
    //       res.data.map(item=>{
    //         list.push({'label':item.name,'value':item.id})
    //       })
    //     }
    //   })
    //   return list
    // },
    getAllPigpen(val){
      let arr = []
      this.formOptions[3].options  = []
      this.$http.get(this.$api.getAllPigpen, {
        buildingId:this.selectBuildId
      }).then(res => {
        if (res.data){
          res.data.map(item => {
            arr.push({
              label: item.name,
              value: item.id
            })
          })
          this.formOptions[3].options  = arr
        }
      })
    },
    //获取策略列表(搜索)
    getEnvironmentaltacticsList(){
      let list =[];
      this.$http.get(this.$api.getEnvironmentaltacticsList).then(res=>{
        if(res.statusCode == 200){
          list.push({'label':'无策略','value':''})
          res.data.map(item=>{
            list.push({'label':item.tacticsName,'value':item.id})
          })
        }
      })
      this.strategyList = list

    },
    //获取策略列表
    getAllStrategyList(){
      let list =[];
      this.$http.get(this.$api.getEnvironmentaltacticsList).then(res=>{
        if(res.statusCode == 200){
          res.data.map(item=>{
            list.push({'label':item.tacticsName,'value':item.id})
          })
        }
      })
      return list
    },
    tableBtnHandle(arg) {
      if(arg[0]=='editDetail'){
        //编辑
        // console.log(arg[1].tacticsName)
        // this.chooseName = arg[1].tacticsName;
        this.nobindsingle = true;
        this.centerDialogVisible = true;
        this.setDialog(arg[1]);
      }
    },
    //点击绑定单个策略
    setDialog (items) {
      this.formItem.snNumber = items.snNumber;//序列号
      this.formItem.deviceId = items.deviceId;//设备id
      this.formItem.deviceName = items.deviceName;//设备类型
      this.formItem.deviceType = items.deviceType;//设备类型
      this.formItem.buildingName = items.buildingName;//已绑栋舍
      this.formItem.buildName = items.buildName;//已绑单元
      this.formItem.strategyId = items.strategyId;//已绑策略id
      this.formItem.strategyName = items.strategyName;//已绑策略名称
    },
    onSearch(val) {
      this.obj = val;
      this.getDevicePage(val)
    },
     //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.getDevicePage(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.getDevicePage(this.obj)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 375px) !important;
    overflow-y: auto !important;
}
  .bindstraegy{
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    height: calc(100vh - 132px);
    overflow-y: hidden;
    .addbtnbox{
      margin-bottom:10px;
    }
    .grid-content2{
      margin-bottom:10px;
      display:flex;
      //align-content: space-between;
      flex-wrap: wrap;
      .tabitem{
        width:50%;
        margin-bottom:10px;
        .itemtitle{
          display: inline-block;
          text-align: justify;
          white-space:break-spaces;
          width:100px;
        }
        .el-input{
          width:200px;
        }
      }

  }
  }
</style>
