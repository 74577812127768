<template>
  <div class="addValue">
    <searchForm :formOptions='formOptions' @onSearch='onSearch' :btnItems='btnItems'  />
    <page-table
      :tableData='tableData'
      :tableLabel='tableLabel'
      :pager='pager'
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
    ></page-table>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
export default {
  
  name: 'addValue',
  components: {
    pageTable,
    searchForm
  },
  data(){
    return{
      btnItems: 'search' + 'reset',
      formOptions: [
        {
          label: '账单类型',
          prop: 'type',
          element: 'el-select',
          options: [{'label':'消费','value':'1'},{'label':'充值','value':'0'}]
        },
        {
          label: '账单时间',
          prop: 'recordTime',
          type: 'daterange',//monthrange | datetimerange | daterange |date
          element: 'el-date-picker'
        },

      ],
      tableData: [],
      tableLabel: [
        { label: '账单时间', param: 'recordTime' },
        { label: '账单类型', param: 'type' },
        { label: '账单金额(元)', param: 'money' },
        { label: '账单描述', param: 'msg' }
      ],
      pager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      },
    }
  },
  mounted() {
    this.getMoneyrecordpage()
  },
  methods: {
    getMoneyrecordpage(obj){
      this.$http.get(this.$api.getMoneyrecordpage,{
         ...obj,
        current: this.pager.currentPage,
        size: this.pager.pageSize
      }).then(res => {
         let arr =  res.data.records
        arr.map(item=>{
          if (item.type==1){
            item.type = '消费'
          }else {
            item.type = '充值'
          }
        })
        // console.log(res)
        this.tableData = arr
        this.pager.total = res.data.total
      })
    },
     //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.getDevicePage(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.getDevicePage(this.obj)
    },
    onSearch(val) {
      let obj ={};
      if(val.recordTime){
        obj.startTime = val.recordTime[0] + ' 00:00:00';
        obj.endTime = val.recordTime[1] + ' 23:59:59';
      }
      if(val.type){
        obj.type = val.type
      }
      this.getMoneyrecordpage(obj)
      //this.loadTable(val)
    },
  },

}
</script>

<style lang="scss" scoped>
.addValue{
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  height: calc(100vh - 132px);
  overflow-y: scroll;
}
</style>
