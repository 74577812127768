<template>
  <div class="container01">
    <div class="box-wrapper">
      <el-tabs v-model="activeName" class="tab-box">
        <el-tab-pane label="用户信息" name="first">
          <div v-show="showUserList" class="PageList">
            <el-button type="primary" @click="switchView">
              新增用户
            </el-button>
            <UserListView @editCate='editCate'></UserListView>
          </div>
          <div v-show="!showUserList" class="add-category">
            <el-button type="primary" @click="switchView">
              返回列表
            </el-button>
            <UserView :modifyCateId="modifyCateId"></UserView>
          </div>
        </el-tab-pane>
        <el-tab-pane label="商品规格" name="third">
          <h1>ccc</h1>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import UserListView from './component/UserListView'
import UserView from './component/UserView'

export default {
  name: 'User',
  components: {
    UserListView,
    UserView
  },
  data() {
    return {
      activeName: 'first',
      showUserList: true,
      modifyCateId: null
    }
  },
  methods: {
    switchView() {
      this.showUserList = !this.showUserList
      this.modifyCateId = null
    },
    editCate(uid) {
      this.modifyCateId = uid
      this.showUserList = !this.showUserList
    }
  }
}
</script>

<style lang="scss" scoped>
  .container01 {
    padding: 8px;
    min-height: 100vh;
    background-color: rgb(240, 242, 245);
    .box-wrapper {
      background-color: #fff;
      border-radius: 3px;
      padding: 0px 10px;
    }
  }

</style>
